import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import menus from '../../config/menus';

import StorageHelper from '../../helpers/StorageHelper';
import useAuth from '../../hooks/useAuth';

const allIcons = require('@material-ui/icons');

const useStyles = makeStyles(
  (theme) => ({
    menu: {
      borderLeft: '3px solid transparent',
      paddingLeft: 13,
    },
    menuActive: {
      backgroundColor: theme.palette.action.selected,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      '& .MuiListItemIcon-root': {
        color: 'inherit',
      },
    },
    menuIcon: {
      marginRight: -8,
    },
  }),
  { index: 1 }
);

export default function Menus({ tooltip = false }) {
  const global = useSelector((state) => state.global);
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin, isAdminDevice, isAdminMailer, isAdminMP, isAdminBB, isAdminDomain, user } = useAuth();
  const handleLogout = () => {
    StorageHelper.clear();
    history.go(0);
  };

  return (
    <>
      {menus?.map((menu, key) => {
        const features = menu.features;
        // .filter(({ admin, adminDevice, adminMailer, adminMP, adminDomain, username }) =>
        //   username ? user?.username === username : adminDevice ? isAdminDevice : adminMailer ? isAdminMailer : adminMP ? isAdminMP : adminDomain ? isAdminDomain : admin ? isAdmin : true
        // );
        const filteredFeatures = features
          .filter((f) => f.name)
          .filter((f) => (f.admin ? isAdmin : true))
          .filter((f) => (f.adminDevice ? isAdmin || isAdminDevice : true))
          .filter((f) => (f.adminMailer ? isAdmin || isAdminMailer : true))
          .filter((f) => (f.adminDomain ? isAdmin || isAdminDomain : true))
          .filter((f) => (f.adminMP ? isAdmin || isAdminMP : true))
          .filter((f) => (f.adminBB ? isAdmin || isAdminBB : true))
          .filter((f) => (f.username ? f.username.includes(user?.username) : true))
          .filter((f) => (f.notusername ? !f.notusername.includes(user?.username) : true));
        return (
          filteredFeatures?.length > 0 && (
            <List key={key}>
              <ListSubheader>{!tooltip ? menu.name : `${menu.name.substr(0, 3)}...`}</ListSubheader>
              {filteredFeatures.map((feature) => {
                const FeatureIcon = allIcons[feature.icon];
                const todayMPBalanceSum = global?.sysinfo?.balancesToday?.filter((s) => user.username === 'admin' || s.owners.includes(user.username)).reduce((acc, a) => acc + a.sumBalance, 0);
                const todayMPSessionCount = global?.sysinfo?.balancesToday?.filter((s) => isAdmin || s.owners.includes(user.username)).length;
                const todayUsadoSum = global?.sysinfo?.usadosToday?.filter((s) => isAdminMP || s.owners.includes(user.username)).reduce((acc, a) => acc + a.usadoValue, 0);

                return (
                  <ListItem key={feature.url} button component={NavLink} to={feature.url} className={classes.menu} activeClassName={classes.menuActive} exact={feature.url === '/'}>
                    {Boolean(feature.icon) && (
                      <ListItemIcon className={classes.menuIcon}>
                        <FeatureIcon />
                      </ListItemIcon>
                    )}
                    {/* <ListItemText primary={feature.name} /> */}
                    <ListItemText
                      primary={
                        feature.name === 'Online'
                          ? `Online (${(global?.onlines || []).filter((online) => isAdmin || online.username === user.username).reduce((sum, o) => sum + o.totalOnline, 0)})`
                          : feature.name === 'Mobile Sessions'
                          ? `MP ${todayMPBalanceSum ? `R$ (${Number(todayMPBalanceSum).toLocaleString('en-US').replace(/,/g, '.')})` : 'Sessions'} 
                               ${todayMPSessionCount ? `(${todayMPSessionCount})` : ''}`
                          : feature.name === 'Usado'
                          ? `MP Usado ${todayUsadoSum ? `(${Number(todayUsadoSum).toFixed(2)})` : ''}`
                          : // : feature.name === 'Netflix'
                          // ? `Netflix` + (global?.sysinfo?.netflix ? ` (${global?.sysinfo?.netflix})` : '')
                          // : feature.name === 'UniCred'
                          // ? `UniCred` + (global?.sysinfo?.unicred ? ` (${global?.sysinfo?.unicred})` : '')
                          // : feature.name === 'ViaCredi'
                          // ? `ViaCredi` + (global?.sysinfo?.viacredi ? ` (${global?.sysinfo?.viacredi})` : '')
                          feature.name === 'BB'
                          ? `BB (${global?.sysinfo?.bb || 0})`
                          : feature.name
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          )
        );
      })}
      <List>
        <ListItem button className={classes.menu} style={{ marginTop: 32 }} onClick={handleLogout}>
          <ListItemIcon className={classes.menuIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </>
  );
}
