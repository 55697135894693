import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';

import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Input from '../../components/Form/Input';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function IpLogs({ setTitle }) {
  const { isAdmin, user } = useAuth();
  const users = useSelector((s) => s.global.userList);
  const [ASDetails, setASDetails] = useState([]);
  const [blockedASs, setBlockedASs] = useState(null);
  const [blockedIPs, setBlockedIPs] = useState(null);
  const [blockedHosts, setBlockedHosts] = useState(null);

  useEffect(() => setTitle('Online'), [setTitle]);

  const { data: pendings = 0 } = useFetch('/api/rd/domains/pending', { refreshInterval: 3000 });
  const { data: dbipStatus } = useFetch('/api/rd/dbip/status', { refreshInterval: 10000 });

  useEffect(() => {
    if (blockedASs) {
      Promise.all(blockedASs.split(',').map(async (number) => await API.get(`/api/rd/dbip/as/${number.trim()}`).then((r) => r.data))).then((r) => {
        setASDetails(r);
        console.log(r);
      });
    }
  }, [blockedASs]);
  if (!pendings) {
    return <Loading />;
  }
  const columns = [
    {
      title: 'No',
      Cell: ({ index }) => index + 1,
    },
    // {
    //   title: 'StartFrom',
    //   attribute: 'createdAt',
    //   Cell: ({ createdAt }) => {
    //     const date = new Date(createdAt);
    //     return date.toLocaleString('pt-br');
    //   },
    // },
    {
      title: 'Life',
      Cell: ({ updatedAt }) => toHHMMSS(Math.floor((new Date().getTime() - updatedAt) / 1000).toString()),
    },
    {
      title: 'Redir/ Owner',
      Cell: ({ redir }) => (
        <>
          {redir}
          <br />
          {(users || [])
            .filter((u) => u.public && u.redirDomainList.includes(redir))
            .map((u) => u.username)
            .join(', ')}
        </>
      ),
    },
    {
      title: 'IP',
      Cell: ({ ips }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {ips.map((ip, index) => (
            <span key={index} style={{ backgroundColor: '#0001', padding: 5, borderRadius: 3, margin: 1 }}>
              {ip}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'IP Info',
      Cell: ({ ipinfo }) =>
        ipinfo && (
          <div>
            <span>
              {ipinfo?.city} / {ipinfo?.stateProvCode}
            </span>
            <br />
            <span style={{ color: '#aaa' }}>{ipinfo?.organization}</span>
          </div>
        ),
    },
    {
      title: 'Email/Sender',
      Cell: ({ email, emails, tracking }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <span style={{ backgroundColor: '#0001', padding: 5, borderRadius: 3, margin: 3 }}>
            {email || ''}
            {tracking?.mailGroup && tracking?.sender && (
              <>
                <br />
                <span style={{ color: '#f888' }}>{tracking.mailGroup}</span>
                <br />
                <span style={{ color: '#fc8a' }}>{tracking.sender}</span>
                {tracking?.trackId && (
                  <>
                    <br />
                    <span style={{ color: '#fffc' }}>{tracking.trackId}</span>
                  </>
                )}
              </>
            )}
          </span>
          {(emails || []).map((email) => (
            <span key={email} style={{ backgroundColor: '#0001', padding: 5, borderRadius: 3, margin: 3 }}>
              {email}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Theme / Hash',
      Cell: ({ hash, email, theme }) => (
        <>
          {theme && <span style={{ backgroundColor: '#1113', padding: 5, borderRadius: 15 }}>{<span style={{ color: theme === 'mp' ? '#8cf' : 'yellow' }}>{theme.toUpperCase()}</span>}</span>}
          &nbsp;&nbsp;&nbsp;
          {hash}
        </>
      ),
    },
    {
      title: 'Bloqueado',
      Cell: ({ blocked, score, reason }) => (blocked ? <Box style={{ color: '#A66' }}>Blocked ({score})</Box> : <Box color="success.main">Allowed</Box>),
    },
    {
      title: 'Reason',
      attribute: 'reason',
      Cell: ({ reason, blocked, score }) => (
        <div style={{ overflowY: 'scroll', maxHeight: 100 }}>
          {reason.split('\n').map((r, index) => (
            <div key={index} style={{ color: blocked ? '#A66' : '#CC6' }}>
              {r}
            </div>
          ))}
        </div>
      ),
    },
  ];
  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Grid container spacing={4} justify="space-between">
          <Grid item>
            <Box>
              {isAdmin && (
                <>
                  <span>DB-API: </span>
                  <span style={{ color: 'lightgreen' }}>{dbipStatus?.queriesLeft || 0}</span> /&nbsp;<span style={{ color: '#ccc' }}>{dbipStatus?.queriesPerDay || 0}</span>
                </>
              )}
            </Box>
          </Grid>
          <Grid item>
            {isAdmin && (
              <Box color="error.main">
                <Button onClick={() => API.get(`/api/sv/settings/blocked`).then(({ data }) => setBlockedASs(data.asns.join(', ')))}>
                  <Icons.Home />
                  &nbsp;&nbsp; Blocked ASs
                </Button>
                <Button onClick={() => API.get(`/api/sv/settings/blocked`).then(({ data }) => setBlockedIPs(data.ips.join(', ')))}>
                  <Icons.LocationCity />
                  &nbsp;&nbsp; Blocked IPs
                </Button>
                <Button onClick={() => API.get(`/api/sv/settings/blocked`).then(({ data }) => setBlockedHosts(data.hosts.join(', ')))}>
                  <Icons.Home />
                  &nbsp;&nbsp; Blocked Hosts
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Table data={pendings.map((l, index) => ({ ...l, index })).slice(0, 30)} columns={columns} rowsPerPage={30} />

      <Dialog open={Boolean(blockedASs)} onClose={() => setBlockedASs(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            if (param.asns)
              API.post(`/api/sv/settings/blocked`, {
                asns: param.asns
                  .split(',')
                  .map((as) => +as.trim())
                  .filter(Boolean),
              });
            setBlockedASs(null);
          }}
        >
          <DialogTitle>Blocked ASs</DialogTitle>
          <DialogContent>
            <Input autoFocus label="ASN" name="asns" rows="3" defaultValue={blockedASs} multiline />
          </DialogContent>
          <DialogContent>
            <Table
              data={ASDetails || []}
              columns={[
                {
                  title: 'Number',
                  Cell: ({ number }) => number,
                },
                {
                  title: 'Number',
                  Cell: ({ name }) => name,
                },
                {
                  title: 'organization',
                  Cell: ({ organization }) => organization,
                },
                {
                  title: 'Country',
                  Cell: ({ countryCode }) => countryCode,
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Update
            </Button>
            <Button onClick={() => setBlockedASs(null)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(blockedIPs)} onClose={() => setBlockedIPs(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            if (param.ips)
              API.post(`/api/sv/settings/blocked`, {
                ips: param.ips
                  .split(',')
                  .map((ip) => ip.trim())
                  .filter(Boolean),
              });
            setBlockedIPs(null);
          }}
        >
          <DialogTitle>Blocked IPs</DialogTitle>
          <DialogContent>
            <Input autoFocus label="IPs" name="ips" rows="5" defaultValue={blockedIPs} multiline />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Update
            </Button>
            <Button onClick={() => setBlockedIPs(null)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(blockedHosts)} onClose={() => setBlockedHosts(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            if (param.hosts)
              API.post(`/api/sv/settings/blocked`, {
                hosts: param.hosts
                  .split(',')
                  .map((host) => host.trim())
                  .filter(Boolean),
              });
            setBlockedHosts(null);
          }}
        >
          <DialogTitle>Blocked Hosts</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Hosts" name="hosts" rows="8" defaultValue={blockedHosts} multiline />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Update
            </Button>
            <Button onClick={() => setBlockedHosts(null)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

function toHHMMSS(str) {
  var sec_num = parseInt(str, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}
