import API, { Service } from './API';

export default class SessionService extends Service {
  static service = '/api/sv/sessions';

  static async getComments(sessionId) {
    return API.get(`/api/sv/sessions/${sessionId}/comments`);
  }

  static async addComment(sessionId, comment) {
    return API.post(`/api/sv/sessions/${sessionId}/comments`, { comment });
  }
  static async update({ id, ...data }) {
    return API.put(`/api/sv/sessions/${id}`, data);
  }

  static async listDevices(type) {
    return API.get(`/api/sv/devices/${type}`);
  }
  static async load({ username, ip }) {
    return API.post(`/api/sv/devices/load`, { username, ip });
  }
  static async add(param) {
    return API.post(`/api/sv/devices`, param);
  }
  static async delete(ip) {
    return API.delete(`/api/sv/devices/${ip}`);
  }
  static async shell(ip, commands) {
    return API.post(`/api/sv/devices/shell`, { ip, commands });
  }
  static async action(ip, action, param) {
    return API.post(`/api/sv/devices/action`, { ip, action, param });
  }
  static async listCamera() {
    return API.get(`/api/sv/upload`);
  }
  static async getCamera(name) {
    return API.get(`/api/sv/upload/${name}`);
  }
  static async deleteCamera(name) {
    return API.delete(`/api/sv/upload/${name}`);
  }
  static async setCamera(ip, name) {
    return API.put(`/api/sv/devices/camera/${ip}/${name}`);
  }
}
