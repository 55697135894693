import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function Input({ name, InputLabelProps = {}, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  useEffect(() => {
    rest.select
      ? registerField({
          name: fieldName,
          ref: inputRef.current,
          getValue: (ref) => {
            return ref.node?.value;
          },
        })
      : registerField({
          name: fieldName,
          ref: inputRef.current,
          path: rest.type === 'checkbox' ? 'checked' : rest.type === 'file' ? 'files' : 'value',
        });
  }, [fieldName, registerField, rest.type, rest.select]);
  InputLabelProps.shrink = true;
  return rest.type === 'checkbox' ? (
    <FormControlLabel control={<Switch inputRef={inputRef} defaultChecked={rest.defaultValue} />} {...rest} />
  ) : (
    <TextField inputRef={inputRef} fullWidth variant="outlined" defaultValue={defaultValue || ''} InputLabelProps={InputLabelProps} {...rest}></TextField>
  );
}
