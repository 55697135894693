import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(
  {
    icon: { fontSize: 14 },
  },
  { index: 1 }
);

export default function Copy({ value, children = null, style = {} }) {
  const classes = useStyles();

  const [copyTimeout, setCopyTimeout] = useState(null);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(`${value ?? ''}`);

    if (copyTimeout) {
      clearTimeout(copyTimeout);
    }

    const timeout = setTimeout(() => setCopyTimeout(null), 2000);
    setCopyTimeout(timeout);

    return () => clearTimeout(timeout);
  };

  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>{children || value}</Grid>
      <Grid item>
        <Tooltip title={copyTimeout ? 'Copiado!' : 'Copiar'}>
          <IconButton className={classes.icon} size="small" onClick={handleCopy} style={style}>
            {copyTimeout ? <CheckCircleIcon fontSize="inherit" /> : <FileCopyIcon fontSize="inherit" />}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
