export default function cameras(state = { list: [], ip: null }, action) {
  const { type, value } = action;
  switch (type) {
    case 'CAMERA_SHOW':
      return { ...state, ip: value };

    case 'CAMERA_HIDE':
      return { ...state, ip: null };

    case 'CAMERA_LIST':
      return { list: value, ip: state.ip };

    default:
      return state;
  }
}
