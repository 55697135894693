import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function DDos({ setTitle, setHeaderRight }) {
  useEffect(() => setTitle('DDos - G3M'), [setTitle]);
  const { user, isAdminMP } = useAuth();

  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [current, setCurrent] = useState(null);

  const { data: ddosList } = useFetch(`/api/sv/ddos?offset=${offset * limit}&limit=${limit}`, { refreshInterval: 10000 });

  useEffect(() => {
    setHeaderRight(
      <>
        <FormControlLabel
          control={
            <span>
              <Icons.Add />
              &nbsp;&nbsp;
            </span>
          }
          label="G3M VM"
          onClick={() => {
            setCurrent({});
          }}
          disabled={true}
        />
      </>
    );
    return () => setHeaderRight(null);
  }, [setHeaderRight]);

  const columns = [
    {
      title: 'ID',
      attribute: 'index',
    },
    {
      title: 'IP',
      attribute: 'ip',
      Cell: ({ ip, status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{ip}</div>,
    },
    {
      title: 'Version',
      Cell: ({ status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{status?.version}</div>,
    },
    {
      title: 'Created',
      Cell: ({ status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{!!status?.createdAt && new Date(status.createdAt).toLocaleString()}</div>,
    },
    {
      title: 'Working',
      Cell: ({ status, results }) => (
        <div style={{ color: !status ? 'gray' : '#fff' }}>
          {(results || []).map((result, index) => (
            <div key={index}>
              {result.running ? 'Attacking ' : 'End attack '}&nbsp;{result.ip}
            </div>
          ))}
        </div>
      ),
    },
    // {
    //   title: 'Action',
    //   Cell: (row) => (
    //     <>
    //       <ButtonGroup size="small">
    //         <Button
    //           title="Update"
    //           onClick={() => {
    //             API.post(`/api/sv/ddos`, { ip: row.ip, password: row.password });
    //           }}
    //           disabled={!row.status}
    //         >
    //           <Icons.SystemUpdate fontSize="small" />
    //         </Button>
    //         <Button
    //           title="Remove"
    //           onClick={() => {
    //             if (window.confirm(`Are you sure to delete?`)) {
    //               API.delete(`/api/sv/ddos/${row.ip}`);
    //             }
    //           }}
    //         >
    //           <Icons.Delete fontSize="small" />
    //         </Button>
    //       </ButtonGroup>
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Table data={ddosList?.map((l, index) => ({ index: index + 1, ...l }))} columns={columns} />
      </Box>
      <Dialog open={Boolean(current)} onClose={() => setCurrent(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            if (current?.ip) {
              // Update
            } else {
              API.post(`/api/sv/ddos`, params);
            }
            setCurrent(null);
          }}
        >
          <DialogTitle>{!current?.ip && 'Add'} VM </DialogTitle>
          <DialogContent>
            <Input label="ip" type="text" name="ip" defaultValue={current?.ip} fullWidth />
            <p></p>
            <Input label="password" type="text" name="password" defaultValue={current?.password} fullWidth />
            <p></p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrent(null)} color="primary">
              Close
            </Button>

            {!current?.ip && (
              <Button type="submit" color="secondary">
                Add
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
