import useSWR from 'swr';
import API from '../services/API';

export default function useFetch(url = null, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async (url) => {
      const response = await API.get(url);
      return response.data;
    },
    options
  );

  return { data, error, mutate };
}
