// src/components/PuppeteerScreen.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Paper, Button, IconButton } from '@material-ui/core';
import { ExpandMore, ExpandLess, Close } from '@material-ui/icons';

const pupSv = 'https://pupservice.btks.games/ws-panel'; // Base URL for the backend service

export default function PuppeteerScreen({ setTitle }) {
  const [sessions, setSessions] = useState([]);
  const [websockets, setWebsockets] = useState([]);
  const [activeSessions, setActiveSessions] = useState({});
  const [url, setUrl] = useState('https://mercadolivre.com.br');
  const [isCanvasActive, setIsCanvasActive] = useState(false);
  const [isTypingInUrl, setIsTypingInUrl] = useState(false);

  useEffect(() => {
    setTitle('MP-Web');

    const fetchSessions = async () => {
      try {
        console.log('Fetching sessions from server...');
        const response = await fetch(`${pupSv}/sessions`);
        if (!response.ok) throw new Error('Failed to fetch sessions');
        const data = await response.json();
        console.log('Sessions data:', data);
        setSessions(data);
      } catch (error) {
        console.error('Error fetching sessions:', error.message);
      }
    };

    const fetchWebsockets = async () => {
      try {
        console.log('Fetching WebSocket data from server...');
        const response = await fetch(`${pupSv}/ws`);
        if (!response.ok) throw new Error('Failed to fetch websockets');
        const data = await response.json();
        console.log('WebSocket data:', data);

        const wsMap = {};
        data.forEach(ws => {
          if (ws.status === 'active') {
            wsMap[ws.email] = `ws://82.197.66.99:${ws.wsEndpoint.port}`; // Forçando o uso do host 82.197.66.99
            console.log(`Mapped WebSocket for ${ws.email}: ${wsMap[ws.email]}`);
          }
        });
        setActiveSessions(wsMap);
        setWebsockets(data);
      } catch (error) {
        console.error('Error fetching websockets:', error.message);
      }
    };

    fetchSessions();
    fetchWebsockets();

    const intervalSessions = setInterval(fetchSessions, 10000);
    const intervalWebsockets = setInterval(fetchWebsockets, 10000);

    return () => {
      clearInterval(intervalSessions);
      clearInterval(intervalWebsockets);
    };
  }, [setTitle]);

  const startSession = async (email) => {
    try {
      console.log(`Starting session for email: ${email}`);
      const response = await fetch(`${pupSv}/sessions/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, ML: true }),
      });
      if (!response.ok) throw new Error('Failed to start session');
      const data = await response.json();
      const { wsEndpoint } = data;
      console.log(`Received WebSocket endpoint for ${email}:`, wsEndpoint);

      if (wsEndpoint) {
        // Forçando o host para 82.197.66.99, independentemente do que a API retorna
        const fixedEndpoint = `ws://82.197.66.99:${wsEndpoint.port}`;
        setActiveSessions((prev) => ({
          ...prev,
          [email]: fixedEndpoint,
        }));
        console.log(`Active WebSocket mapped for ${email}: ${fixedEndpoint}`);
      } else {
        console.error('Error: No WebSocket endpoint returned from server.');
      }
    } catch (error) {
      console.error('Error starting session:', error.message);
    }
  };

  const closeSession = (email) => {
    console.log(`Closing session for ${email}`);
    setActiveSessions((prev) => {
      const newSessions = { ...prev };
      delete newSessions[email];
      return newSessions;
    });
  };

  const PuppeteerScreenComponent = ({ email, wsEndpoint }) => {
    const canvasRef = useRef(null);
    const ws = useRef(null);
    const [screenWidth, setScreenWidth] = useState('small');

    const connectWebSocket = useCallback(() => {
      console.log(`Connecting WebSocket for ${email} at ${wsEndpoint}`);
      ws.current = new WebSocket(wsEndpoint);

      ws.current.onopen = () => {
        console.log('WebSocket connection opened');
        ws.current.send(JSON.stringify({ type: 'getCurrentUrl' }));
      };

      ws.current.onclose = () => {
        console.log('WebSocket connection closed. Reconnecting...');
        setTimeout(connectWebSocket, 5000);
      };

      ws.current.onmessage = (event) => {
        try {
          const msg = JSON.parse(event.data);
          console.log('Received WebSocket message:', msg);
          if (msg.type === 'frame') {
            updateCanvas(msg.data);
          } else if (msg.type === 'url') {
            setUrl(msg.url);
          }
        } catch (err) {
          console.error('Error processing WebSocket message:', err.message);
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error.message);
      };
    }, [wsEndpoint]);

    const updateCanvas = (data) => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        const img = new Image();
        img.src = `data:image/jpeg;base64,${data}`;
        img.onload = () => {
          ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          ctx.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height);
        };
      }
    };

    const sendMessage = (message) => {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify(message));
      }
    };

    const handleNavigate = () => {
      setIsTypingInUrl(false);
      sendMessage({ type: 'navigate', url });
    };

    const handleBack = () => sendMessage({ type: 'goBack' });
    const handleForward = () => sendMessage({ type: 'goForward' });
    const handleRefresh = () => sendMessage({ type: 'refresh' });

    const handleKeyDown = (event) => {
      if (isCanvasActive && !isTypingInUrl) {
        event.preventDefault();
        const key = event.key;
        sendMessage({ type: 'keydown', key });
      }
    };

    const handleKeyUp = (event) => {
      if (isCanvasActive && !isTypingInUrl) {
        event.preventDefault();
        const key = event.key;
        sendMessage({ type: 'keyup', key });
      }
    };

    const handleClick = (event) => {
      setIsCanvasActive(true);
      setIsTypingInUrl(false);
      if (canvasRef.current) {
        const rect = canvasRef.current.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / rect.width) * canvasRef.current.width;
        const y = ((event.clientY - rect.top) / rect.height) * canvasRef.current.height;
        sendMessage({ type: 'click', x, y });
      }
    };

    const handleScroll = (event) => {
      if (isCanvasActive) {
        sendMessage({ type: 'scroll', deltaY: event.deltaY });
      }
    };

    const handleUrlInputFocus = () => {
      setIsTypingInUrl(true);
      setIsCanvasActive(false);
    };

    const handleUrlInputBlur = () => {
      setIsTypingInUrl(false);
    };

    useEffect(() => {
      connectWebSocket();
      return () => ws.current && ws.current.close();
    }, [connectWebSocket]);

    const toggleScreenWidth = () => setScreenWidth(screenWidth === 'small' ? 'large' : 'small');

    return (
      <div style={{ padding: 8, border: '1px solid black', marginTop: 10 }}>
        <NavigationBar
          url={url}
          setUrl={setUrl}
          onNavigate={handleNavigate}
          onBack={handleBack}
          onForward={handleForward}
          onRefresh={handleRefresh}
          onUrlInputFocus={handleUrlInputFocus}
          onUrlInputBlur={handleUrlInputBlur}
        />
        <div
          style={{ overflow: 'auto', display: 'inline-block' }}
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onWheel={handleScroll}
        >
          <canvas
            ref={canvasRef}
            width={screenWidth === 'small' ? 512 : 1024}
            height={screenWidth === 'small' ? 384 : 768}
            style={{ display: 'block' }}
          />
        </div>
        <IconButton size="small" onClick={toggleScreenWidth}>
          {screenWidth === 'small' ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
        <IconButton size="small" onClick={() => closeSession(email)}>
          <Close />
        </IconButton>
      </div>
    );
  };

  return (
    <Box component={Paper} mb={2}>
      <h3>Active Sessions</h3>
      {sessions.map((session) => (
        <div key={session.sessionId} style={{ padding: 2 }}>
          <div
            style={{
              backgroundColor: '#0006',
              padding: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{session.email}</span>
            {activeSessions[session.email] ? (
              <PuppeteerScreenComponent
                email={session.email}
                wsEndpoint={activeSessions[session.email]}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => startSession(session.email)}
              >
                Open Session
              </Button>
            )}
          </div>
        </div>
      ))}
    </Box>
  );
}

function NavigationBar({ url, setUrl, onNavigate, onBack, onForward, onRefresh, onUrlInputFocus, onUrlInputBlur }) {
  return (
    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
      <Button onClick={onBack}>←</Button>
      <Button onClick={onForward}>→</Button>
      <Button onClick={onRefresh}>⟳</Button>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && onNavigate()}
        onFocus={onUrlInputFocus}
        onBlur={onUrlInputBlur}
        style={{ width: '400px', marginRight: '10px' }}
      />
      <Button onClick={onNavigate}>Go</Button>
    </div>
  );
}
