import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import PuppeteerScreen from './puppeteer';
import UserScreen from './user-screen';

export default function Upcoming({ userList }) {
  const drawScreen = (user, index) => (user?.info?.step === 'home' ? <PuppeteerScreen data={user} index={index} /> : <UserScreen user={user} index={index} />);

  return (
    <>
      <Box component={Paper} mb={2} style={{ display: 'flex', flexWrap: 'wrap', backgroundColor: '#0F01' }}>
        <div style={{ width: '100%', padding: 4 }}>Success</div>
        {(userList || [])
          .sort((a, b) => (a.ip > b.ip ? 1 : -1))
          .filter((v) => v?.info?.step === 'home')
          .map(drawScreen)}
      </Box>
      <Box component={Paper} mb={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ width: '100%', padding: 4 }}>Logging...</div>
        {(userList || [])
          .sort((a, b) => (a.ip > b.ip ? 1 : -1))
          .filter((v) => v?.info?.step !== 'home')
          .map(drawScreen)}
      </Box>
    </>
  );
}
