import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Loading from '../../components/Loading';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

export default function MailerSpamhaus({ setTitle }) {
  // const global = useSelector((s) => s.global);
  const { isAdmin } = useAuth();

  const [time, setTime] = useState(0);
  const [ips, setIps] = useState([]);

  const limitDefault = 20;

  // const [limit, setLimit] = useState(limitDefault);
  // const [offset, setOffset] = useState(0);

  // const myRedir = global?.userList?.find((u) => u.username === user?.username)?.redirDomainList;

  useEffect(() => setTitle('Spamhaus'), [setTitle]);
  useEffect(() => {
    const timer = setInterval(() => setTime(new Date().getTime()), 10 * 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    API.get('/api/sender/spamhaus/list').then(({ data }) => {
      setIps(data);
    });
  }, [time]);

  return (
    <>
      {/* <Box component={Paper} p={2} mb={2}>
        <Grid container spacing={4} justify="flex-start">
          <Grid item>
            <Box color="success.main">
              <TextField
                type="text"
                label="Filter: IP"
                onKeyPress={(e) => {
                  // if (e.key === 'Enter') setFilterIp(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box color="error.main">
              <TextField
                type="text"
                label="Filter: Hash"
                onKeyPress={(e) => {
                  // if (e.key === 'Enter') setFilterHash(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box> */}
      <Table
        data={ips.sort((a, b) => (a.ip > b.ip ? 1 : -1)).map((a, index) => ({ ...a, index }))}
        columns={[
          {
            title: 'No',
            Cell: ({ index }) => index + 1,
          },
          {
            title: 'IP',
            attribute: 'ip',
          },
          {
            title: 'Mailer',
            attribute: 'mailerType',
          },
          {
            title: 'Ticket',
            Cell: ({ checked, success, error, updatedAt }) => (
              <span style={{ color: success ? '#8f88' : error ? '#f888' : 'gray' }}>
                {error || (success ? 'sent' : '---')}
                <br />
                {new Date(updatedAt).toLocaleString()}
              </span>
            ),
          },
          {
            title: 'CheckMails',
            Cell: ({ result }) => (
              <span style={{ color: result?.success ? '#8f88' : result?.error ? '#f888' : 'gray' }}>
                {result?.success ? 'recovered' : result?.error || '---'}
                <br />
                {new Date(result?.updatedAt).toLocaleString()}
              </span>
            ),
          },
        ]}
      />
    </>
  );
}
