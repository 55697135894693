import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle }) {
  useEffect(() => setTitle('Auto Refresh'), [setTitle]);
  const { isAdmin, user } = useAuth();
  const [logs, setLogs] = useState([]);

  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    API.get('/api/sv/devices-refresh/logs').then(({ data }) => setLogs(data));
  }, [time]);

  const { data } = useFetch('/api/sv/devices-refresh', { refreshInterval: 10000 }) || {};
  const refreshStatus = data?.status;

  return !(isAdmin || user.adminDevice) ? (
    <></>
  ) : (
    <>
      <div style={{ fontFamily: 'sans-serif' }}>
        <Box component={Paper} mb={2}>
          <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp; Refresh &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                size="small"
                onClick={() => {
                  API.post(`/api/sv/devices-refresh/${refreshStatus ? 'pause' : 'resume'}`);
                }}
              >
                {refreshStatus ? <Icons.Pause style={{ color: 'lightgreen' }} /> : <Icons.PlayArrow style={{ color: '#F88' }} />}
              </Button>
            </span>
          </div>
        </Box>
        <Box>
          {logs
            // .filter((log) => log.moduleName === 'AutoRefresh')
            .slice(Math.max(0, logs.length - 40))
            .map((log, index) => (
              <>
                <div key={index} style={{ color: log.type === 'error' ? '#F888' : 'green' }}>
                  <span style={{ color: '#aaa' }}>{new Date(log.time).toLocaleString()}</span>&nbsp;&nbsp;&nbsp;
                  {log.content.includes(':') && <span style={{ color: 'darkcyan' }}>[{log.content.slice(0, log.content.indexOf(':'))}]</span>}&nbsp;&nbsp;
                  <span>{log.content.slice(log.content.indexOf(':') + 1)}</span>
                </div>
              </>
            ))}
        </Box>
      </div>
    </>
  );
}
