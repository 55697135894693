import React from 'react';
import CurrencyFormat from 'react-currency-format';

import Box from '@material-ui/core/Box';

export default function Money({
  value,
  prefix = 'R$ ',
  suffix = '',
  decimals = 2,
  component = 'span',
  ...rest
}) {
  return (
    <Box component={component} whiteSpace="nowrap" {...rest}>
      <CurrencyFormat
        value={value}
        prefix={prefix}
        suffix={suffix}
        displayType="text"
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={decimals}
      />
    </Box>
  );
}
