import React from 'react';

import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Button({
  children,
  loading,
  loadingProps,
  ...buttonProps
}) {
  if (loading) {
    buttonProps.disabled = true;
  }

  return (
    <MUIButton
      color="primary"
      size="large"
      variant="contained"
      {...buttonProps}
    >
      {Boolean(loading) && (
        <>
          <CircularProgress color="primary" size={20} {...loadingProps} />
          &nbsp;&nbsp;
        </>
      )}
      {children}
    </MUIButton>
  );
}
