import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import Color from '../../components/Form/Color';
import Input from '../../components/Form/Input';
import useAuth from '../../hooks/useAuth';
import UserService from '../../services/UserService';

export default function UsersForm({ setTitle }) {
  const formRef = useRef(null);

  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  const { user, isAdmin } = useAuth();

  const id = params.id || null;
  const hasId = Boolean(id) && id !== 'create';
  const matchParentUrl = match.url.split('/').slice(0, -1).join('/');

  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState({});
  // const [groupUsers, setGroupUsers] = useState(null);

  useEffect(() => setTitle('Editar Usuário'), [setTitle]);

  // // Get groups
  // useEffect(() => {
  //   MlMpApi.get('groups_users/')
  //     .then(({ data }) => setGroupUsers(data || []))
  //     .catch(() => null);
  // }, []);

  // Get model data
  useEffect(() => {
    if (hasId) {
      UserService.get(id).then(({ data }) => {
        data.userMlMp = data.userMlMp || '';
        setModel(data);
      });
    }
  }, [formRef, hasId, id, setModel]);

  useEffect(() => {
    if (model) {
      formRef.current.setData(model);
    }
  }, [model]);

  const handleDomains = (str) => {
    return `${str || ''}`
      .trim()
      .replace(/https:\/\//g, '')
      .replace(/http:\/\//g, '')
      .split(`\n`)
      .map((url) => url.replace(/\/$/g, '').trim())
      .filter(Boolean)
      .join(`\n`);
  };

  async function handleSubmit(data) {
    data.defaultRedir = (data.defaultRedir || '').trim();
    data.braRedir = (data.braRedir || '').trim();
    data.netRedir = (data.netRedir || '').trim();
    data.bbRedir = (data.bbRedir || '').trim();
    data.uniRedir = (data.uniRedir || '').trim();
    data.viaRedir = (data.viaRedir || '').trim();

    setSaving(true);

    if (data.redirDomains) {
      data.redirDomains = handleDomains(data.redirDomains);
    }

    try {
      await UserService.save(data);

      alert('O registro foi salvo! Confira se os Domínios (Redir) ficaram corretos.');

      history.push(`${matchParentUrl}`);
    } catch (err) {
      alert(err?.response?.data?.message || 'Houve uma falha na requisição.');
      setSaving(false);
    }
  }

  // if (!groupUsers) {
  //   return <div>Carregando...</div>;
  // }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Hidden xsUp implementation="css">
        <Input name="_id" />
      </Hidden>
      <Box component={Paper} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input required label="Usuário" name="username" inputProps={{ minLength: 5 }} autoFocus={!hasId} disabled={hasId} />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input required label="Senha" name="password" inputProps={{ minLength: 6 }} />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="Redir" name="defaultRedir" />
          </Grid>

          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="Bradesco Redir" name="braRedir" />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="BB Redir" name="bbRedir" />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="Netflix Redir" name="netRedir" />
          </Grid>

          {/* 
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="UNICRED Redir" name="uniRedir" />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input label="VIACREDI Redir" name="viaRedir" />
          </Grid> */}
          
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Grid container justify="space-around" spacing={1}>
              <Input label="VMs" name="vms" style={{ width: '40%' }} />
              &nbsp;
              <Input label="Max Balance" name="maxBalance" type="number" style={{ width: '40%' }} />
              &nbsp;
              <Color required label="Cor" name="color" style={{ width: '10%' }} />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          {isAdmin && (
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <Grid container justify="space-around" spacing={2}>
                <span style={{ width: '70%' }}>
                  <Typography variant="body2">Tipo de Conta</Typography>
                  <Checkbox label="Admin" size="small" name="admin" disabled={model.admin && user?._id === id} />
                  <Checkbox label="MPAll" size="small" name="adminMPAll" />
                  <Checkbox label="MP" size="small" name="adminMP" />
                  <Checkbox label="BB" size="small" name="adminBB" />
                  <Checkbox label="Device" size="small" name="adminDevice" />
                  <Checkbox label="Mailer" size="small" name="adminMailer" />
                  <Checkbox label="Domain" size="small" name="adminDomain" />
                </span>
                <span style={{ width: '20%' }}>
                  <Typography variant="body2">Exibição</Typography>
                  <Checkbox label="Público" size="small" name="public" disabled={model.public && user?._id === id} />
                </span>
              </Grid>
            </Grid>
          )}
          {/*<Grid item xs={12} style={{ padding: 0 }} />*/}
          {/*<Grid item xs={12} md={8} lg={6} xl={4}>*/}
          {/*  <Select*/}
          {/*    label="Usuário MP/ML"*/}
          {/*    name="userMlMp"*/}
          {/*    defaultValue={model?.userMlMp || ''}*/}
          {/*    options={groupUsers.map((user) => ({*/}
          {/*      value: user._id,*/}
          {/*      label: `${user.title} (ID: ${user._id})`,*/}
          {/*    }))}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <Input multiline label="Domínios (Redir)" name="redirDomains" rows="10" />
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }} />
          <Grid item xs={12} md={8} lg={6} xl={4} align="right">
            <Button type="submit" size="large" loading={saving}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
}
