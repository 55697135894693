import React, { memo, useCallback, useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import TableUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { fade } from '@material-ui/core/styles/colorManipulator';

import theme from '../../config/theme';

const call = (fn, ...args) => (typeof fn === 'function' ? fn(...args) : null);
const themeColors = ['secondary', 'error', 'warning', 'info', 'success'];

const getHexColor = function (row) {
  let color = row.color || row?.owners?.[0]?.color || '#000';

  if (themeColors.includes(color)) {
    color = theme.palette[color].main;
  }

  // Priorizar estas cores (o último é o prioritário), de acordo com status
  if (hasHighBalance(row)) color = '#8F83';
  if (row?.done) color = '#F883';

  return color;
};
const hasHighBalance = (row) => Math.max(row?.balance || 0, row?.MLCredito || 0, row?.MPCredito || 0, row?.MPExpress || 0, row?.MPCardLimit || 0) >= 800;

const HeadComponent = ({ column, columnKey, headStyle, onHeadClick }) => {
  return (
    <TableCell align={column.align} style={call(headStyle, column, columnKey)} onClick={(e) => call(onHeadClick, e, column.sortAttribute || column.attribute, column)}>
      {column.Header ? column.Header(column) : <b>{column.title}</b>}
    </TableCell>
  );
};

const CellComponent = ({ attribute, align = 'left', defaultValue = '', Cell, cellStyle, row, rowKey, onCellClick }) => {
  // const isEmpty = attribute && (typeof row[attribute] === 'undefined' || row[attribute] === null);

  const value = Cell ? Cell(row) : row[attribute];

  return (
    <TableCell align={align} style={call(cellStyle, attribute, row, rowKey)} onClick={(e) => call(onCellClick, e, attribute, row)}>
      {value || defaultValue || ''}
    </TableCell>
  );
};

const HeadItem = memo(HeadComponent);
const CellItem = memo(CellComponent);

const TableComponent = ({
  columns = [],
  data = [],
  pagination = false,
  paginationTop = false,
  page = 0,
  rowsPerPage = 100,
  reverse = false,
  selectRow = () => false,
  empty = 'Nenhum registro.',
  /** headStyle = (column, columnKey) => ({}) */
  headStyle = ((column) => (column.attribute === '_id' ? { padding: 0 } : null), []),
  /** rowStyle = (row, rowKey) => ({}) */
  rowStyle = (() => ({ cursor: 'pointer' }), []),
  /** cellStyle = (attribute, row, rowKey) => ({}) */
  cellStyle = ((attribute, row) => ({
    backgroundColor: fade(getHexColor(row), 0.2),
    ...(attribute === '_id' && { padding: 0 }),
  }),
  []),
  /** onHeadClick = (e, attribute, column) => null */
  onHeadClick = null,
  /** onRowClick = (e, row, rowKey) => null */
  onRowClick = null,
  /** onCellClick = (e, attribute, row) => null */
  onCellClick = null,
  ...rest
}) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsShown, setRowsShown] = useState(rowsPerPage);
  // const [currentRows, setCurrentRows] = useState([]);

  const rowsPerPageOptions = [50, 100, 250, 500];

  useEffect(() => {
    if (data) {
      setRows(reverse ? [...data].reverse() : data);
    } else {
      setRows([]);
    }
  }, [data, reverse]);

  const handleChangePage = useCallback((e, newPage) => setCurrentPage(newPage), [setCurrentPage]);

  const handleChangeRowsPerPage = useCallback(
    (e) => {
      setCurrentPage(0);
      setRowsShown(e.target.value);
    },
    [setCurrentPage, setRowsShown]
  );

  let currentRows = rows;

  if (pagination) {
    const offset = currentPage * rowsShown;
    currentRows = [...rows].splice(offset, rowsShown);
  }

  return (
    <TableContainer component={Paper}>
      {Boolean(pagination) && Boolean(paginationTop) && (
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsShown}
          page={currentPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      )}
      <Divider />
      <TableUI stickyHeader size="small" {...rest}>
        {currentRows.length > 0 && (
          <TableHead>
            <TableRow>
              {columns.map((column, columnKey) => (
                <HeadItem key={column.key || column.attribute || columnKey} column={column} columnKey={columnKey} headStyle={headStyle} onHeadClick={onHeadClick} />
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {!rows.length && (
            <TableRow>
              <TableCell colSpan={columns.length}>{empty}</TableCell>
            </TableRow>
          )}
          {currentRows.map((row, rowKey) => (
            <TableRow key={rowKey} hover selected={selectRow(row)} style={call(rowStyle, row, rowKey)} onClick={(e) => call(onRowClick, e, row, rowKey)}>
              {columns.map(({ key, attribute, align, defaultValue, Cell }, columnKey) => (
                <CellItem
                  key={key || attribute || columnKey}
                  attribute={attribute}
                  align={align}
                  defaultValue={defaultValue}
                  Cell={Cell}
                  cellStyle={cellStyle}
                  row={row}
                  rowKey={rowKey}
                  onCellClick={onCellClick}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableUI>
      {Boolean(pagination) && (
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsShown}
          page={currentPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

const Table = memo(TableComponent);

export default Table;
