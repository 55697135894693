import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { Home } from '@material-ui/icons';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';

import Html from '../../components/Form/Html';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

export default function MailTemplates({ setTitle }) {
  const { templateId } = useParams();
  const { isAdmin, isAdminMailer, user } = useAuth();
  const users = useSelector((s) => s.global.userList);

  const [templates, setTemplates] = useState([]);
  const [html, setHtml] = useState('');

  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [sourceMode, setSourceMode] = useState(false);

  useEffect(() => setTitle(`Mail Templates ${templateId ? '- ' + templateId : ''}`), [setTitle]);

  const refresh = async () => {
    try {
      const response = await API.get(`/template`);
      setTemplates(response.data.filter((l) => (templateId ? l._id === templateId : true)));
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    refresh();
  }, [templateId]);

  const columns = [
    {
      title: 'ID',
      attribute: '_id',
    },
    {
      title: 'Sender',
      attribute: 'senderName',
    },
    {
      title: 'Subject',
      attribute: 'subject',
    },
    {
      title: 'HTML',
      attribute: 'html',
      Cell: ({ html }) => (html && html.length > 70 ? html.substring(0, 67) + ' ...' : html),
    },
    {
      title: 'Hot Domain',
      attribute: 'hotmailerDomain',
    },
    {
      title: 'Created',
      attribute: 'createdAt',
      Cell: ({ createdAt }) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title="Show detail"
              variant="outlined"
              onClick={() => {
                setCurrentTemplate(row);
                setHtml(row.html);
              }}
            >
              <DetailsIcon fontSize="small" />
            </Button>
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete?`)) {
                  API.delete(`/template/${row._id}`)
                    .then(() => refresh())
                    .catch((error) => console.error('Error deleting template:', error));
                }
              }}
              disabled={row.userId !== user.username}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  return (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Templates</h3>
          {templateId ? (
            <Button
              title="Home"
              onClick={() => {
                window.location.href = '/mailer/templates';
              }}
            >
              <Home /> &nbsp;&nbsp;Templates page
            </Button>
          ) : (
            <Button
              title="Add Mail Template"
              onClick={() => {
                setCurrentTemplate({ userId: user.username });
              }}
            >
              <AccountBoxIcon /> &nbsp;&nbsp;Add Mail Template
            </Button>
          )}
        </div>
        <Table size="small" data={templates} columns={columns} />
        <Dialog open={!!currentTemplate} onClose={() => setCurrentTemplate(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              setCurrentTemplate(null);
              API.post(`/template`, param)
                .then(() => refresh())
                .catch((error) => console.error('Error creating/updating template:', error));
            }}
          >
            <DialogTitle>{currentTemplate?._id ? 'Update' : 'Add'} mail template</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentTemplate?._id} style={{ display: 'none' }} />
              <Input label="Sender Name" type="text" name="senderName" defaultValue={currentTemplate?.senderName} autoFocus required />
              <p />
              <Input label="Subject" type="text" name="subject" defaultValue={currentTemplate?.subject} required />
              <p />
              <Input label="Text" type="text" name="text" defaultValue={currentTemplate?.text} multiline rows={2} style={{ display: 'none' }} />
              <Input label="Hotmailer Domain" type="text" name="hotmailerDomain" defaultValue={currentTemplate?.hotmailerDomain || 'mercadopago.com.br'} />
              <p />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    setSourceMode(!sourceMode);
                  }}
                >
                  <EditIcon />
                  &nbsp;&nbsp;{sourceMode ? 'Show mail' : 'Show source'}
                </Button>
                <Button
                  onClick={() => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = (e) => {
                      if (input.files.length > 0) {
                        var file = input.files[0];
                        if (!file) return;
                        var reader = new FileReader();
                        reader.onload = (e) => {
                          setHtml(e.target.result);
                        };
                        reader.readAsText(file);
                      }
                    };
                    input.click();
                  }}
                >
                  <MailIcon />
                  &nbsp;&nbsp;Import mail template from html
                </Button>
              </div>
              <p />
              <Html html={html} height={380} style={{ display: sourceMode ? 'none' : 'block' }} />
              <Input
                name="html"
                type="text"
                multiline
                rows={18}
                onChange={(e) => {
                  setHtml(e.target.value);
                }}
                value={html}
                defaultValue={currentTemplate?.html}
                style={{ display: sourceMode ? 'block' : 'none' }}
                required
              />
              <p />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentTemplate(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={currentTemplate?.userId !== user.username}>
                {currentTemplate?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>
    </>
  );
}
