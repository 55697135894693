import { createTheme } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/blue';
import secondary from '@material-ui/core/colors/yellow';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary,
    secondary,
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: 12,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '.5rem',
          height: '.5rem',
        },
        '*::-webkit-scrollbar-track': {
          borderRadius: 'inherit',
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, .3)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 'inherit',
          backgroundColor: 'darkgrey',
        },
      },
    },
  },
});

export default theme;
