import React, { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function Pixes({}) {
  const [offset, setOffset] = useState(0);
  const [modelComment, setModelComment] = useState(null);
  const formCommentRef = useRef(null);
  const limit = 100;
  const { user, isAdminMP } = useAuth();

  const { data: logs } = useFetch(`/api/sv/bradesco/pix?offset=${offset * limit}&limit=${limit}`, { refreshInterval: 5000 });
  const { data: logCount } = useFetch(`/api/sv/bradesco/pix/len`);

  const columns = [
    {
      title: 'No',
      Cell: ({ index, enabled }) => <span style={{ color: enabled ? 'inherit' : 'gray' }}>{index + 1}</span>,
    },
    {
      title: 'Code',
      Cell: ({ _id, enabled }) => <span style={{ color: enabled ? 'inherit' : 'gray' }}>{_id}</span>,
    },
    {
      title: 'Created',
      attribute: 'createdAt',
      Cell: ({ createdAt, enabled }) => <span style={{ color: enabled ? 'inherit' : 'gray' }}>{new Date(createdAt).toLocaleString('pt-br')}</span>,
    },
    {
      title: 'Coment',
      Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title={row.enabled ? 'Disable' : 'Enable'}
              onClick={() => {
                API.post(`/api/sv/bradesco/pix/${row._id}`, { enabled: !row.enabled });
              }}
            >
              {row.enabled ? <Icons.Stop fontSize="small" /> : <Icons.PlayArrow fontSize="small" />}
            </Button>
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete the Pix?`)) {
                  API.delete(`/api/sv/bradesco/pix/${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];
  return (
    <>
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Pagination size="large" color="secondary" showFirstButton count={Math.floor(logCount / 100) + 1} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
          <Button
            onClick={() => {
              const pix = window.prompt('input pix code');
              if (pix) {
                API.post(`/api/sv/bradesco/pix/${pix}`, { enabled: true });
              }
            }}
          >
            <Icons.Add />
            Add
          </Button>
        </div>
        <Table data={logs?.map((l, index) => ({ index, ...l })) || []} columns={columns} pagination={false} rowsPerPage={0} />
      </>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={(params) => {
            API.put(`/api/sv/bradesco/pix/${modelComment._id}`, {
              comments: [
                ...(modelComment.comments || []),
                {
                  type: 'Manual',
                  owner: user.username,
                  description: params.comment,
                  createdAt: new Date().getTime(),
                },
              ],
            });
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" defaultValue={modelComment?.comment || ''} />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment) => (
              <CommentItem comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
