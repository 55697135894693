import axios from 'axios';
import StorageHelper from '../helpers/StorageHelper';
import config from '../config'; // Importar config

export default class AuthService {
  static async login(username, password) {
    try {
      const response = await axios.post(`${config.authSv}/api/login`, { username, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.data.token && response.data.user) {
        const { token, user } = response.data;

        StorageHelper.setItem(StorageHelper.KEYS.TOKEN, token);
        StorageHelper.setItem(StorageHelper.KEYS.USER, user);

        window.location.href = '/online';

        return response;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('AuthService: Error during login', error);
      throw error;
    }
  }

  static async logout() {
    StorageHelper.clear();
    window.location.href = '/login';
  }

  static isAuthenticated() {
    return Boolean(StorageHelper.getToken());
  }
}