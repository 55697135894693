import { info } from 'toastr';

import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';

import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle, setHeaderRight }) {
  useEffect(() => setTitle('Digitalocean, Linode'), [setTitle]);
  const { isAdmin, isAdminMailer, user } = useAuth();

  const [currentDigitalOcean, setCurrentDigitalOcean] = useState(null);
  const [digitaloceans, setDigitalOceans] = useState([]);
  const [digitaloceanVMs, setDigitalOceanVMs] = useState(null);

  const [currentLinode, setCurrentLinode] = useState(null);
  const [linodes, setLinodes] = useState([]);
  const [linodeVMs, setLinodeVMs] = useState(null);
  const [showLocked, setShowLocked] = useState(false);

  const refresh = () => {
    API.get(`/vps-account`).then((res) => {
      const data = res.data || [];
      setDigitalOceans(data.filter((r) => r.type === 'digitalocean'));
      setLinodes(data.filter((r) => r.type === 'linode'));
      data
        .filter((r) => r.type === 'digitalocean')
        .map((acc) => {
          API.get(`/digitalocean/vms?token=${acc.info.token}`).then(({ data }) => {
            setDigitalOceans((dos) => dos.map((a) => (a.info.token === acc.info.token ? { ...a, vms: data.vms } : a)));
          });
        });
      data
        .filter((r) => r.type === 'linode')
        .map((acc) => {
          API.get(`/linode/vms?token=${acc.info.token}`).then(({ data }) => {
            setLinodes((dos) => dos.map((a) => (a.info.token === acc.info.token ? { ...a, vms: data.vms } : a)));
          });
        });
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    setHeaderRight(
      <>
        <FormControlLabel control={<Switch checked={showLocked} onChange={() => setShowLocked(!showLocked)} />} label="Locked" />
      </>
    );
    return () => setHeaderRight(null);
  }, [setHeaderRight, showLocked]);

  return !(isAdmin || isAdminMailer) ? (
    <></>
  ) : (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" onClick={() => setCurrentDigitalOcean({})}>
              <Icons.Add fontSize="small" />
            </Button>
            DigitalOcean ({digitaloceans?.length})
          </span>
        </div>
        <Table
          size="small"
          // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
          data={
            digitaloceans
              ?.filter((l) => showLocked || !l.locked)
              .sort((a, b) => (!a.locked && b.locked ? -1 : a.locked && !b.locked ? 1 : 0))
              .map((a, index) => ({ ...a, index: index + 1 })) || []
          }
          columns={[
            {
              title: 'ID',
              attribute: 'index',
              Cell: ({ index, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{index}</span>,
            },
            {
              title: 'Lock',
              Cell: ({ locked }) => (locked ? <Icons.Lock style={{ fontSize: 16, color: '#F88' }} /> : <Icons.LockOpen style={{ fontSize: 16, color: 'green' }} />),
            },
            {
              title: 'Name / Owner',
              attribute: 'info',
              Cell: ({ info, owner, enabled, locked }) => (
                <>
                  <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{info.name}</span>
                  <br />
                  <span style={{ color: !enabled || locked ? 'gray' : 'green' }}>{owner}</span>
                </>
              ),
            },
            {
              title: 'Token/Proxy',
              attribute: 'info',
              Cell: ({ info, enabled, locked }) => (
                <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>
                  {info.token}
                  <br />
                  {info.proxy}
                </span>
              ),
            },
            {
              title: 'Created',
              attribute: 'createdAt',
              Cell: ({ createdAt, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{new Date(createdAt).toLocaleString()}</span>,
            },
            {
              title: 'Current VMs',
              Cell: ({ vms, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{vms?.length || '0'}</span>,
            },
            {
              title: 'SenderType',
              attribute: 'senderType',
              Cell: ({ senderType, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{senderType}</span>,
            },
            {
              title: (
                <>
                  Speed
                  <br />
                  Hot/Other
                </>
              ),
              attribute: 'speed',
              Cell: ({ hotmailSpeed, othermailSpeed, enabled, locked }) => (
                <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>
                  {hotmailSpeed} / {othermailSpeed}
                </span>
              ),
            },
            {
              title: 'Max VMs',
              attribute: 'maxvms',
              Cell: ({ maxvms, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{maxvms}</span>,
            },
            {
              title: 'Max Deploy',
              attribute: 'maxDeploy',
              Cell: ({ maxDeploy, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{maxDeploy || 1}</span>,
            },
            {
              title: 'AutoSend',
              attribute: 'autosend',
              Cell: ({ autosend, enabled, locked }) =>
                autosend ? <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>TRUE</span> : <span style={{ color: !enabled || locked ? 'gray' : '#F338' }}>FALSE</span>,
            },
            {
              title: 'Action',
              Cell: (row) => (
                <>
                  <ButtonGroup size="small">
                    <Button title="Toggle" onClick={() => API.put(`/vps-account/${row._id}`, { enabled: !row.enabled }).then((r) => refresh())}>
                      {row.enabled ? <Icons.Pause /> : <Icons.PlayArrow />}
                    </Button>
                    <Button title="Edit" onClick={() => setCurrentDigitalOcean(row)}>
                      <Icons.Edit fontSize="small" />
                    </Button>
                    <Button
                      title="Remover"
                      onClick={() => {
                        if (window.confirm(`Are you sure to delete the DigitalOcean account`)) {
                          API.delete(`/vps-account/${row._id}`).then(() => refresh());
                        }
                      }}
                    >
                      <Icons.Delete fontSize="small" />
                    </Button>
                    <Button
                      title="List VMs"
                      onClick={() => {
                        API.get(`/digitalocean/vms?token=${row.info.token}`).then(({ data }) => {
                          setDigitalOceanVMs({ token: row.info.token, vms: row.vms });
                        });
                      }}
                    >
                      <Icons.List fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </>
              ),
            },
          ]}
          pagination={true}
          paginationTop={true}
          rowsPerPage={50}
        />
        <Dialog open={!!digitaloceanVMs} onClose={() => setDigitalOceanVMs(null)} fullWidth>
          <Form>
            <DialogTitle>VMs</DialogTitle>
            <DialogContent>
              <Table
                size="small"
                // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
                data={digitaloceanVMs?.vms?.map((a, index) => ({ ...a, index: index + 1 })) || []}
                columns={[
                  {
                    title: 'ID',
                    attribute: 'index',
                  },
                  {
                    title: 'IP',
                    attribute: 'ip',
                  },
                  {
                    title: 'Domain',
                    attribute: 'name',
                  },
                  {
                    title: 'Created',
                    attribute: 'created_at',
                    Cell: ({ created_at }) => new Date(created_at).toLocaleString(),
                  },
                  {
                    title: '',
                    Cell: (vm) => (
                      <>
                        <ButtonGroup size="small">
                          <Button
                            title="Remover"
                            onClick={() => {
                              if (window.confirm(`Are you sure to delete ${vm.ip}`)) {
                                API.delete(`/digitalocean/vms/${vm.ip}?token=${digitaloceanVMs?.token}`);
                              }
                            }}
                          >
                            <Icons.Delete fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </>
                    ),
                  },
                ]}
              />
              <p />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  API.post(`/digitalocean/vms?token=${digitaloceanVMs?.token}`).finally(() => setDigitalOceanVMs(null));
                }}
                color="primary"
              >
                Add One
              </Button>
              <Button
                type="button"
                color="default"
                onClick={() => {
                  if (window.confirm('Are you sure to delete all VMs')) {
                    for (let vm of digitaloceanVMs.vms) {
                      if (vm.ip) API.delete(`/digitalocean/vms/${vm.ip}?token=${digitaloceanVMs?.token}`);
                    }
                  }
                }}
              >
                Delete All
              </Button>
              <Button onClick={() => setDigitalOceanVMs(null)} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
        <Dialog open={!!currentDigitalOcean} onClose={() => setCurrentDigitalOcean(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              param.info = { name: param.name, token: param.token, proxy: param.proxy };
              delete param.name;
              delete param.token;
              delete param.proxy;
              API.put(`/vps-account/${param._id || 'do-' + new Date().getTime()}`, param)
                .then(() => refresh())
                .catch((e) => {
                  window.alert(`Cannot add account: ${e.response?.data?.error}`);
                });
              setCurrentDigitalOcean(null);
            }}
          >
            <DialogTitle>{currentDigitalOcean?._id ? 'Update' : 'Add'} Digitalocean</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentDigitalOcean?._id} style={{ display: 'none' }} />
              <Input type="text" name="type" value="digitalocean" style={{ display: 'none' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="Name" type="text" name="name" defaultValue={currentDigitalOcean?.info?.name} autoFocus required />
                &nbsp;&nbsp;
                <Input label="SenderType" select name="senderType" defaultValue={currentDigitalOcean?.senderType || 'HOTMAIL'} required>
                  <MenuItem key="HOTMAIL" value="HOTMAIL">
                    Hotmail
                  </MenuItem>
                  <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                    OtherMail
                  </MenuItem>
                  <MenuItem key="BOTH" value="BOTH">
                    Both
                  </MenuItem>
                  <MenuItem key="DDOS" value="DDOS">
                    DDos
                  </MenuItem>
                </Input>
              </div>
              <p />
              <Input label="Token" type="text" name="token" defaultValue={currentDigitalOcean?.info?.token} required />
              <p />
              <Input label="Proxy" type="text" name="proxy" defaultValue={currentDigitalOcean?.info?.proxy} />
              <p />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentDigitalOcean?.hotmailSpeed || 150} required />
                &nbsp;&nbsp;
                <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentDigitalOcean?.othermailSpeed || 150} required />
              </div>
              <p />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="MAX VMs" type="number" name="maxvms" defaultValue={currentDigitalOcean?.maxvms || 9} required />
                &nbsp;&nbsp;
                <Input label="MAX Deploy in 1 hour" type="number" name="maxDeploy" defaultValue={currentDigitalOcean?.maxDeploy || 1} required />
              </div>
              <p />
              <Input name="autosend" type="checkbox" label="Run from 4:00 to 18:00" defaultValue={currentDigitalOcean?.autosend || false} />
              <p />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentDigitalOcean(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {currentDigitalOcean?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" onClick={() => setCurrentLinode({})}>
              <Icons.Add fontSize="small" />
            </Button>
            Linode ({linodes?.length})
          </span>
        </div>
        <Table
          size="small"
          data={
            linodes
              ?.filter((l) => showLocked || !l.locked)
              .sort((a, b) => (!a.locked && b.locked ? -1 : a.locked && !b.locked ? 1 : 0))
              .map((a, index) => ({ ...a, index: index + 1 })) || []
          }
          columns={[
            {
              title: 'ID',
              attribute: 'index',
              Cell: ({ index, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{index}</span>,
            },
            {
              title: 'Lock',
              Cell: ({ locked }) => (locked ? <Icons.Lock style={{ fontSize: 16, color: '#F88' }} /> : <Icons.LockOpen style={{ fontSize: 16, color: 'green' }} />),
            },
            {
              title: 'Name / Owner',
              attribute: 'info',
              Cell: ({ info, owner, enabled, locked }) => (
                <>
                  <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{info.name}</span>
                  <br />
                  <span style={{ color: !enabled || locked ? 'gray' : 'green' }}>{owner}</span>
                </>
              ),
            },
            {
              title: 'Token/Proxy',
              attribute: 'info',
              Cell: ({ info, enabled, locked }) => (
                <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>
                  {info.token}
                  <br />
                  {info.proxy}
                </span>
              ),
            },
            {
              title: 'Created',
              attribute: 'createdAt',
              Cell: ({ createdAt, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{new Date(createdAt).toLocaleString()}</span>,
            },
            {
              title: 'Current VMs',
              Cell: ({ vms, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>{vms?.length || '0'}</span>,
            },
            {
              title: 'SenderType',
              attribute: 'senderType',
              Cell: ({ senderType, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{senderType}</span>,
            },
            {
              title: (
                <>
                  Speed
                  <br />
                  Hot/Other
                </>
              ),
              attribute: 'speed',
              Cell: ({ hotmailSpeed, othermailSpeed, enabled, locked }) => (
                <span style={{ color: !enabled || locked ? 'gray' : 'inherit' }}>
                  {hotmailSpeed} / {othermailSpeed}
                </span>
              ),
            },
            {
              title: 'Max VMs',
              attribute: 'maxvms',
              Cell: ({ maxvms, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{maxvms}</span>,
            },
            {
              title: 'Max Deploy',
              attribute: 'maxDeploy',
              Cell: ({ maxDeploy, enabled, locked }) => <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>{maxDeploy || 1}</span>,
            },
            {
              title: 'AutoSend',
              attribute: 'autosend',
              Cell: ({ autosend, enabled, locked }) =>
                autosend ? <span style={{ color: !enabled || locked ? 'gray' : 'lightgreen' }}>TRUE</span> : <span style={{ color: !enabled || locked ? 'gray' : '#F338' }}>FALSE</span>,
            },
            {
              title: 'Action',
              Cell: (row) => (
                <>
                  <ButtonGroup size="small">
                    <Button title="Toggle" onClick={() => API.put(`/vps-account/${row._id}`, { enabled: !row.enabled }).then((r) => refresh())}>
                      {row.enabled ? <Icons.Pause /> : <Icons.PlayArrow />}
                    </Button>
                    <Button title="Edit" onClick={() => setCurrentLinode(row)}>
                      <Icons.Edit fontSize="small" />
                    </Button>
                    <Button
                      title="Remover"
                      onClick={() => {
                        if (window.confirm(`Are you sure to delete the Linode account`)) {
                          API.delete(`/vps-account/${row._id}`).then(() => refresh());
                        }
                      }}
                    >
                      <Icons.Delete fontSize="small" />
                    </Button>
                    <Button
                      title="List VMs"
                      onClick={() => {
                        API.get(`/linode/vms?token=${row.info.token}`).then(({ data }) => {
                          setLinodeVMs({ token: row.info.token, vms: row.vms });
                        });
                      }}
                    >
                      <Icons.List fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </>
              ),
            },
          ]}
          pagination={true}
          paginationTop={true}
          rowsPerPage={50}
        />
        <Dialog open={!!linodeVMs} onClose={() => setLinodeVMs(null)} fullWidth>
          <Form>
            <DialogTitle>Linode VMs</DialogTitle>
            <DialogContent>
              <Table
                size="small"
                data={linodeVMs?.vms?.map((a, index) => ({ ...a, index: index + 1 })) || []}
                columns={[
                  {
                    title: 'ID',
                    attribute: 'index',
                  },
                  {
                    title: 'IP',
                    attribute: 'ip',
                  },
                  {
                    title: 'Created',
                    attribute: 'created_at',
                    Cell: ({ created_at }) => new Date(created_at).toLocaleString(),
                  },
                  {
                    title: '',
                    Cell: (vm) => (
                      <>
                        <ButtonGroup size="small">
                          <Button
                            title="Remover"
                            onClick={() => {
                              if (window.confirm(`Are you sure to delete ${vm.ip}`)) {
                                API.delete(`/linode/vms/${vm.ip}?token=${linodeVMs?.token}`);
                              }
                            }}
                          >
                            <Icons.Delete fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </>
                    ),
                  },
                ]}
              />
              <p />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  API.post(`/linode/vms?token=${linodeVMs?.token}`).finally(() => setLinodeVMs(null));
                }}
                color="primary"
              >
                Add One
              </Button>
              <Button
                type="button"
                color="default"
                onClick={() => {
                  if (window.confirm('Are you sure to delete all VMs')) {
                    for (let vm of linodeVMs.vms) {
                      if (vm.ip) API.delete(`/linode/vms/${vm.ip}?token=${linodeVMs?.token}`);
                    }
                  }
                }}
              >
                Delete All
              </Button>
              <Button onClick={() => setLinodeVMs(null)} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
        <Dialog open={!!currentLinode} onClose={() => setCurrentLinode(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              param.info = { name: param.name, token: param.token, proxy: param.proxy };
              delete param.name;
              delete param.token;
              delete param.proxy;
              API.put(`/vps-account/${param._id || 'linode-' + new Date().getTime()}`, param)
                .then(() => refresh())
                .catch((e) => {
                  window.alert(`Cannot add account: ${e.response?.data?.error}`);
                });
              setCurrentLinode(null);
            }}
          >
            <DialogTitle>{currentLinode?._id ? 'Update' : 'Add'} Linode</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentLinode?._id} style={{ display: 'none' }} />
              <Input type="text" name="type" value="linode" style={{ display: 'none' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="Name" type="text" name="name" defaultValue={currentLinode?.info?.name} autoFocus required />
                &nbsp;&nbsp;
                <Input label="SenderType" select name="senderType" defaultValue={currentLinode?.senderType || 'HOTMAIL'} required>
                  <MenuItem key="HOTMAIL" value="HOTMAIL">
                    Hotmail
                  </MenuItem>
                  <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                    OtherMail
                  </MenuItem>
                  <MenuItem key="BOTH" value="BOTH">
                    Both
                  </MenuItem>
                  <MenuItem key="DDOS" value="DDOS">
                    DDos
                  </MenuItem>
                </Input>
              </div>
              <p />
              <Input label="Token" type="text" name="token" defaultValue={currentLinode?.info?.token} required />
              <p />
              <Input label="Proxy" type="text" name="proxy" defaultValue={currentLinode?.info?.proxy} />
              <p />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentLinode?.hotmailSpeed || 150} required />
                &nbsp;&nbsp;
                <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentLinode?.othermailSpeed || 150} required />
              </div>
              <p />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input label="MAX VMs" type="number" name="maxvms" defaultValue={currentLinode?.maxvms || 20} required />
                &nbsp;&nbsp;
                <Input label="MAX Deploy in 1 hour" type="number" name="maxDeploy" defaultValue={currentLinode?.maxDeploy || 1} required />
              </div>
              <p />
              <Input name="autosend" type="checkbox" label="Run from 4:00 to 18:00" defaultValue={currentLinode?.autosend || false} />
              <p />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentLinode(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {currentLinode?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>
    </>
  );
}
