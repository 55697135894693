import { createStore } from 'redux';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import { DefaultRoutes } from './config/routes';
import theme from './config/theme';

import { AuthProvider } from './contexts/AuthContext';
import reducers from './reducers';
import './styles/index.css';

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CssBaseline />
          <DefaultRoutes />
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
