import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useField } from '@unform/core';

export default function Select({ name, options = [], ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current?.node,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      select
      variant="outlined"
      defaultValue={defaultValue}
      {...rest}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
