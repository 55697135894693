import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { MainRoutes } from '../../config/routes';
import CameraScreen from '../../components/Camera';
import Menus from '../../components/Menus';
import Screens from '../../components/ScreenFrame';
import SysInfo from '../../components/SysInfo';
import StorageHelper from '../../helpers/StorageHelper';
import useAuth from '../../hooks/useAuth';
import config from '../../config';
const drawerWidth = 240;

// Criando uma instância de axios com a baseURL do authSv
const API_AUTH = axios.create({
  baseURL: config.authSv,
  headers: {
    'Content-Type': 'application/json',
  },
});


const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    logo: {
      marginRight: theme.spacing(2),
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 32,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    main: {
      // display: 'flex',
      // flexFlow: 'column',
      // flexGrow: 1,
      flex: 1,
      marginTop: 56,
      minWidth: 324,
      overflow: 'auto',
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        marginTop: 48,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 64,
      },
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }),
  { index: 1 }
);

export default function Main() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [title, setTitle] = useState('Main');
  const [headerRight, setHeaderRight] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(StorageHelper.getDrawer());

  // load sysinfo, users, and online
  const [sysinfo, setSysinfo] = useState(null);
  const [onlines, setOnlines] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sysinfoResponse = await API_AUTH.get('api/sysinfo');
        console.log('sysinfoResponse:', sysinfoResponse); // Log para verificar a resposta de sysinfo
  
        //configurar depois onlines
        //const onlinesResponse = 0; await API.get('/domains/online');
        const userListResponse = await API_AUTH.get('/users');
        //console.log('userListResponse:', userListResponse); // Log para verificar a resposta de userList
  
        // Corrigindo a forma como os dados são acessados
        const sysinfoData = sysinfoResponse.data.data;
        //console.log('sysinfoData:', sysinfoData); // Log para verificar os dados de sysinfo
  
        setSysinfo(sysinfoData);
        setOnlines(0);
        setUserList(userListResponse.data);
  
        const processedSysinfo = sysinfoData;
        //console.log('processedSysinfo:', processedSysinfo); // Log para verificar os dados processados de sysinfo
  
        dispatch({
          type: 'GLOBAL_SET',
          value: {
            sysinfo: processedSysinfo,
            onlines: [{ username: 'default' }],
            userList: userListResponse.data,
          },
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);
  

  useEffect(() => {
    setOpenDrawer(StorageHelper.getDrawer());
  }, []);

  function handleDrawerOpen() {
    StorageHelper.setItem(StorageHelper.KEYS.DRAWER, true);
    setOpenDrawer(true);
  }

  function handleDrawerClose() {
    StorageHelper.setItem(StorageHelper.KEYS.DRAWER, false);
    setOpenDrawer(false);
  }

  return (
    <div className={classes.root}>
      <Box component={AppBar} displayPrint="none" position="absolute" className={clsx(classes.appBar, openDrawer && classes.appBarShift, 'no-print')}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, openDrawer && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
          {Boolean(headerRight) && headerRight}
        </Toolbar>
      </Box>
      <Drawer
        variant="permanent"
        open={openDrawer}
        classes={{
          root: 'no-print',
          paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
        }}
      >
        <div className={classes.toolbarIcon}>
          <Box px={1} fontWeight="bold">
            {`${user?.username || ''}`.toUpperCase()} - {sysinfo?.version}
          </Box>
          <IconButton onClick={handleDrawerClose} style={{ marginLeft: 'auto' }}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <SysInfo openDrawer={openDrawer} />
        <Menus tooltip={!openDrawer} />
      </Drawer>
      <Box className={classes.main} component="main" p={2}>
        <MainRoutes setHeaderRight={setHeaderRight} setTitle={setTitle} />
      </Box>
      <CameraScreen />
      <Screens />
    </div>
  );
}
