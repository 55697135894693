import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Details';

import Html from '../../components/Form/Html';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function MailTemplates({ setTitle }) {
  const { isAdmin, isAdminMailer, user } = useAuth();
  const users = useSelector((s) => s.global.userList);
  const me = users?.find((u) => u.username === user.username);

  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState(null);
  const [processedHTML, setProcessedHTML] = useState([]);

  const [demoTag, setDemoTag] = useState('');
  const [mailerSettigs, setMailerSettigs] = useState({});
  // const [black, setBlack] = useState('');

  useEffect(() => setTitle('Mail Templates / Tags / Namecheap / R53'), [setTitle]);
  const refresh = () => {
    API.get(`/tag`).then((r) => {
      setTags(r.data);
      setDemoTag(
        `Tag Test
{$braredir}
{$tkredir}
{$mpredir}
{$netredir}
{$bbredir}
{$viaredir}

{$clientName(Notamos que)}
{$email}\t{$emailuser}\t{$emaildomain}
${r.data.map((t) => `{$${t.key}}`).join('\n')}
{$time}\t{$date}\t{$randInt10}\t{$randHash10}\t{$randBetween100-1000}\t{$randPlace}\t{$randEmail}`
      );
    });
    API.get(`/settings/mailer`).then((r) => setMailerSettigs(r.data));
  };
  useEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Namecheap</h3>
        </div>
        <Form
          onSubmit={(namecheap) => {
            API.post(`/settings/mailer`, { namecheap });
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              label="API User"
              type="text"
              name="apiuser"
              value={mailerSettigs?.namecheap?.apiuser}
              autoFocus
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, namecheap: { apiuser: e.target.value, apikey: curr.namecheap.apikey } }))}
            />
            &nbsp;&nbsp;
            <Input
              label="API Key"
              type="text"
              name="apikey"
              value={mailerSettigs?.namecheap?.apikey}
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, namecheap: { apikey: e.target.value, apiuser: curr.namecheap.apiuser } }))}
            />
            &nbsp;&nbsp;
            <Button type="submit" disabled={!(isAdmin || isAdminMailer)}>
              <Icons.Save /> &nbsp;&nbsp;Save
            </Button>
          </div>
        </Form>
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>AWS R53</h3>
        </div>
        <Form
          onSubmit={(aws53) => {
            API.post(`/settings/mailer`, { aws53 });
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              label="Region"
              type="text"
              name="region"
              value={mailerSettigs?.aws53?.region}
              autoFocus
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, aws53: { ...curr.aws53, region: e.target.value } }))}
            />
            &nbsp;&nbsp;
            <Input
              label="ID"
              type="text"
              name="accessKeyId"
              value={mailerSettigs?.aws53?.accessKeyId}
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, aws53: { ...curr.aws53, accessKeyId: e.target.value } }))}
            />
            &nbsp;&nbsp;
            <Input
              label="Key"
              type="text"
              name="secretAccessKey"
              value={mailerSettigs?.aws53?.secretAccessKey}
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, aws53: { ...curr.aws53, secretAccessKey: e.target.value } }))}
            />
            &nbsp;&nbsp;
            <Button type="submit" disabled={!(isAdmin || isAdminMailer)}>
              <Icons.Save /> &nbsp;&nbsp;Save
            </Button>
          </div>
        </Form>
      </Box>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Tags</h3>
          <Button title="Add Tag" onClick={() => setCurrentTag({})}>
            <Icons.GroupAdd /> &nbsp;&nbsp;Add Tag
          </Button>
        </div>
        <Table
          size="small"
          data={[{ domain: true, key: 'userredir', value: me?.defaultRedir }, ...(tags || [])]}
          columns={[
            {
              title: 'Key',
              attribute: 'key',
              Cell: ({ key, domain }) => <Box style={{ color: domain ? 'cyan' : 'white' }}>{key}</Box>,
            },
            {
              title: 'Values',
              attribute: 'value',
              Cell: ({ value, domain }) => <Box style={{ color: domain ? 'cyan' : 'white' }}>{value}</Box>,
            },
            {
              title: 'Added At',
              attribute: 'createdAt',
              Cell: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleString(),
            },
            {
              title: 'Action',
              Cell: (row) =>
                row.createdAt && (
                  <>
                    <ButtonGroup size="small">
                      <Button title="Show detial" variant="outlined" onClick={() => setCurrentTag(row)} disabled={row.userId !== user.username}>
                        <DetailsIcon fontSize="small" />
                      </Button>

                      <Button
                        title="Remover"
                        onClick={() => {
                          if (window.confirm('Are you sure to delete?')) API.delete(`/tag/${row._id}`).then(() => refresh());
                        }}
                        disabled={row.userId !== user.username}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  </>
                ),
            },
          ]}
        />
      </Box>
      <br />
      <Box component={Paper} mb={2} padding={1}>
        <h3>Tag Test</h3>
        <Form
          onSubmit={(param) => {
            param.client = {
              trackingId: Math.floor(Math.random() * 10000000),
              accounts: [{ type: 'banking', first_name: `${user.username.toUpperCase()} First_Name`, last_name: 'Last_Name' }],
              email: `${user.username}@example.com`,
            };
            API.post(`/tag/custom`, param).then((r) => setProcessedHTML(r.data));
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#1115', padding: 10, fontSize: 10 }}>
            <Input multiline rows={7} value={demoTag} name="html" style={{ width: '54%' }} onChange={(e) => setDemoTag(e.target.value)} />
            <Button type="submit">&gt;&gt;</Button>
            <Input multiline rows={7} value={processedHTML} name="dest" />
          </div>
        </Form>

        <Dialog open={Boolean(currentTag)} onClose={() => setCurrentTag(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              API.post(`/tag`, param).then(() => refresh());
              setCurrentTag(null);
            }}
          >
            <DialogTitle>Add Tag</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentTag?._id} style={{ display: 'none' }} />
              <Input label="Key" type="text" name="key" defaultValue={currentTag?.key} autoFocus />
              <p />
              <Input label="Value" type="text" name="value" defaultValue={currentTag?.value} />
              <p />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentTag(null)} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary">
                {currentTag?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Blacks</h3>
        </div>
        <Form
          onSubmit={() => {
            API.post(`/settings/mailer`, { emailsNotToSend: mailerSettigs.emailsNotToSend });
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              label="BlackList for account name"
              type="text"
              name="black"
              value={mailerSettigs.emailsNotToSend}
              onChange={(e) => setMailerSettigs((curr) => ({ ...curr, emailsNotToSend: e.target.value }))}
              multiline
              rows={10}
              autoFocus
            />
            <Button type="submit" disabled={!(isAdmin || isAdminMailer)}>
              <Icons.Save /> &nbsp;&nbsp;Save
            </Button>
          </div>
        </Form>
      </Box>
    </>
  );
}
