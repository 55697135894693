const menus = [
  {
    name: 'Mercado',
    features: [
      // {
      //   adminDevice: true,
      //   name: 'MP Devices',
      //   url: '/devices',
      //   icon: 'Computer',
      //   routes: [{ path: '/devices', page: 'Devices' }],
      // },

      // {
      //   adminMP: true,
      //   name: 'Usado',
      //   // notusername: ['krk01'],
      //   url: '/usado',
      //   icon: 'Money',
      //   routes: [{ path: '/usado', page: 'MobileUsado' }],
      // },
      // {
      //   name: 'Pix-Codes',
      //   admin: true,
      //   // notusername: ['krk01'],
      //   url: '/pix',
      //   icon: 'Money',
      //   routes: [{ path: '/pix', page: 'PixCodes' }],
      // },
      {
        adminMP: true,
        // notusername: ['krk01'],
        name: 'MP',
        url: '/mp',
        icon: 'AccountCircle',
        routes: [{ path: '/mp/:username?', page: 'Mp' }],
      },
      // {
      //   admin: true,
      //   name: 'BB',
      //   // notusername: ['krk01'],
      //   url: '/bb',
      //   icon: 'AccountBox',
      //   routes: [{ path: '/bb', page: 'Bb' }],
      // },
      // {
      //   admin: true,
      //   name: 'Trojan',
      //   url: '/trojan',
      //   icon: 'AcUnit',
      //   routes: [{ path: '/trojan', page: 'Trojan' }],
      // },
      // {
      //   adminBB: true,
      //   name: 'Bradesco',
      //   // notusername: ['krk01'],
      //   url: '/brdesco',
      //   icon: 'AcUnit',
      //   routes: [{ path: '/brdesco', page: 'Bra' }],
      // },
      // {
      //   admin: true,
      //   name: 'MPWeb',
      //   url: '/mpweb',
      //   icon: 'AcUnit',
      //   routes: [{ path: '/mpweb', page: 'MPWeb' }],
      // },
      // {
      //   adminDevice: true,
      //   name: 'AutoRefresh',
      //   url: '/autorefresh',
      //   icon: 'Computer',
      //   routes: [{ path: '/autorefresh', page: 'AutoRefresh' }],
      // },
      {
        name: 'Netflix',
        admin: false,
        // notusername: ['krk01'],
        url: '/netflix',
        icon: 'AccountBalance',
        routes: [{ path: '/netflix', page: 'Netflix' }],
      },
      // {
      //   name: 'UniCred',
      //   admin: true,
      //   // notusername: ['krk01'],
      //   url: '/unicred',
      //   icon: 'AccountTree',
      //   routes: [{ path: '/unicred', page: 'Unicred' }],
      // },
      // {
      //   name: 'ViaCredi',
      //   admin: true,
      //   // notusername: ['krk01'],
      //   url: '/viacredi',
      //   icon: 'CardTravel',
      //   routes: [{ path: '/viacredi', page: 'Viacredi' }],
      // },
    ],
  },
  {
    name: 'Mailer',
    features: [
      {
        adminMailer: true,
        name: 'Manage Mails',
        url: '/mailer-emails',
        icon: 'Mail',
        routes: [{ path: '/mailer-emails', page: 'Emails' }],
      },
      {
        adminMailer: true,
        name: 'Send Mails',
        url: '/mailer-send',
        icon: 'Send',
        routes: [{ path: '/mailer-send', page: 'MailerSend' }],
      },
      // {
      //   adminMailer: true,
      //   username: ['admin'],
      //   name: 'Send SMS',
      //   url: '/sms-send',
      //   icon: 'Sms',
      //   routes: [{ path: '/sms-send', page: 'SMSSend' }],
      // },
      {
        adminMailer: true,
        name: 'Template',
        url: '/mailer/templates',
        icon: 'Attachment',
        routes: [{ path: '/mailer/templates/:templateId?', page: 'MailerTemplates' }],
      },
      {
        adminMailer: true,
        name: 'Etc',
        url: '/mailer/etc',
        icon: 'Attachment',
        routes: [{ path: '/mailer/etc', page: 'MailerEtc' }],
      },
      // {
      //   adminMailer: true,
      //   username: ['admin'],
      //   name: 'Namecheap',
      //   url: '/mailer-namecheap',
      //   icon: 'Cloud',
      //   routes: [{ path: '/mailer-namecheap', page: 'Namecheap' }],
      // },
      {
        adminMailer: true,
        name: 'DO / Linode',
        url: '/mailer-digitalocean',
        icon: 'LocalLibrary',
        routes: [{ path: '/mailer-digitalocean', page: 'Digitalocean' }],
      },
      // {
      //   adminMailer: true,
      //   name: 'Spamhaus',
      //   url: '/mailer-spamhaus',
      //   icon: 'LocalMall',
      //   routes: [{ path: '/mailer-spamhaus', page: 'MailerSpamhaus' }],
      // },
    ],
  },
  {
    name: 'Geral',
    features: [
      {
        adminDomain: true,
        name: 'Logins (Bra/MP)',
        url: '/logins',
        icon: 'ToggleOn',
        routes: [{ path: '/logins', page: 'OnlineLogins' }],
      },
      // {
      //   name: 'Online',
      //   url: '/online',
      //   icon: 'ToggleOn',
      //   routes: [{ path: '/online', page: 'Online' }],
      // },
      {
        name: 'Logs de IP',
        url: '/iplogs',
        icon: 'Dns',
        routes: [{ path: '/iplogs', page: 'IpLogs' }],
      },
      // {
      //   adminDomain: true,
      //   name: 'Domínios',
      //   url: '/domains',
      //   icon: 'Domain',
      //   routes: [{ path: '/domains', page: 'WebDomains' }],
      // },
      // {
      //   adminDomain: true,
      //   name: 'DDos',
      //   url: '/ddos',
      //   icon: 'Adb',
      //   routes: [{ path: '/ddos', page: 'DDos' }],
      // },
    ],
  },
  {
    name: 'Gerência',
    features: [
      {
        admin: true,
        username: ['admin'],
        name: 'Usuários',
        url: '/users',
        icon: 'SupervisorAccount',
        routes: [
          { path: '/users/:id', page: 'Users/form' },
          { path: '/users', page: 'Users' },
        ],
      },
    ],
  },
];

export default menus;
