import React, { useEffect, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';

import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle }) {
  useEffect(() => {
    setTitle('Manage Mails');
  }, []);

  const { user } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [mailGroups, setMailGroups] = useState([]);
  const [mails, setMails] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [templateResults, setTemplateResults] = useState(null);
  const limit = 100;
  const [offset, setOffset] = useState(0);
  const [mailType, setMailType] = useState(true);
  const [importGroup, setImportGroup] = useState(null);
  const [showGroup, setShowGroup] = useState(true);
  const [showMail, setShowMail] = useState(true);

  let fileInputRef = useRef();

  const refresh = () => {
    API.get(`/group`).then((res) => {
      setMailGroups(res.data);
    });
    //falta fazer rota tbm em mailer
    API.get(`/settings/templates-results`).then((res) => {
      setTemplateResults(res.data);
    });
    API.get(`/template`).then((r) => setTemplates(r.data));
  };
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (currentGroup) {
      setMails([]);
      API.get(`/mails/${currentGroup.groupName}?limit=${limit}&offset=${offset * limit}`).then((r) => {
        setMails(r.data);
      });
    }
  }, [currentGroup, offset, mailType]);

  return (
    <>
      <Box component={Paper} mb={2}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              <Icons.Add fontSize="small" />
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              name="sampleFile"
              onChange={(e) => {
                let formData = new FormData();
                const file = e.target.files[0];
                const fileSize = file.size;
                const groupName = window.prompt(
                  `Input group name`,
                  file.name.slice(0, -4).replace(/-/g, '_').replace(/\+/g, '_').replace(/\*/g, '').replace(/\ /g, '').replace(/\./g, '').replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '')
                );
                if (groupName) {
                  setImportGroup({ groupName, mailCount: 'Uploading' });
                  formData.append('file', file);
                  var request = new XMLHttpRequest();

                  request.upload.addEventListener('progress', function (e) {
                    if (e.loaded <= fileSize) setImportGroup((current) => ({ ...current, mailCount: `Uploading ${Math.round((e.loaded / fileSize) * 100)}%` }));
                    if (e.loaded == e.total) setImportGroup((current) => ({ ...current, mailCount: `Uploading 100%` }));
                  });

                  request.open('post', `https://file.io/?title=${groupName}`);
                  request.timeout = 30 * 60 * 1000;
                  request.send(formData);
                  request.onreadystatechange = () => {
                    if (request.readyState == XMLHttpRequest.DONE) {
                      setImportGroup((current) => ({ ...current, mailCount: `Extract and importing...` }));
                      const link = JSON.parse(request.response).link;
                      API.post(`/group`, { link, groupName, type: file.name.slice(-3) })
                        .then(() => {})
                        .finally(() => {
                          setImportGroup(null);
                          refresh();
                        });
                    }
                  };
                }
                e.target.value = '';
              }}
              accept=".rar,.zip"
              style={{ display: 'none' }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp; Mailer Groups ({mailGroups.length})
          </span>
          {user.username !== 'admin' && (
            <span style={{ fontWeight: '400', marginTop: 6, color: ['#f83', '#f38', '#f66'][Math.floor(Math.random() * 3)] }}>Não faremos nenhuma cópia nem backup dos seus emails.</span>
          )}
          <span>
            <Button onClick={() => setShowGroup((current) => !current)}>{!showGroup ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showGroup && (
          <Table
            size="small"
            data={[importGroup, ...(mailGroups || [])].filter(Boolean).map((g, index) => ({ index: index + 1, ...g }))}
            columns={[
              {
                title: 'No',
                attribute: 'index',
              },
              {
                title: 'Name',
                attribute: 'groupName',
              },
              {
                title: (
                  <>
                    Count
                    {/* <br />
                    <span style={{ color: '#afa' }}>Valid</span>/<span style={{ color: '#faa' }}>Invalid</span>/<span style={{ color: 'inherit' }}>NotChecked</span> */}
                  </>
                ),
                attribute: 'mailCount',
                Cell: ({ mailCount }) => (
                  <>
                    <div>{mailCount}</div>
                  </>
                ),
              },
              {
                title: 'Sent Templates',
                Cell: ({ groupName, mailCount }) =>
                  mailCount &&
                  templateResults && (
                    <>
                      {Object.keys(templateResults[groupName] || {}).map((templateId) => (
                        <div key={templateId}>
                          <span style={{ color: '#ff8c' }}>{templateId}:</span> {Number((100 * templateResults[groupName][templateId]) / mailCount).toFixed(2)}%&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{ color: 'gray' }}>{templates.find((t) => t._id === templateId)?.subject}</span>
                        </div>
                      ))}
                      <div>
                        --- <span style={{ color: '#ff8c' }}>{Number((100 * Object.values(templateResults[groupName] || 0).reduce((acc, a) => acc + a, 0)) / mailCount).toFixed(2)}% </span>
                        <span style={{ color: 'gray' }}>mails were sent successfully</span> ---
                      </div>
                    </>
                  ),
              },
              user.username !== 'admin' && {
                title: '',
                Cell: ({ groupName }) => (
                  <Icons.Delete
                    fontSize="small"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (window.confirm(`Are you sure to delete ${groupName} group?`)) {
                        API.delete(`/group/${groupName}`).then(() => refresh());
                      }
                    }}
                  />
                ),
              },
              {
                title: 'Reset',
                Cell: ({ groupName }) => (
                  <Button
                    onClick={() => {
                      if (window.confirm(`Are you sure to reset ${groupName}`)) {
                        API.post(`/mails/reset/${groupName}`).then(() => {
                          alert(`Done to reset sent infomation`);
                          refresh();
                        });
                      }
                    }}
                  >
                    Reset
                  </Button>
                ),
              },
            ]}
            onRowClick={(e, row) => {
              setCurrentGroup(row);
            }}
            // pagination={true}
            // rowsPerPage={10}
          />
        )}
      </Box>
      <Box component={Paper} mb={2}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>{currentGroup?.groupName}</span>
          <span>
            <Button onClick={() => setShowMail((current) => !current)}>{!showMail ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showMail && (
          <>
            <Pagination size="small" color="secondary" showFirstButton count={Math.floor(currentGroup?.mailCount / limit) + 1} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
            <Table
              size="small"
              data={mails?.mails || []}
              columns={[
                {
                  title: 'Index',
                  attribute: 'autoindex',
                },
                {
                  title: 'Email',
                  attribute: 'email',
                  Cell: ({ email, inbox, emails }) => (
                    <div
                      style={{
                        color: inbox === 1 ? '#cfcc' : inbox === 0 ? 'gray' : 'inherit',
                        textDecoration: inbox === 0 ? 'line-through' : 'inherit',
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxWidth: '50%',
                      }}
                    >
                      {emails ? emails.split(',').map((m) => <span style={{ padding: 2, margin: 2, backgroundColor: '#1115' }}>{m}</span>) : email}
                    </div>
                  ),
                },
                {
                  title: 'Username / CPF',
                  Cell: ({ accounts, name, cpf }) => (
                    <>
                      {name && `${name} / ${cpf}`}
                      {accounts?.map((account, index) => (
                        <div key={index}>
                          {account.type}: {account.first_name} {account.last_name === '.-' ? '' : account.last_name}
                        </div>
                      ))}
                    </>
                  ),
                },
                {
                  title: 'Sent',
                  // attribute: 'delivered',
                  Cell: ({ sent }) => <span style={{ color: 'green' }}>{sent}</span>,
                },
                {
                  title: 'Trans',
                  Cell: ({ transactions }) => (
                    <>
                      {transactions && (
                        <table>
                          {JSON.parse(transactions).map((trans, index) => (
                            <tr key={index} style={{ color: trans.natureza === 'D' ? '#F888' : '#8F88' }}>
                              <td>
                                <Tooltip title={new Date(trans.timestamp.slice(0, 10) + 'T' + trans.timestamp.slice(11, 19).replace(/\./g, ':') + '.000Z').toString()}>
                                  <div style={{ whiteSpace: 'nowrap' }}>{trans.complemento}</div>
                                </Tooltip>
                              </td>
                              <td>
                                {trans.natureza === 'D' ? '-' : '+'}
                                {trans.valor}
                              </td>
                            </tr>
                          ))}
                        </table>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </Box>
    </>
  );
}
