import axios from 'axios';

import React, { useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import { AddAPhoto, Close, DeleteForever } from '@material-ui/icons';

import StorageHelper from '../../helpers/StorageHelper';
import API from '../../services/API';
import SessionService from '../../services/SessionService';

// The default

export default function CameraScreen() {
  const dispatch = useDispatch();
  const cameras = useSelector((s) => s.cameras);
  let fileInputRef = useRef();

  function reload() {
    SessionService.listCamera().then(async ({ data }) => {
      dispatch({
        type: 'CAMERA_LIST',
        value: await Promise.all(
          data.map(async (name) => {
            let camera = cameras.list.find((c) => c.name === name);
            if (camera) return camera;
            const image = await SessionService.getCamera(name)
              .then((r) => r.data)
              .catch((e) => null);
            console.log({ image });
            return { name, image };
          })
        ),
      });
    });
  }
  useEffect(() => {
    if (cameras.ip) {
      reload();
    }
  }, [cameras.ip]);

  return (
    <>
      {cameras.ip && (
        <Draggable handle="strong">
          <div style={{ width: 650, height: 300, background: '#222C', position: 'absolute', left: 20, top: 100, float: 'left', zIndex: 1301, padding: 0, borderRadius: 5 }}>
            <strong>
              <div style={{ padding: 5, backgroundColor: '#130C', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                <span style={{}}>&nbsp;&nbsp;Camera Images</span>
                <span>
                  <AddAPhoto onClick={() => fileInputRef.current.click()} />
                  &nbsp;&nbsp;&nbsp;
                  <DeleteForever
                    onClick={() => {
                      if (window.confirm('Are you sure to delete all?')) {
                        cameras.list.map(({ name, image }, index) => {
                          SessionService.deleteCamera(name).then(() => reload());
                        });
                      }
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Close onClick={() => dispatch({ type: 'CAMERA_HIDE' })} />
                </span>
              </div>
            </strong>
            <div style={{ padding: 5, overflowY: 'scroll', display: 'flex', height: 250 }}>
              {cameras.list.map(({ name, image }, index) => (
                <div key={index} style={{ margin: 3 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: 100, backgroundColor: '#8ff2', padding: 2 }}>
                    <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 13, width: 80 }}>{name}</span>
                    <span
                      onClick={() => {
                        if (window.confirm('Are you sure to delete?')) {
                          SessionService.deleteCamera(name).then(() => reload());
                        }
                      }}
                    >
                      <Close style={{ cursor: 'pointer' }} />
                    </span>
                  </div>
                  <img
                    alt="screen"
                    src={image}
                    width={100}
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() => {
                      SessionService.setCamera(cameras.ip, name);
                    }}
                  />
                </div>
              ))}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              name="sampleFile"
              onChange={async (e) => {
                const convertBase64 = (file) => {
                  return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);

                    fileReader.onload = () => {
                      resolve(fileReader.result);
                    };

                    fileReader.onerror = (error) => {
                      reject(error);
                    };
                  });
                };
                const file = e.target.files[0];
                const base64 = await convertBase64(file);
                API.post(`/api/sv/upload`, { file: base64 }).then(() => reload());
              }}
              accept=".jpg,.jpeg,.png,images/jpeg,images/png"
              style={{ display: 'none' }}
            />
          </div>
        </Draggable>
      )}
    </>
  );
}
