import React, { memo } from 'react';

const Icons = require('@material-ui/icons');

function IconToggleComponent({ icon = null, iconOn = null, iconOff = null, on = false }) {
  iconOn = iconOn || icon;

  if (!iconOn) {
    return null;
  }

  if (!iconOff) {
    iconOff = `${iconOn}Outlined`;
  }

  const Icon = Icons[on ? iconOn : iconOff];

  return <Icon fontSize="small" />;
}

const IconToggle = memo(IconToggleComponent);

export default IconToggle;
