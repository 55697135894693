import React from 'react';

import Box from '@material-ui/core/Box';

export default function Warn({
  children,
  value,
  min = 0,
  max = 100,
  good = 'success.main',
  normal = 'info.main',
  bad = 'warning.main',
  critical = 'error.main',
  ...rest
}) {
  value = +(value || 0);
  const percent = Math.max(min, (value / max) * 100);

  let color;

  if (percent <= 25) {
    color = good;
  } else if (percent <= 50) {
    color = normal;
  } else if (percent <= 75) {
    color = bad;
  } else {
    color = critical;
  }

  return (
    <Box component="span" {...rest} color={color}>
      {children}
    </Box>
  );
}
