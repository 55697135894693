export default class StorageHelper {
  static KEYS = {
    TOKEN: '@app-mercado-token',
    USER: '@app-mercado-user',
    DRAWER: '@app-mercado-drawer',
    SESSION_ID: '@app-mercado-session-id', // Adicionado sessionId
  };

  static getMeta(key) {
    const item = window.localStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {}
    }

    return false;
  }

  static getItem(key, def = null) {
    const item = window.localStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item).value;
      } catch (e) {}
    }

    return def;
  }

  static setItem(key, value) {
    const item = {
      key: key,
      value: value,
      created: new Date().toISOString(),
    };

    return window.localStorage.setItem(key, JSON.stringify(item));
  }

  static removeItem(key) {
    return window.localStorage.removeItem(key);
  }

  static clear() {
    return window.localStorage.clear();
  }

  static getToken() {
    return this.getItem(this.KEYS.TOKEN);
  }

  static getUser() {
    return this.getItem(this.KEYS.USER);
  }

  static getDrawer() {
    return this.getItem(this.KEYS.DRAWER, true);
  }

  static getSessionId() {
    return this.getItem(this.KEYS.SESSION_ID); // Adicionado método para obter sessionId
  }
}
