import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { Dialog, Box, Button, ButtonGroup, DialogActions, DialogContent, DialogTitle, Paper, Switch } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon, Close as CloseIcon, RemoveRedEye as RemoveRedEyeIcon } from '@material-ui/icons';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import API from '../../services/API';

const DomainDialog = ({ currentDomain, onClose, onSubmit }) => (
  <Dialog open={Boolean(currentDomain)} onClose={onClose} fullWidth>
    <Form
      onSubmit={(param) => {
        const newDomain = {
          ...param,
          createdAt: currentDomain ? currentDomain.createdAt : new Date().getTime(),
        };
        onSubmit(newDomain);
      }}
    >
      <DialogTitle>{currentDomain ? 'Update domain' : 'Add domain'}</DialogTitle>
      <DialogContent>
        <Input autoFocus label="Service" name="service" defaultValue={currentDomain?.service} />
        <p />
        <Input label="Domains (comma separated)" name="domains" defaultValue={currentDomain?.domains.join(', ')} />
        <p />
        <Input label="Port" name="port" defaultValue={currentDomain?.port} type="number" />
        <p />
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary">
          {currentDomain ? 'Update' : 'Add'}
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Form>
  </Dialog>
);

const DomainTable = ({ domains, setDomains }) => {
  const handleDomainAction = async (action, domain) => {
    try {
      if (action === 'add') {
        const newDomain = window.prompt(`Add new domain`);
        if (newDomain) {
          const newDomainData = { service: newDomain, domains: [], port: 0 };
          const response = await API.post('/domains', newDomainData);
          //console.log('Domain added:', response.data);
          setDomains((prev) => [...prev, response.data]);
        }
      } else if (action === 'edit') {
        const updatedDomain = window.prompt(`Update domain name`, domain.service);
        if (updatedDomain) {
          const updatedDomainData = { ...domain, service: updatedDomain };
          const response = await API.put(`/domains/${domain._id}`, updatedDomainData);
          //console.log('Domain updated:', response.data);
          setDomains((prev) => prev.map((d) => (d._id === domain._id ? response.data : d)));
        }
      } else if (action === 'delete') {
        if (window.confirm('Are you sure to delete the domain?')) {
          await API.delete(`/domains/${domain._id}`);
          //console.log('Domain deleted:', domain);
          setDomains((prev) => prev.filter((d) => d._id !== domain._id));
        }
      }
    } catch (error) {
      console.error(`Error during ${action} domain:`, error);
    }
  };

  return (
    <Table
      data={domains}
      columns={[
        { title: 'Created', attribute: 'createdAt', Cell: ({ createdAt }) => new Date(createdAt).toLocaleString('pt-br') },
        { title: 'Service', attribute: 'service' },
        { title: 'Domains', attribute: 'domains', Cell: ({ domains }) => domains.join(', ') },
        { title: 'Port', attribute: 'port' },
        {
          title: 'Ações',
          align: 'right',
          Cell: (row) => (
            <ButtonGroup size="small">
              <Button onClick={() => handleDomainAction('edit', row)}><EditIcon fontSize="small" /></Button>
              <Button onClick={() => handleDomainAction('delete', row)}><DeleteIcon fontSize="small" /></Button>
            </ButtonGroup>
          ),
        },
      ]}
    />
  );
};

const WebDomains = ({ setTitle }) => {
  useEffect(() => setTitle('Domínios'), [setTitle]);

  const [domains, setDomains] = useState([]);
  const [currentWebDomain, setCurrentWebDomain] = useState(null);
  const [showWebDomains, setShowWebDomains] = useState(true);

  const reload = async () => {
    try {
      const response = await API.get('/domains');
      //console.log('Domains fetched:', response.data); // Log para verificar a resposta de domains
      setDomains(response.data);
    } catch (error) {
      console.error('Error fetching domains:', error);
    }
  };
  

  useEffect(() => {
    reload();
    const timer = setInterval(reload, 5000);
    return () => clearInterval(timer);
  }, []);

  const handleUpdate = async (newDomain) => {
    try {
      if (newDomain._id) {
        const response = await API.put(`/domains/${newDomain._id}`, newDomain);
        //console.log('Domain updated:', response.data);
        setDomains((prev) => prev.map((d) => (d._id === newDomain._id ? response.data : d)));
      } else {
        const response = await API.post('/domains', newDomain);
        //console.log('Domain added:', response.data);
        setDomains((prev) => [...prev, response.data]);
      }
      setCurrentWebDomain(null);
    } catch (error) {
      console.error('Error updating domain:', error);
    }
  };

  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <div style={{ backgroundColor: '#1113', padding: 5, display: 'flex', justifyContent: 'space-between' }}>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Web domains</span>
          <Button size="small" onClick={() => setShowWebDomains(!showWebDomains)}>
            {showWebDomains ? <CloseIcon /> : <RemoveRedEyeIcon />}
          </Button>
        </div>
        {showWebDomains && (
          <div>
            <div>
              <span>
                <Button onClick={() => setCurrentWebDomain({})}>+</Button>
                Domains ({domains.length})
              </span>
            </div>
            {domains.length > 0 ? (
              <DomainTable domains={domains} setDomains={setDomains} />
            ) : (
              <p>No domains found</p>
            )}
            <p />
          </div>
        )}
      </Box>

      <DomainDialog
        currentDomain={currentWebDomain}
        onClose={() => setCurrentWebDomain(null)}
        onSubmit={handleUpdate}
      />
    </>
  );
};

export default WebDomains;
