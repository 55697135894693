import React, { createContext, useState } from 'react';

import StorageHelper from '../helpers/StorageHelper';

const AuthContext = createContext({
  token: '',
  setToken: () => null,
  user: {},
  setUser: () => null,
  isAdmin: false,
  isAdminDevice: false,
  isAdminMP: false,
  isAdminMPAll: false,
  isAdminMailer: false,
  isAdminDomain: false,
});

function AuthProvider({ children }) {
  const [token, setToken] = useState(StorageHelper.getToken());
  const [user, setUser] = useState(StorageHelper.getUser());

  const isAdmin = Boolean(user?.admin);
  const isAdminDevice = Boolean(user?.adminDevice) || Boolean(user?.admin);
  const isAdminBB = Boolean(user?.adminBB) || Boolean(user?.admin);
  const isAdminMP = Boolean(user?.adminMP) || Boolean(user?.adminMPAll) || Boolean(user?.admin);
  const isAdminMPAll = Boolean(user?.adminMPAll) || Boolean(user?.admin);
  const isAdminMailer = Boolean(user?.adminMailer) || Boolean(user?.admin);
  const isAdminDomain = Boolean(user?.adminDomain) || Boolean(user?.admin);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        isAdmin,
        isAdminDevice,
        isAdminBB,
        isAdminMP,
        isAdminMPAll,
        isAdminMailer,
        isAdminDomain,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider };

export default AuthContext;
