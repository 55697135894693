import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Pagination from '@material-ui/lab/Pagination';

import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function PixCodes({ setTitle, setHeaderRight }) {
  const { user, isAdminMP } = useAuth();

  const [tab, setTab] = useState('normal');

  const [offset, setOffset] = useState(0);
  const [where, setWhere] = useState({ type: 'normal' });
  const [sort, setSort] = useState({ createdAt: -1 });
  const [row, setRow] = useState(null);

  const limit = 100;
  const { data: codes } = useFetch(`/api/sv/pix/codes?offset=${offset * limit}&limit=${limit}&where=${JSON.stringify(where)}&sort=${JSON.stringify(sort)}`, { refreshInterval: 5000 });

  useEffect(() => setTitle('Pix Codes'), [setTitle]);
  useEffect(() => {
    setHeaderRight(
      <>
        <FormControlLabel
          control={
            <Button onClick={() => setRow({})}>
              <Icons.Add />
              &nbsp;Add
            </Button>
          }
          // label="Add"
        />
        <FormControlLabel
          control={
            <Switch
              checked={!where.valid}
              onChange={() => {
                setWhere((curr) => (curr.valid ? {} : { valid: 1 }));
              }}
            />
          }
          label="SHOW INVALID"
        />
        <FormControlLabel
          control={
            <Button
              onClick={() => {
                if (window.confirm(`Are you sure to delete all invalid Pix Codes?`)) {
                  codes
                    .filter((c) => c.valid === 0)
                    .map((code) => {
                      API.delete(`/api/sv/pix/codes?id=${code._id}`);
                    });
                }
              }}
            >
              <Icons.Delete /> &nbsp;Delete All Invalid
            </Button>
          }
        />
        <FormControlLabel
          control={
            <Button
              onClick={() => {
                if (window.confirm(`Are you sure to check all of ${tab.toUpperCase()} Pix Codes?`)) {
                  API.post(`/api/sv/sessions/payment/api/validate-pix?type=${tab}`);
                }
              }}
            >
              <Icons.Check /> &nbsp;Verify All
            </Button>
          }
        />
      </>
    );
    return () => setHeaderRight(null);
  }, [setHeaderRight, where, codes, tab]);

  const columns = [
    {
      title: 'No',
      Cell: ({ index }) => index,
    },
    {
      title: <>Created {sort.createdAt === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort.createdAt === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'createdAt',
      Cell: ({ createdAt, valid }) => <span style={{ color: valid ? 'inherit' : 'gray' }}>{new Date(createdAt).toLocaleString()}</span>,
    },
    {
      title: 'Name',
      Cell: ({ name, valid }) => <span style={{ color: valid ? 'inherit' : 'gray' }}>{name}</span>,
    },
    {
      title: 'Code',
      Cell: ({ _id, valid }) => (
        <Copy value={_id}>
          <span style={{ color: valid ? 'inherit' : 'gray' }}>
            {_id.slice(0, 60)}...{_id.slice(-19)}
          </span>
        </Copy>
      ),
    },
    {
      title: <>Amount {sort.amount === 1 ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : sort.amount === -1 ? <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
      attribute: 'amount',
      Cell: ({ amount, valid }) => <span style={{ color: valid ? 'inherit' : 'gray' }}>{amount}</span>,
    },
    {
      title: 'Comments',
      Cell: ({ comments, valid }) => (
        <div style={{ color: valid ? 'inherit' : 'gray' }}>
          {(comments || []).map((comment, index) => (
            <div key={index} style={{ color: comment.success ? '#cfcd' : '#f888', display: 'flex' }}>
              <MuiLink href={`/mp/${comment.email}`} target={comment.email} style={{ color: '#bbb' }}>
                {comment.email}
              </MuiLink>
              : &nbsp;&nbsp;{comment.message} at {new Date(comment.at).toLocaleString()}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title="Disable"
              onClick={() => {
                if (window.confirm(`Are you sure to disable Code?`)) {
                  API.post(`/api/sv/pix/codes`, { code: row._id, valid: 0 });
                }
              }}
            >
              <Icons.Block fontSize="small" />
            </Button>
            <Button
              title="Verify"
              onClick={() => {
                API.post(`/api/sv/sessions/payment/api/validate-pix`, [row._id]);
              }}
            >
              <Icons.Check fontSize="small" />
            </Button>
            <Button
              title="Edit"
              onClick={() => {
                setRow(row);
              }}
            >
              <Icons.Edit fontSize="small" />
            </Button>
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete the Code?`)) {
                  API.delete(`/api/sv/pix/codes?id=${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Tabs
          value={tab}
          onChange={(e, value) => {
            setTab(value);
            setOffset(0);
            setWhere((curr) => ({ ...curr, type: value }));
          }}
          size="small"
        >
          <Tab label="Normal" value="normal" style={{ minWidth: 100, maxWidth: 100 }} />
          <Tab label="Donate Pix" value="donate_kids" style={{ minWidth: 130, maxWidth: 130 }} />
        </Tabs>
        <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table
          data={(codes || []).map((c, index) => ({ ...c, index: index + 1 + offset * limit }))}
          columns={columns}
          pagination={false}
          rowsPerPage={0}
          onHeadClick={useCallback(
            (e, attribute) => {
              if (isAdminMP && attribute) {
                setSort({ [attribute]: sort[attribute] > 0 ? -1 : 1 });
              }
            },
            [sort]
          )}
        />
      </Box>

      <Dialog open={Boolean(row)} onClose={() => setRow(null)} fullWidth>
        <Form
          onSubmit={(params) => {
            if (row) {
              API.post(`/api/sv/pix/codes`, { code: params.code.trim(), valid: 1, amount: +params.amount, comments: [], type: tab, name: params.name });
            } else {
              API.post(`/api/sv/pix/codes`, { code: params.code.trim(), amount: +params.amount, name: params.name });
            }
            setRow(null);
          }}
        >
          <DialogTitle>Pix Code</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Pix Code" name="code" rows={5} defaultValue={row?.code || ''} multiline disabled={row?._id} />
          </DialogContent>
          <DialogContent>
            <Input label="Type" name="type" value={tab} disabled />
          </DialogContent>
          <DialogContent>
            <Input label="Name" name="name" defaultValue={row?.name || ''} />
          </DialogContent>
          <DialogContent>
            <Input label="Amount" name="amount" type="number" defaultValue={row?.amount || 0} disabled={tab === 'donate_kids'} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRow(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
