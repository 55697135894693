import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import * as Colors from '@material-ui/core/colors';

const colors = Object.values(Colors);

delete colors[1]; // RED
delete colors[10]; // GREEN
delete colors[11]; // GREEN
delete colors[12]; // GREEN
delete colors[16]; // ORANGE

export default function Color({
  name,
  onChange,
  InputLabelProps = {},
  TextFieldProps = {},
  InputProps = {},
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleColor = (color) => {
    inputRef.current.value = color || '';
    inputRef.current.style.color = 'transparent';
    inputRef.current.style.backgroundColor = color || null;
    setOpen(false);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, color) {
        handleColor(color);
      },
    });
  }, [fieldName, registerField]);

  InputLabelProps.shrink = true;
  InputProps.readOnly = true;

  const handleOpen = (e) => {
    setOpen(true);
    setAnchorEl(inputRef.current);
  };

  return (
    <>
      <TextField
        inputRef={inputRef}
        fullWidth
        variant="outlined"
        defaultValue={defaultValue}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        {...rest}
        onClick={handleOpen}
        onFocus={handleOpen}
      />
      {open && (
        <Menu anchorEl={anchorEl} open={true} onClose={() => setOpen(false)}>
          <div style={{ width: 128 }}>
            <Grid container>
              {colors.map(({ 500: color }, key) => (
                <Grid
                  item
                  key={key}
                  xs={3}
                  style={{ backgroundColor: color, height: 32 }}
                  onClick={() => handleColor(color)}
                />
              ))}
            </Grid>
          </div>
        </Menu>
      )}
    </>
  );
}
