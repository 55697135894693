import { combineReducers } from 'redux';

import agents from './agents';
import cameras from './cameras';
import global from './global';
import screens from './screens';

export default combineReducers({
  screens,
  agents,
  cameras,
  global,
});
