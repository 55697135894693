export default function screens(state = [], action) {
  let ret = [...state];
  switch (action.type) {
    case 'SCREEN_ADD':
      if (ret.find((s) => s && s.ip === action.value.ip)) return ret;
      const emptyId = ret.findIndex((s) => !s);
      if (emptyId >= 0) {
        ret[emptyId] = action.value;
        return ret;
      }
      return [...ret, action.value];
    case 'SCREEN_REMOVE':
      for (let key in ret) {
        if (ret[key] && ret[key].ip === action.value) ret[key] = null;
      }
      return ret;
    default:
      return state;
  }
}
