import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import IconToggle from '../../components/IconToggle';
import Loading from '../../components/Loading';
import Money from '../../components/Money';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';
import SessionService from '../../services/SessionService';
import { ReactComponent as PixSvg } from './pix.svg';
import useStyles from './useStyles';

const Icons = require('@material-ui/icons');

const limitDefault = 20;

export default function MobileUsado({ setTitle, setHeaderRight }) {
  const global = useSelector((s) => s.global);

  const dispatch = useDispatch();

  const classes = useStyles();
  const { isAdminMP, user } = useAuth();
  const { username: sessionUsername } = useParams();

  useEffect(() => setTitle('Usado'), [setTitle]);

  const [modelComment, setModelComment] = useState(null);
  const [error, setError] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [sort, setSort] = useState('updatedAt DESC');
  const [limit, setLimit] = useState(limitDefault);
  const [offset, setOffset] = useState(0);
  const [minUsadoValue, setMinUsadoValue] = useState(1);
  const [filterOwner, setFilterOwner] = useState('');
  const [devices, setDevices] = useState(null);
  // const [tabValue, setTabValue] = useState('new');
  const [usadoValues, setUsadoValues] = useState({});

  // Anchors
  // Buscar usuários online no FAKE WEB
  const usersShown =
    global?.sysinfo?.usadosToday
      ?.filter((r) => r.usadoValue)
      .map((r) => (!r.owners.length ? ['unknown'] : r.owners))
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index) || []; // global?.onlines?.map((u) => u.username);

  useEffect(() => {
    for (let caption of usersShown) {
      const usados = global?.sysinfo?.usadosToday?.filter((s) => (caption === 'unknown' ? !s.owners.length : s.owners.includes(caption)));
      usadoValues[caption] = {
        oldUsado: usados.filter((u) => u.old)?.reduce((acc, a) => acc + a.usadoValue, 0) || 0,
        newUsado: usados.filter((u) => !u.old)?.reduce((acc, a) => acc + a.usadoValue, 0) || 0,
      };
    }
    setUsadoValues(usadoValues);
  }, [usersShown, global]);

  const myVMs = global?.userList?.find((u) => u.username === user?.username)?.vms;

  // const handleMore = useCallback(() => {
  //   setLimit(limitDefault);
  //   setOffset(accounts?.length);
  // }, [accounts]);

  const handleSort = useCallback(
    (e, attribute) => {
      if (isAdminMP && attribute && attribute !== '_id') {
        const dir = sort.includes('ASC') ? 'DESC' : 'ASC';
        // setOffset(0);
        setLimit(Math.max(limitDefault, accounts?.length));
        setSort(`${attribute} ${dir}`);
      }
    },
    [accounts, sort, isAdminMP]
  );

  const handleSave = useCallback(async ({ username, ...data }, message = 'O registro foi salvo!') => {
    try {
      await SessionService.update({ id: username, ...data });
      setAccounts((current) => {
        const session = current.find((s) => s.username === username);
        if (session) Object.assign(session, data);
        return current;
      });

      if (message) {
        alert(message);
      }
    } catch (err) {
      alert(err?.response?.data?.message || 'Houve uma falha na requisição.');
    }
  }, []);

  // getData
  useEffect(() => {
    const param = { sort, limit: limitDefault, offset: offset * limitDefault, filterOwner, minUsadoValue };
    SessionService.list(param)
      .then(({ data }) => {
        const sessions = data.map((s) => ({ ...s, comments: s.comments || [] }));
        setError(false);
        // setHasMore(sessions.length === limit);
        // setAccounts(sessions);
        // setAccounts(sessions.filter((d) => isAdminMP || d.updatedAt > new Date().getTime() - 1 * 24 * 60 * 60 * 1000));
        setAccounts(sessions.filter((s) => isAdminMP || s.createdAt > 1662768000000)); // show only in 1 month
      })
      .catch(() => setError(true));
  }, [sort, limit, offset, minUsadoValue, filterOwner, isAdminMP]);

  const today = new Date();
  if (today.getUTCHours() < 3) today.setUTCDate(today.getUTCDate() - 1);
  today.setUTCHours(0, 0, 0, 0);
  const today0 = today.getTime() + 3 * 60 * 60 * 1000;
  const todayUsado = (comments) => comments.filter((c) => c.type === 'Usado' && c.createdAt > today0).reduce((a, b) => a + +b.description.split(' ')[0], 0);
  const columns = useMemo(
    () => [
      {
        title: 'No',
        Cell: ({ index, usadoToday }) => <span style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>{index + 1}</span>,
      },
      {
        title: <>Criado {sort.startsWith('createdAt') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
        attribute: 'createdAt',
        align: 'center',
        Cell: ({ createdAt, usadoToday }) => <span style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>{new Date(createdAt).toLocaleString('pt-br')}</span>,
      },
      {
        title: <>Atualiz {sort.startsWith('updatedAt') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}</>,
        attribute: 'updatedAt',
        align: 'center',
        Cell: ({ updatedAt, usadoToday }) => <span style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>{new Date(updatedAt).toLocaleString('pt-br')}</span>,
      },
      {
        title: 'Owner',
        Cell: ({ owners, usadoToday }) => (
          <Box py={0.5}>
            <span style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>{(owners || []).join(', ')}</span>
          </Box>
        ),
      },
      {
        title: 'Level / Info',
        Cell: ({ _id, username, password, passwordLost, passwordChangedAt, passwordChangedAuto, jhonsonAt, jhonsonEmail, jhonsonUsername, phone, cpf, ip, usadoToday }) => (
          <>
            <Copy value={username} style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>
              <MuiLink href={`/mp/${username}`} target={_id} style={{ color: '#fff' }}>
                <span style={{ color: usadoToday > 0 ? 'inherit' : 'gray' }}>{username}</span>
              </MuiLink>
            </Copy>
          </>
        ),
      },
      {
        title: (
          <Box lineHeight="1.2">
            Balance {sort.startsWith('balance') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}
          </Box>
        ),
        attribute: 'balance',
        align: 'center',
        Cell: ({ balance, liberar, investimentos, criptomoedas, dinheiroExpress, merchantAdmin, cardToEnable, createdAt }) => (
          <>
            {!!balance && (
              <div>
                <Money value={balance} decimals={2} style={{ color: balance > 0 ? '#dfd' : balance < 0 ? '#f55' : 'inherit' }} />
              </div>
            )}
            {!!liberar && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="Liberar">
                <div>
                  <Money value={liberar} decimals={2} style={{ color: '#6AF' }} />
                </div>
              </Tooltip>
            )}
            {!!investimentos && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="Investimentos">
                <div>
                  <Money value={investimentos} decimals={2} style={{ color: '#FA6' }} />
                </div>
              </Tooltip>
            )}
            {!!criptomoedas && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="Criptomoedas">
                <div>
                  <Money value={+criptomoedas} decimals={2} style={{ color: '#AF6' }} />
                </div>
              </Tooltip>
            )}
            {!!dinheiroExpress && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="Dinheiro Express">
                <div>
                  <Money value={+dinheiroExpress} decimals={2} style={{ color: '#6FA' }} />
                </div>
              </Tooltip>
            )}
            {!!merchantAdmin && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="Merchant Admin">
                <div>
                  <Money value={+merchantAdmin} decimals={2} style={{ color: '#cf6' }} />
                </div>
              </Tooltip>
            )}
            {!!cardToEnable && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="CardToEnable">
                <div>
                  <Money value={+cardToEnable} decimals={2} style={{ color: '#A6F' }} />
                </div>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: (
          <Box lineHeight="1.2">
            Credit {sort.startsWith('MLCredito') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}
          </Box>
        ),
        attribute: 'MLCredito',
        align: 'center',
        Cell: ({ MLCredito, done }) => (
          <>
            {!!MLCredito && (
              <Tooltip title="MLCredito">
                <div>
                  <Money value={MLCredito || 0} decimals={2} style={{ color: done ? 'gray' : 'inherit' }} />
                </div>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: (
          <Box lineHeight="1.2">
            MPCard{' '}
            {sort.startsWith('MPCardLimit') || sort.startsWith('MPCardMaxLimit') ? (
              sort.endsWith('ASC') ? (
                <Icons.ArrowUpward style={{ marginBottom: -6 }} />
              ) : (
                <Icons.ArrowDownward style={{ marginBottom: -6 }} />
              )
            ) : (
              ''
            )}
          </Box>
        ),
        attribute: 'MPCardLimit',
        align: 'center',
        Cell: ({ MPCardLimit, MPCardMaxLimit, MPCardLimitPercent, createdAt, done }) => (
          <>
            {/* {(+MPCardLimitPercent || 0).toFixed(2)}% */}
            {!!MPCardLimit && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="MPCardLimit">
                <div>
                  <Money value={MPCardLimit} decimals={2} style={{ color: done ? 'gray' : '#6AF' }} />
                </div>
              </Tooltip>
            )}
            {!!MPCardMaxLimit && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="MPCardMaxLimit">
                <div>
                  <Money value={MPCardMaxLimit} decimals={2} style={{ color: done ? 'gray' : '#FA6' }} />
                </div>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: <Box lineHeight="1.2">Features</Box>,
        defaultValue: '-',
        align: 'center',
        Cell: ({ MPCardEnable, MLEmprestimo, pix, _id, creditcard, requireDoc, createdAt }) => (
          <>
            {!!creditcard && (isAdminMP || createdAt > 1662768000000) && (
              <Tooltip title="CreditCard">
                <>
                  <span style={{ color: 'red', fontStretch: '30%' }}>C</span>
                  &nbsp;
                </>
              </Tooltip>
            )}
            {!!MPCardEnable && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="Enable MP Card">
                  <Icons.Person color="primary" style={{ fontSize: 14, verticalAlign: 'top' }} />
                </Tooltip>
                &nbsp;
              </>
            )}
            {!!MLEmprestimo && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="Este usuario possui emprestimo">
                  <Icons.NewReleases color="secondary" style={{ fontSize: 14, verticalAlign: 'top' }} />
                </Tooltip>
                &nbsp;
              </>
            )}
            {/* {!!MLCreditoAumentar && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="Aumentar meu limite">
                  <Icons.AccessAlarm color="primary" style={{ fontSize: 14, verticalAlign: 'top' }} />
                </Tooltip>
                &nbsp;
              </>
            )} */}
            {/* {!!PhysicalCardEnable && (
              <>
                <Tooltip title="Order Physical Card">
                  <Icons.AddAlert style={{ fontSize: 15, verticalAlign: 'top', color: '#F88' }} />
                </Tooltip>
                &nbsp;
              </>
            )} */}
            {/* {!!cardTested && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="MP card">
                  <Icons.CardGiftcard color="secondary" style={{ fontSize: 14, verticalAlign: 'top' }} />
                </Tooltip>
                &nbsp;
              </>
            )} */}
            {!!pix && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="Pix">
                  <PixSvg style={{ width: 12 }} />
                </Tooltip>
                &nbsp;
              </>
            )}
            {!!requireDoc && (isAdminMP || createdAt > 1662768000000) && (
              <>
                <Tooltip title="Require Doc">
                  <Icons.Error color="secondary" style={{ fontSize: 14, verticalAlign: 'top', color: '#f55' }} />
                </Tooltip>
                &nbsp;
              </>
            )}
            {/* {attackingSessions.find((a) => a._id === _id) ? <Icons.DirectionsRunRounded style={{ fontSize: 14 }} color="secondary" /> : ''} */}
          </>
        ),
      },
      {
        title: (
          <Box lineHeight="1.2">
            Usado {sort.startsWith('usadoValue') ? sort.endsWith('ASC') ? <Icons.ArrowUpward style={{ marginBottom: -6 }} /> : <Icons.ArrowDownward style={{ marginBottom: -6 }} /> : ''}
            <br />
            -Today-
          </Box>
        ),
        attribute: 'usadoValue',
        align: 'center',
        Cell: ({ usadoValue, usadoToday }) => (
          <div>
            {!!usadoToday && <span style={{ color: usadoToday > 0 ? 'yellow' : 'gray' }}>{usadoToday}</span>}
            <br />
            <span style={{ color: 'gray' }}>{usadoValue}</span>
          </div>
        ),
      },
      {
        title: 'Coment.',
        attribute: 'comments',
        Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
      },
      ...(!myVMs
        ? []
        : [
            {
              key: 'actions',
              title: 'Ações',
              Cell: (row) => (
                <>
                  <ButtonGroup size="small">
                    <Button
                      onClick={() => {
                        SessionService.listDevices('mp')
                          .then(({ data }) => {
                            setDevices({
                              username: row.username,
                              list: data.filter((g) => g.idle && new RegExp(myVMs).test(g.email)).sort((a, b) => (a.email > b.email ? 1 : -1)),
                            });
                          })
                          .catch((e) => {
                            console.error(e);
                          });
                      }}
                      disabled={row.done}
                    >
                      <Icons.PlayArrow fontSize="small" />
                    </Button>
                    <Button
                      title="Refresh"
                      onClick={() => {
                        document.body.style.cursor = 'wait';
                        API.post(`/api/sv/sessions/refresh/${row.username}`)
                          .then(({ data }) => {
                            setAccounts((curr) => curr.map((rec) => (rec.username === row.username ? { ...rec, ...data } : rec)));
                          })
                          .finally(() => {
                            document.body.style.cursor = 'default';
                          });
                      }}
                    >
                      <Icons.Refresh fontSize="small" />
                    </Button>
                    <Tooltip title={row.done ? 'Marcar como não concluído' : 'Marcar como concluído'}>
                      <Button
                        variant={row.done ? 'contained' : 'outlined'}
                        onClick={() => {
                          if (window.confirm('Are you sure to finish to handle the session?')) handleSave({ username: row._id, done: row.done ? 0 : 1 }, false);
                        }}
                        disabled
                      >
                        <IconToggle iconOn="CheckBox" iconOff="CheckBoxOutlineBlankOutlined" on={row.done} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </>
              ),
            },
          ]),
    ],
    [handleSave, myVMs, sort, user.username]
  );

  if (!accounts) {
    return error ? <Box color="error.main">Erro ao buscar os dados.</Box> : <Loading />;
  }
  return (
    <div className={classes.root}>
      <div>
        <div>
          {isAdminMP && (
            <Box component={Paper} mb={2} p={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {usersShown.length > 0 && (
                <Grid item>
                  <Box p={1}>
                    <Grid container>
                      <Grid item key={0}>
                        <Box className="cursor-pointer" bgcolor={'' === filterOwner ? '#4CF8' : '#4CF2'} my={0.5} ml={1} py={0.2} px={1} style={{ width: 105 }}>
                          <Box component="span" color="text.secondary" style={{ fontSize: 11 }} onClick={() => setFilterOwner('')}>
                            <div>ALL</div>
                            <div>{Number(global?.sysinfo?.usadosToday.reduce((acc, a) => acc + a.usadoValue, 0) || 0).toFixed(2)}</div>
                          </Box>
                        </Box>
                      </Grid>
                      {usersShown.map((caption, key) => (
                        <Grid item key={key}>
                          <Box className="cursor-pointer" bgcolor={caption === filterOwner ? '#4CF8' : '#4CF2'} my={0.5} ml={1} py={0.2} px={1} style={{ width: 105 }}>
                            <Box component="span" color="text.secondary" style={{ fontSize: 11 }} onClick={() => setFilterOwner(caption)}>
                              <div>{caption}</div>
                              <div>
                                {isAdminMP || user.username === caption ? (
                                  <>
                                    <span style={{ color: 'yellow' }}>{Number(usadoValues[caption]?.newUsado || 0).toFixed(2)}</span> /{' '}
                                    <span style={{ color: '#ccc' }}>{Number(usadoValues[caption]?.oldUsado || 0).toFixed(2)}</span>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              )}
              <Grid item xs="auto">
                <TextField
                  type="number"
                  label="Minimum Usado"
                  name="minUsadoValue"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') setMinUsadoValue(+e.target.value);
                  }}
                  size="small"
                  style={{ marginLeft: 10, fontSize: 9, width: 150 }}
                />
              </Grid>
            </Box>
          )}

          {/* {isAdminMP && (
            <Grid item>
              <Tabs
                value={tabValue}
                onChange={(e, val) => {
                  setTabValue(val);
                }}
                size="small"
              >
                <Tab label="Novas" value="new" style={{ minWidth: 100, maxWidth: 100 }} />
                <Tab label="Old" value="old" style={{ minWidth: 130, maxWidth: 130 }} />
              </Tabs>{' '}
            </Grid>
          )} */}

          <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#FFF1' }}>
            <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
            <span style={{ color: 'yellow', marginTop: 5, fontSize: 16 }}>--- Page {offset + 1} ---</span>
            <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
          </div>
          <Table
            size="small"
            data={accounts.map((a, index) => ({
              ...a,
              index: index + offset * limitDefault,
              comments: a.comments.filter((comment) => isAdminMP || a.createdAt > 1662768000000 || comment.description === 'kiked' || comment.description === 'rec cc'),
              usadoToday: todayUsado(a.comments),
            }))}
            onHeadClick={handleSort}
            columns={columns}
          />
          {sessionUsername && accounts?.length > 0 && (
            <Box my={2}>
              <Grid container spacing={2}>
                {columns.map((col, key) => {
                  const { attribute, defaultValue = '' } = col;
                  const account = accounts[0];

                  const isEmpty = attribute && (typeof account[attribute] === 'undefined' || account[attribute] === null);

                  const content = col.CellIndividual ? col.CellIndividual(account) : col.Cell ? col.Cell(account) : account[col.attribute];

                  return (
                    <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                      <Box component={Paper} height="100%" p={1}>
                        <Box component="h3" mt={0}>
                          {col.title}
                        </Box>
                        <Box>{isEmpty ? defaultValue || '' : content}</Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
          {/* {!sessionUsername && hasMore && (
            <Box component={Paper} my={2}>
              <Button fullWidth disabled={loadingPage} onClick={handleMore}>
                {loadingPage ? 'Carregando...' : 'Carregar anteriores'}
              </Button>
            </Box>
          )} */}
        </div>
      </div>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
        // onSubmit={(param) => {
        //   console.log(modelComment._id, param);
        //   SessionService.addComment(modelComment._id, param.comment);
        //   modelComment.comments.push({ description: param.comment.comment, createdAt: new Date().getTime() });
        //   setModelComment(null);
        // }}
        >
          <DialogTitle>Comentários</DialogTitle>
          {/* <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" defaultValue={modelComment?.comment || ''} />
          </DialogContent> */}
          <DialogContent>
            {modelComment?.comments.map((comment, key) => (
              <div key={key} style={{ display: 'flex', justifyContent: 'space-between', minHeight: 36 }}>
                <CommentItem comment={comment} />
                {comment.type === 'Usado' && (
                  <span>
                    <ButtonGroup size="small">
                      <Button>
                        <Icons.Edit
                          onClick={() => {
                            const newUsadoValue = +window.prompt('Insert Usado', +comment.description);
                            if (newUsadoValue) {
                              const updateComment = modelComment?.comments.find((c) => c.type === 'Usado' && c.description === comment.description && c.createdAt === comment.createdAt);
                              if (updateComment) updateComment.description = newUsadoValue;
                              SessionService.update({
                                id: modelComment.username,
                                comments: modelComment?.comments, //
                                usadoValue: modelComment?.comments.map((c) => (c.type === 'Usado' ? +c.description : 0)).reduce((acc, a) => acc + a, 0),
                              });
                              setModelComment(modelComment);
                            }
                          }}
                        />
                      </Button>
                      <Button>
                        <Icons.Delete
                          onClick={() => {
                            if (modelComment?.comments && window.confirm('Are you sure to delete?')) {
                              modelComment.comments = modelComment.comments.filter((c) => (c.type === 'Usado' && c.description !== comment.description) || c.createdAt !== comment.createdAt);
                              SessionService.update({
                                id: modelComment.username,
                                comments: modelComment.comments,
                                usadoValue: modelComment.comments.map((c) => (c.type === 'Usado' ? +c.description : 0)).reduce((acc, a) => acc + a, 0),
                              });
                              setModelComment(modelComment);
                            }
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                  </span>
                )}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!devices} onClose={() => setDevices(null)}>
        <Form
          onSubmit={(param) => {
            SessionService.load(param)
              .then(({ data }) => {
                dispatch({ type: 'SCREEN_ADD', value: data });
              })
              .catch((e) => console.error(e));
            setDevices(null);
          }}
        >
          <DialogTitle>Select VM</DialogTitle>
          <DialogContent>
            <Input disabled label="UserName" name="username" value={devices?.username} />
            <p />
            <Select autoFocus required label="VM" name="ip" options={devices?.list.filter((g) => g.idle).map((g) => ({ label: g.email, value: g.ip }))} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDevices(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
