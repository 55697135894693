export default class SoundHelper {
  static play(path) {
    // Create an AudioContext object
    var audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Load sound file asynchronously
    function loadSound(url, callback) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';

      request.onload = function () {
        audioContext.decodeAudioData(
          request.response,
          function (buffer) {
            callback(buffer);
          },
          function (error) {
            console.error('decodeAudioData error', error);
          }
        );
      };
      request.send();
    }

    // Play the loaded sound buffer
    function playSound(buffer) {
      var source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start(0);
    }

    // Usage example
    loadSound(path, function (buffer) {
      playSound(buffer);
    });
  }
}
