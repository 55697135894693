import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import Box from '@material-ui/core/Box';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function Checkbox({ name = '', defaultChecked, ...rest }) {
  const inputRef = useRef(null);
  const [checked, setChecked] = useState(false);

  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: 'checked',
      getValue: () => checked,
      setValue: (ref, value) => setChecked(Boolean(value)),
    });
  }, [fieldName, registerField, checked]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <>
      <FormControlLabel
        // Props
        control={
          <MuiCheckbox
            inputRef={inputRef}
            name={name}
            color="primary"
            defaultChecked={defaultChecked}
            checked={checked}
            onClick={clearError}
            onChange={handleChange}
          />
        }
        {...rest}
      />
      {Boolean(error) && (
        <Box fontSize={12} color="error.main">
          {error}
        </Box>
      )}
    </>
  );
}
