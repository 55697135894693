import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';

import Copy from '../../components/Copy';
import Table from '../../components/Table';
import useFetch from '../../hooks/useFetch';

Array.prototype.onlyUnique = function () {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  return this.filter(onlyUnique);
};

export default function LoginAttempts({ setTitle }) {
  useEffect(() => setTitle('Login Attempts'), [setTitle]);
  const [tabValue, setTabValue] = useState('bra');
  const [offset, setOffset] = useState(0);
  const [row, setRow] = useState(null);
  const [filter, setFilter] = useState('');

  const limit = 30;
  const { data: logins } = useFetch(`/api/rd/logins/${tabValue || 'bra'}?offset=${offset * limit}&limit=${limit}&id=${filter}`, { refreshInterval: 5000 });
  const emails = (logins || []).map((l) => l.email).onlyUnique();
  const columns = [
    {
      title: 'ID',
      Cell: (email) => email,
    },
    {
      title: 'Message',
      Cell: (email) => (
        <table>
          {logins
            ?.filter((l) => l.email === email)
            .map((rec, index) => (
              <tr key={index}>
                <td style={{ color: rec.msg === 'open page - done' ? 'yellow' : rec.msg.includes('face') || rec.msg.includes('kyc') ? '#f668' : rec.succ ? '#cffe' : '#F668', minWidth: 300 }}>
                  {rec.msg.length > 100 ? (
                    <Copy value={rec.msg}>
                      <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 300 }}>{rec.msg}</div>
                    </Copy>
                  ) : (
                    rec.msg
                  )}
                </td>
                <td>&nbsp;&nbsp;&nbsp;{new Date(rec.at).toLocaleString()}</td>
              </tr>
            ))}
        </table>
      ),
    },
  ];
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(e, val) => {
          setTabValue(val);
        }}
        size="small"
      >
        <Tab label="Bra" value="bra" style={{ minWidth: 100, maxWidth: 100 }} />
        <Tab label="MP" value="mp" style={{ minWidth: 100, maxWidth: 130 }} />
      </Tabs>
      <Box component={Paper} p={2} mb={2}>
        <Grid container spacing={4} justify="flex-start">
          {/* <Grid item>
            <Box color="success.main">My logs: {iplogs.filter((l) => myRedir?.includes(l.redir)).length}</Box>
          </Grid> */}
          <Grid item>
            <Box color="success.main">
              <TextField
                type="text"
                label="Filter: ID"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setFilter(e.target.value);
                }}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </Box>
          </Grid>
        </Grid>
        <Pagination size="large" color="secondary" showFirstButton count={NaN} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table data={emails || []} columns={columns} onRowClick={(e, row) => setRow(row.username)} pagination={false} rowsPerPage={0} />
      </Box>
      {/* <Box component={Paper} p={2} mb={2}>
        <div style={{ backgroundColor: '#111', padding: 5 }}>
          {row &&
            ((logins || []).find((l) => l.username === row)?.logs || []).map((log, index) => (
              <div key={index} style={{ color: log.success ? 'green' : '#F888', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {new Date(log.at).toLocaleString()} [{log.action}] ({log.code || ''}) ==&gt; [{log.page}] ({log.message})
              </div>
            ))}
        </div>
      </Box> */}
    </>
  );
}
