import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { Dialog, Tooltip } from '@material-ui/core';

import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function UserScreen({ user, index }) {
  const [hide, setHide] = useState({});

  return (
    <div key={index} style={{ padding: 2, width: 300, margin: 2 }} id={`screen-${index}`}>
      <div style={{ backgroundColor: user?.info?.mobile ? '#3006' : '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <span style={{ color: 'lightgreen' }}>
            {user?.info?.mobile && 'M '}
            {user.ip} ({(user?.info?.step || 'idle').toUpperCase()})
          </span>
          &nbsp;&nbsp;&nbsp;
          <span style={{ color: 'lightblue' }}>{user.info?.AGN && `${user.info.AGN}/${user.info.CTA}-${user.info.DIGCTA}`}</span>
        </div>
        <div>
          <Tooltip title="Reload">
            <span style={{ cursor: 'pointer' }} onClick={() => API.post(`/api/rd/bra/client/${user.id}`, { action: 'reload' })}>
              <Icons.Repeat fontSize="small" style={{ position: 'relative', top: 5 }} />
            </span>
          </Tooltip>
        </div>
        {/* <div>
          <Icons.SwapHorizOutlined
            fontSize="small"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              const ele = document.getElementById(`screen-${index}`);
              if (ele) {
                if (ele.style.width === '300px') {
                  ele.style.width = '100%';
                } else {
                  ele.style.width = '300px';
                }
              }
            }}
          />
        </div> */}
        {/* {!hide[vm.id] ? (
            <Icons.Close fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setHide({ ...hide, [vm.id]: true })} />
          ) : (
            <Icons.RemoveRedEye fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setHide({ ...hide, [vm.id]: false })} />
          )} */}
      </div>
      {!hide[user.ip] && (
        <div style={{ width: 300, height: 200, position: 'relative' }}>
          <div style={{ display: 'flex', height: 400, width: 590, pointerEvents: 'none', transformOrigin: '0 0', transform: 'scale(0.5)' }}>
            <iframe
              src={
                'data:text/html,' +
                encodeURIComponent(
                  user.info?.pageHtml?.replace('text/html;CHARSET=iso-8859-1', 'text/html;CHARSET=utf8').replace(/<script(\s+(\w+\s*=\s*("|').*?\3)\s*)*\s*(\/>|>.*?<\/script\s*>)/, '')
                )
              }
              // src={'data:text/html,' + encodeURIComponent(html.replace(/<script(\s+(\w+\s*=\s*("|').*?\3)\s*)*\s*(\/>|>.*?<\/script\s*>)/, ''))}
              style={{ width: '100%' }}
              sandbox=""
            />
          </div>
          <div style={{ position: 'absolute', bottom: 0, left: 2, overflow: 'hidden', width: '97%', maxHeight: '50%', backgroundColor: '#2224' }}>
            {(user.logs || []).map((log, index) => (
              <div index={index}>
                {new Date(log.at).toLocaleTimeString()} [{log.title}] {log.detail}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
