import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    root: {
      '& .MuiButtonGroup-root .MuiButtonBase-root': {
        maxWidth: '32px',
        maxHeight: '28px',
        minWidth: '32px',
        minHeight: '28px',
      },
    },
  },
  { index: 1 }
);

export default useStyles;
