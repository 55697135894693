import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import useAuth from '../../hooks/useAuth';
import Warn from '../Warn';

export default function SysInfo({ openDrawer }) {
  const global = useSelector((s) => s.global);

  const [cpu, setCpu] = useState(null);
  const [mem, setMem] = useState(null);
  const [lag, setLag] = useState(null);

  useEffect(() => {
    const { cpuUsagePercent, memUsagePercent, latency } = global.sysinfo || {};

    cpuUsagePercent && setCpu(cpuUsagePercent);
    memUsagePercent && setMem(memUsagePercent);
    latency && setLag(latency);
  }, [global]);

  if (!global) {
    return null;
  }

  return (
    <>
      <Box px={2} py={1}>
        <Grid container justify="space-between">
          <Grid item>
            <Tooltip title="Uso de CPU do servidor">
              <Box fontSize={12}>
                {openDrawer && 'CPU: '}
                <Warn value={cpu}>{Math.ceil(cpu || 0)}%</Warn>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Uso de memória do servidor">
              <Box fontSize={12}>
                {openDrawer && 'MEM: '}
                <Warn value={mem}>{Math.ceil(mem || 0)}%</Warn>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tempo de resposta do servidor">
              <Box fontSize={12}>
                {openDrawer && 'LAT: '}
                <Warn value={lag} max={500}>
                  {Math.ceil(lag || 0)}ms
                </Warn>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  );
}
