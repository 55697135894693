import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';

import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import SendMail from '../../components/SendMail';
import Table from '../../components/Table';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle }) {
  useEffect(() => setTitle('Send SMS'), [setTitle]);
  // const [tasks, setTasks] = useState([]);
  const [upsertTask, setUpsertTask] = useState(null);
  const [showTasks, setShowTasks] = useState(true);
  const [currentAccount, setCurrentAccount] = useState(null);

  const [showLogs, setShowLogs] = useState(true);

  const { data: smsStatus } = useFetch('/api/sender/sms/status', { refreshInterval: 5000 }) || {};
  const { data: taskAlls } = useFetch(`/api/sender/task-send`, { refreshInterval: 5000 }) || [];

  const tasks = (taskAlls?.filter((t) => t.mode === 'sms') || []).map((t) => ({ ...t, options: JSON.parse(t.options) }));

  return (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" onClick={() => setCurrentAccount({})}>
              <Icons.Add fontSize="small" />
            </Button>
            CheapGlobalSMS ({smsStatus?.accounts?.length || 0})
          </span>
        </div>
        <Table
          size="small"
          // onRowClick={(e, row) => setCurrentDigitalOcean(row)}
          data={smsStatus?.accounts?.map((a, index) => ({ ...a, index: index + 1 })) || []}
          columns={[
            {
              title: 'ID',
              attribute: 'index',
            },
            {
              title: 'Name',
              Cell: ({ info }) => info.sub_account,
            },
            {
              title: 'Passwrod',
              Cell: ({ info }) => info.sub_account_pass,
            },
            {
              title: 'Balance (Credits)',
              Cell: ({ balance }) => (balance ? balance : <span style={{ color: '#F88' }}>0</span>),
            },
            {
              title: 'Left SMS (Credits / 3)',
              Cell: ({ balance }) => <span style={{ color: +balance > 0 ? 'green' : '#F88' }}>{Math.floor(Math.max(0, +balance) / 3)}</span>,
            },
            {
              title: 'Created',
              attribute: 'createdAt',
              Cell: ({ createdAt }) => new Date(createdAt).toLocaleString(),
            },
            {
              title: 'Action',
              Cell: (row) => (
                <>
                  <ButtonGroup size="small">
                    <Button title="Edit" onClick={() => setCurrentAccount(row)}>
                      <Icons.Edit fontSize="small" />
                    </Button>
                    <Button
                      title="Remover"
                      onClick={() => {
                        if (window.confirm(`Are you sure to delete the account`)) {
                          API.delete(`/api/sender/sms/account/${row._id}`);
                        }
                      }}
                    >
                      <Icons.Delete fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </>
              ),
            },
          ]}
        />
        <br />

        <Dialog open={!!currentAccount} onClose={() => setCurrentAccount(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              API.post(`/api/sender/sms/account`, param);
              setCurrentAccount(null);
            }}
          >
            <DialogTitle>{currentAccount?._id ? 'Update' : 'Add'} SMS account</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentAccount?._id} style={{ display: 'none' }} />
              <p />
              <Input label="Name" type="text" name="sub_account" defaultValue={currentAccount?.info?.sub_account} autoFocus required />
              <p />
              <Input label="Password" type="text" name="sub_account_pass" defaultValue={currentAccount?.info?.sub_account_pass} required />
              <p />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentAccount(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {currentAccount?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" variant="outlined" onClick={() => setUpsertTask({})}>
              <Icons.Add fontSize="small" />
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp; Tasks ({tasks?.length})
          </span>
          <span>
            <Button onClick={() => setShowTasks((current) => !current)}>{!showTasks ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showTasks && (
          <Table
            size="small"
            // onRowClick={(e, row) => setCurrentTask(row)}
            data={tasks || []}
            columns={[
              {
                title: 'Templates',
                attribute: 'templates',
                Cell: ({ templates }) => (
                  <div>
                    {templates.map((t, key) => (
                      <div key={key}>{t}</div>
                    ))}
                  </div>
                ),
              },
              // {
              //   title: 'Type',
              //   attribute: 'type',
              // },
              {
                title: 'Resend',
                Cell: ({ options }) => (options.resend ? <div style={{ color: 'lightgreen' }}>TRUE</div> : <div style={{ color: '#F338' }}>FALSE</div>),
              },
              {
                title: 'Multiple',
                Cell: ({ options }) => (options.multiple ? <span style={{ color: 'lightgreen' }}>TRUE</span> : <span style={{ color: '#F338' }}>FALSE</span>),
              },
              {
                title: 'Random',
                Cell: ({ options }) => (options.random ? <span style={{ color: 'lightgreen' }}>TRUE</span> : <span style={{ color: '#F338' }}>FALSE</span>),
              },
              {
                title: 'AutoSend', // Run from 4:00 to 18:00
                Cell: ({ options }) => (options.autosend ? <span style={{ color: 'lightgreen' }}>TRUE</span> : <span style={{ color: '#F338' }}>FALSE</span>),
              },
              {
                title: 'Seek',
                Cell: ({ seek }) => <>{seek}</>,
              },
              {
                title: 'Sent',
                Cell: ({ sent, successSent, failedSent }) => (
                  <>
                    {sent} = <span style={{ color: 'green' }}>{successSent || 0}</span> + <span style={{ color: '#f888' }}>{failedSent}</span>
                  </>
                ),
              },
              {
                title: 'Created',
                attribute: 'createdAt',
                Cell: ({ createdAt }) => new Date(createdAt).toLocaleString(),
              },
              {
                title: 'Action',
                Cell: (row) => (
                  <>
                    <ButtonGroup size="small">
                      <Button
                        title="Test to send SMS with the first template"
                        onClick={() => {
                          const number = window.prompt(`Insert number`, window.localStorage.getItem('TEST_PHONE_NUMBER'));
                          if (number) {
                            window.localStorage.setItem('TEST_PHONE_NUMBER', number);
                            let message = row.templates[0];
                            if (message) {
                              message = message.replace(/\{\$user_name\}/, `First Name Last Name`);
                              message = message.replace(/\{\$user_id\}/, `FirstName`);
                              API.post(`/api/sender/sms/test`, { message, number });
                            }
                          }
                        }}
                      >
                        <Icons.Sms fontSize="small" />
                      </Button>
                      <Button
                        title="Toggle"
                        onClick={() => {
                          API.put(`/api/sender/task-send/${row._id}/${row.status === 'Running' ? 'Paused' : 'Running'}`);
                        }}
                        disabled={row.status === 'Done'}
                      >
                        {row.status === 'Running' ? (
                          <Icons.Pause fontSize="small" style={{ color: 'lightgreen' }} />
                        ) : row.status === 'Paused' ? (
                          <Icons.PlayArrow fontSize="small" style={{ color: '#F228' }} />
                        ) : (
                          <Icons.StopOutlined fontSize="small" style={{ color: 'gray' }} />
                        )}
                      </Button>
                      <Button
                        title="Update"
                        onClick={() => {
                          setUpsertTask(row);
                        }}
                      >
                        <Icons.Update fontSize="small" />
                      </Button>
                      <Button
                        title="Remover"
                        onClick={() => {
                          if (window.confirm(`Are you sure to delete Task`)) API.delete(`/api/sender/task-send/${row._id}`);
                        }}
                      >
                        <Icons.Delete fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  </>
                ),
              },
            ]}
          />
        )}
        <Dialog
          open={!!upsertTask}
          onClose={() => {
            setUpsertTask(null);
          }}
          fullWidth
        >
          <Form
            onSubmit={(param) => {
              param.templates = [param.template1, param.template2, param.template3].filter(Boolean);
              delete param.template1;
              delete param.template2;
              delete param.template3;
              API.post(`/api/sender/task-send`, param).finally(() => {
                setUpsertTask(null);
              });
            }}
          >
            <DialogTitle>Send mail</DialogTitle>
            <DialogContent>
              <Input name="_id" type="text" defaultValue={upsertTask?._id} style={{ display: 'none' }} />
              <Input name="mode" type="text" value="sms" style={{ display: 'none' }} />
              {/* <Input name="type" type="text" label="Domain" defaultValue={upsertTask?.type} required /> */}
              <p />
              <Input name="template1" type="text" label="Mail Template1" defaultValue={(upsertTask?.templates || [])[0]} required />
              <p />
              <Input name="template2" type="text" label="Mail Template2" defaultValue={(upsertTask?.templates || [])[1]} />
              <p />
              <Input name="template3" type="text" label="Mail Template3" defaultValue={(upsertTask?.templates || [])[2]} />
              <p />
              <Input name="resend" type="checkbox" label="Looping send  (resend)" defaultValue={upsertTask?.options?.resend || false} />
              <p />
              <Input name="multiple" type="checkbox" label="Send email even already sent (multiple)" defaultValue={upsertTask?.options?.multiple || false} />
              <p />
              <Input name="random" type="checkbox" label="Send email randomly (random)" defaultValue={upsertTask?.options?.random || false} />
              <p />
              <Input name="autosend" type="checkbox" label="Run from 4:00 to 18:00" defaultValue={upsertTask?.options?.autosend || false} />
              <p />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setUpsertTask(null);
                }}
                color="primary"
              >
                Close
              </Button>
              <Button type="submit" color="primary">
                {upsertTask?._id ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>&nbsp;&nbsp;&nbsp;&nbsp; Logs</span>
          <span>
            <Button onClick={() => setShowLogs((current) => !current)}>{!showTasks ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showLogs && (
          <div style={{ backgroundColor: '#111', minHeight: 300 }}>
            {(smsStatus?.logs || []).map((log, index) => (
              <div key={index}>
                <span style={{ color: '#f7f6' }}>{new Date(log.at).toLocaleString()}</span> &nbsp;&nbsp;
                <span style={{ color: '#888' }}>[{log.number}]</span>&nbsp;&nbsp;
                <span style={{ color: log.success ? 'green' : '#f888' }}>{log.result}</span>&nbsp;&nbsp;
              </div>
            ))}
          </div>
        )}
      </Box>
    </>
  );
}
