import React from 'react';

export default function CommentItem({ comment }) {
  const today = new Date();
  if (today.getUTCHours() < 3) today.setUTCDate(today.getUTCDate() - 1);
  today.setUTCHours(0, 0, 0, 0);
  const today0 = today.getTime() + 3 * 60 * 60 * 1000;

  return (
    <div key={comment._id} style={{ color: comment.type !== 'Usado' || comment.createdAt > today0 ? 'inherit' : 'gray' }}>
      {!!comment.type && comment.type !== 'Login' && `${comment.type}: `}
      {comment.description}
      {comment.type !== 'Login' && ` at ${new Date(comment.createdAt).toLocaleString('pt-br')}`}
      {comment.owner && comment.type !== 'Login' && (
        <>
          &nbsp;-&nbsp;
          <span style={{ fontStyle: 'italic' }}>{comment.owner}</span>
        </>
      )}
    </div>
  );
}
