import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import { CardActionArea, CardContent, ListItemIcon, ListItemText, MenuItem, MenuList } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import {
  CardGiftcard,
  Camera,
  Close,
  CloudDownload,
  Comment,
  DeleteForever,
  Favorite,
  Flag,
  FlagOutlined,
  Link,
  Menu,
  MenuOpen,
  Money,
  Pages,
  Pageview,
  Payment,
  PlayArrow,
  Receipt,
  Refresh,
  Save,
  SendSharp,
  Star,
  StarOutline,
  Stop,
  StopOutlined,
  StopRounded,
} from '@material-ui/icons';

import Copy from '../../components/Copy';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import API from '../../services/API';
import SessionService from '../../services/SessionService';
import CommentItem from '../Comment';
import Input from '../Form/Input';
import { ReactComponent as QRSvg } from '../qr.svg';

// The default

function ScreenFrame({ data }) {
  const { user, isAdminMP } = useAuth();
  const { ip, token, email, type, owner } = data;
  const [showMemu, setShowMenu] = useState(false);
  const [comments, setComments] = useState(null);
  const [session, setSession] = useState(null);
  const [qrDialog, setQrDialog] = useState(null);
  const dispatch = useDispatch();

  const { data: genymotion } = useFetch(`/api/sv/devices/${type}/${ip}`, { refreshInterval: 5000 });
  const username = genymotion?.username || data.username || '';

  const reload = () => {
    if (username && type === 'mp') {
      SessionService.list({ username }).then((e) => {
        setSession(e.data[0]);
      });
    }
  };
  useEffect(() => {
    reload();
  }, []);

  return (
    <Draggable handle=".handle" scale={1}>
      <div style={{ position: 'absolute', left: 10, top: 10, width: 400 }}>
        <div
          className="handle"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            backgroundColor: session && session.usingUser ? (session.usingUser === user.username ? 'darkcyan' : '#833') : '#331',
            padding: 4,
            height: 33,
          }}
        >
          <span>
            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 330 }}>
              [{owner}] {username} ({ip})
            </div>
          </span>
          <span>
            {type === 'mp' && username && (
              <>
                {!showMemu ? <MenuOpen onClick={() => setShowMenu(true)} /> : <Menu onClick={() => setShowMenu(false)} />}
                {showMemu && session && (
                  <div style={{ position: 'absolute' }}>
                    <MenuList style={{ backgroundColor: '#000e' }} onClick={() => setShowMenu(false)} onBlur={() => setShowMenu(false)}>
                      <>
                        <MenuItem onClick={() => SessionService.update({ id: username, usingUser: session?.usingUser ? '' : user.username }).then(() => reload())}>
                          <ListItemIcon style={{ color: session?.usingUser ? (session?.usingUser === user.username ? 'darkcyan' : '#C33') : 'inherit' }}>
                            {session?.usingUser ? <Flag /> : <FlagOutlined />}
                          </ListItemIcon>
                          <ListItemText>Using</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => window.open(`/mp/${username}`, '_blank')}>
                          <ListItemIcon>
                            <Link />
                          </ListItemIcon>
                          <ListItemText>Open Session</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            SessionService.getComments(username).then((r) => {
                              setComments((r.data || []).reverse());
                            })
                          }
                        >
                          <ListItemIcon>
                            <Comment />
                          </ListItemIcon>
                          <ListItemText>Comments</ListItemText>
                        </MenuItem>
                        {isAdminMP && (
                          <MenuItem
                            onClick={() => {
                              const usadoValue = window.prompt(`Insert usado value`, 0);
                              if (+usadoValue) {
                                SessionService.update({
                                  id: username,
                                  usadoValue: +(session?.usadoValue || 0) + +usadoValue,
                                  comments: [...session.comments, { type: 'Usado', description: usadoValue, createdAt: new Date().getTime(), owner: user.username }],
                                }).then(() => reload());
                              }
                            }}
                          >
                            <ListItemIcon>
                              <Money />
                            </ListItemIcon>
                            <ListItemText>Usado</ListItemText>
                          </MenuItem>
                        )}

                        <MenuItem onClick={() => SessionService.update({ id: username, favorite: 1 }).then(() => reload())}>
                          <ListItemIcon style={{ color: session?.favorite ? 'red' : 'inherit' }}>{session?.favorite ? <Star /> : <StarOutline />}</ListItemIcon>
                          <ListItemText>Favoite</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => SessionService.update({ id: username, done: 1 }).then(() => reload())}>
                          <ListItemIcon style={{ color: session?.done ? 'red' : 'inherit' }}>{session?.done ? <StopRounded /> : <StopOutlined />}</ListItemIcon>
                          <ListItemText>Done</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => SessionService.addComment(username, 'kiked').then(() => reload())}>
                          <ListItemIcon>
                            <DeleteForever />
                          </ListItemIcon>
                          <ListItemText>Kick</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => dispatch({ type: 'CAMERA_SHOW', value: ip })}>
                          <ListItemIcon>
                            <Camera />
                          </ListItemIcon>
                          <ListItemText>Camera</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            document.body.style.cursor = 'wait';
                            API.post(`/api/sv/sessions/payment/api/creditcard/activate`, { username })
                              .then((res) => {
                                if (res.data.success) window.alert('😄 Success to activate');
                                else window.alert(`☹️ ${res.data?.message || 'unknown'}`);
                              })
                              .catch((e) => {
                                window.alert(`☹️ Failed: ${e.response?.data?.error || 'unknown'}`);
                              })
                              .finally(() => {
                                document.body.style.cursor = null;
                              });
                          }}
                        >
                          <ListItemIcon>
                            <CardGiftcard />
                          </ListItemIcon>
                          <ListItemText>Activate CreditCard</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            SessionService.shell(ip, `am start -d "mercadopago://credit-card-upgrade?from=SHORTCUT"`);
                          }}
                        >
                          <ListItemIcon>EC</ListItemIcon>
                          <ListItemText>Enable CreditCard</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const money = +window.prompt(`Insert money R$`);
                            if (money) {
                              setQrDialog({ money, username });
                              API.post(`/api/sv/sessions/payment/qr/generate`, { username, money })
                                .then(({ data }) => {
                                  setQrDialog((curr) => ({ ...curr, ...data }));
                                })
                                .catch((err) => {
                                  setQrDialog((curr) => ({ ...curr, error: err.response.data.error }));
                                });
                            }
                          }}
                        >
                          <ListItemIcon>
                            <QRSvg style={{ width: 18 }} />
                          </ListItemIcon>
                          <ListItemText>Receive QR</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const phone = window.prompt(`Insert TIM number`, window.localStorage.getItem('PAYMENT_PHONE') || '');
                            if (phone) window.localStorage.setItem('PAYMENT_PHONE', phone);

                            document.body.style.cursor = 'wait';
                            API.post(`/api/sv/sessions/payment/tim`, { ip, phone }).finally(() => {
                              document.body.style.cursor = 'default';
                            });
                          }}
                        >
                          <ListItemIcon>Tim</ListItemIcon>
                          <ListItemText>Payment Tim</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const phone = window.prompt(`Insert VIVO number`, window.localStorage.getItem('PAYMENT_PHONE') || '');
                            if (phone) window.localStorage.setItem('PAYMENT_PHONE', phone);

                            document.body.style.cursor = 'wait';
                            API.post(`/api/sv/sessions/payment/vivo`, { ip, phone }).finally(() => {
                              document.body.style.cursor = 'default';
                            });
                          }}
                        >
                          <ListItemIcon>Vi</ListItemIcon>
                          <ListItemText>Payment VIVO</ListItemText>
                        </MenuItem>
                      </>
                    </MenuList>
                  </div>
                )}
              </>
            )}
            <span style={{ cusor: 'pointer' }} onClick={() => dispatch({ type: 'SCREEN_REMOVE', value: ip })}>
              <Close />
            </span>
          </span>
        </div>
        <div style={{ backgroundColor: 'black', padding: 0 }}>
          <iframe src={`/screen/${type}/${ip}?token=${token}`} frameBorder={0} width={400} height={700} />
        </div>
        <div
          className="handle"
          style={{
            cursor: 'move',
            backgroundColor: '#0F08',
            height: 33,
          }}
        ></div>
        <Dialog open={Boolean(comments)} onClose={() => setComments(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              if (param.comment)
                SessionService.addComment(username, param.comment).then(() => {
                  SessionService.getComments(username).then((r) => setComments((r.data || []).reverse()));
                });
            }}
          >
            <DialogTitle>Comentários</DialogTitle>
            <DialogContent>
              <Input autoFocus label="Novo Comentário" name="comment" rows="1" />
            </DialogContent>
            <DialogContent>
              {(comments || []).map((comment) => (
                <CommentItem comment={comment} />
              ))}
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                Add
              </Button>
              <Button onClick={() => setComments(null)} color="primary">
                Cancelar
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
        <Dialog open={!!qrDialog} onClose={() => setQrDialog(null)}>
          <DialogTitle>Corbar com codigo QR ou Pix</DialogTitle>
          <DialogContent> Email: {qrDialog?.username} </DialogContent>
          <DialogContent> Money: R$ {qrDialog?.money} </DialogContent>
          <DialogContent> Type: {qrDialog?.kind} </DialogContent>
          <DialogContent>
            <div style={{ padding: 24, backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
              {qrDialog?.qr_data ? (
                <QRCode value={qrDialog.qr_data} />
              ) : qrDialog?.error ? (
                <span style={{ color: '#c00' }}>{qrDialog?.error}</span>
              ) : (
                <span style={{ color: 'black' }}>Generating...</span>
              )}
            </div>
          </DialogContent>
          <DialogContent>
            <Copy value={qrDialog?.qr_data}>{qrDialog?.qr_data}</Copy>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setQrDialog(null)} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Draggable>
  );
}

export default function Screens() {
  const screens = useSelector((s) => s.screens) || [];
  return (
    <div style={{ position: 'fixed', left: 0, top: 0, zIndex: 1201 }}>
      {screens.filter(Boolean).map((screen, index) => (
        <div key={index}>{<ScreenFrame key={index} data={screen} />}</div>
      ))}
    </div>
  );
}
