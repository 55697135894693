import React, { memo } from 'react';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';

import CommentItem from '../Comment';

const Icons = require('@material-ui/icons');

const CommentsComponent = ({ row, onOpen }) => {
  const { comments = [] } = row;
  const lastComment = comments[comments.length - 1];

  return (
    <>
      {Boolean(lastComment) && <CommentItem comment={lastComment} />}
      <Tooltip title="Ver/add comentários">
        <ButtonGroup size="small">
          <Button size="small" variant="outlined" onClick={() => onOpen(row)}>
            <Badge color="secondary" badgeContent={Math.max(0, comments?.length - 1)}>
              <Icons.Add fontSize="small" />
            </Badge>
          </Button>
        </ButtonGroup>
      </Tooltip>
    </>
  );
};

const Comments = memo(CommentsComponent);

export default Comments;
