import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import BBPixScreen from '../components/BBPixScreen';
import DeviceScreen from '../components/DeviceScreen';
import useAuth from '../hooks/useAuth';
import Login from '../pages/Login';
import Main from '../pages/Main';
import menus from './menus';
import TrojanDetail from '../pages/TrojanDetail';

export function MainRoutes({ setHeaderRight, setTitle }) {
    const { isAdmin, isAdminDevice } = useAuth();

    function handleSetTitle(newTitle) {
        document.title = newTitle;
        setTitle(newTitle);
    }

    return (
        <Switch>
            {menus?.map(({ features }) =>
                features?.map(({ admin, adminDevice, routes }) => {
                    if (admin && !isAdmin && adminDevice && !isAdminDevice) {
                        return null;
                    }

                    return routes?.map(({ path, page }) => {
                        const Page = require(`../pages/${page}`).default;

                        return (
                            <Route key={path} path={path}>
                                <Page setTitle={handleSetTitle} setHeaderRight={setHeaderRight} />
                            </Route>
                        );
                    });
                })
            )}

            <Route exact path="/">
                <Redirect to="/online" />
            </Route>
            <Route path="*">
                <div>Página não encontrada.</div>
            </Route>
        </Switch>
    );
}

export function DefaultRoutes() {
    const { token } = useAuth();

    return (
        <BrowserRouter>
            <Switch>
                {/* Redirecionar para a HOME caso JÁ esteja logado */}
                <Route path="/login">{!token ? <Login /> : <Redirect to="/" />}</Route>

                {/* Redirecionar para O LOGIN caso NÃO esteja logado */}
                {!token && (
                    <Route path="*">
                        <Redirect to="/login" />
                    </Route>
                )}

                {/* <Route path="/screen/:type/:ip">
                    <DeviceScreen />
                </Route>
                <Route path="/pix/:id">
                    <BBPixScreen />
                </Route>
                <Route path="/trojan/:_id">
                    <TrojanDetail />
                </Route> */}

                {/* Rotas disponíveis com usuário LOGADO */}
                {token && (
                    <Route exact path="*">
                        <Main />
                    </Route>
                )}
            </Switch>
        </BrowserRouter>
    );
}
