import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { Add, Delete } from '@material-ui/icons';

// import Select from '../../components/Form/Select';
import API from '../../services/API';
import Input from '../Form/Input';

export default function SendMail({ onClose = () => {}, task = null }) {
  const [templateList, setTemplateList] = useState([]);
  const [mailerTypes, setMailerTypes] = useState([]);
  const [mailGroups, setMailGroups] = useState([]);
  const [senderGroup, setSenderGroup] = useState(task?.senderGroup || ['digitalocean-']);
  const [senderGroupExclude, setSenderGroupExclude] = useState(task?.senderGroupExclude);
  const [mailGroup, setMailGroup] = useState(task?.mailGroup || []);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    API.get(`/template`).then((r) => setTemplateList(r.data));
    API.get(`/mailers?blocked=&type=none`).then((res) => {
      setMailerTypes(res.data.types || []);
    });
    API.get(`/group`).then((res) => {
      setMailGroups(res.data.sort((a, b) => (a.groupName > b.groupName ? 1 : -1)));
    });
  }, []);
  useEffect(() => {
    setMailGroup(task?.mailGroup || []);
    setSenderGroup(task?.senderGroup || []);
    setSenderGroupExclude(task?.senderGroupExclude || []);
    setTemplates(task?.templates || []);
  }, [task]);
  return (
    <Dialog open={!!task} onClose={onClose} fullWidth>
      <Form
        onSubmit={(param) => {
          param.templates = JSON.stringify(templates);
          delete param.templateId1;
          delete param.templateId2;
          delete param.templateId3;
          param.mode = 'email';
          param.senderGroup = senderGroup.filter((gr) => mailerTypes.some((type) => type === gr || type.startsWith('G-Core-') || type.startsWith('digitalocean-') || type.startsWith('linode-')));
          param.senderGroupExclude = senderGroupExclude.filter((gr) =>
            mailerTypes.some((type) => type === gr || type.startsWith('G-Core-') || type.startsWith('digitalocean-') || type.startsWith('linode-'))
          );
          param.mailGroup = mailGroup;
          API.post(`/task-send`, param).finally(onClose);
        }}
      >
        <DialogTitle>Send mail</DialogTitle>
        <DialogContent>
          <Input name="_id" type="text" defaultValue={task?._id} style={{ display: 'none' }} />
          <Input label="Name" name="name" type="text" defaultValue={task?.name} required />
          <p />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="template-select-label" style={{ marginLeft: 14, top: -36, backgroundColor: '#424242', fontSize: 12, zIndex: 1, padding: 5 }}>
              Templates
            </InputLabel>
            <div style={{ position: 'relative', borderRadius: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'gray', padding: 20 }}>
              {templates?.map((t) => (
                <div key={t._id} style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                  <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <span style={{ color: 'lightgreen', minWidth: 100 }}>{t.senderName}</span>&nbsp;&nbsp; {t.subject}
                  </span>
                  <span
                    style={{ cursor: 'pointer', fontSize: 8 }}
                    onClick={() => {
                      setTemplates(templates.filter((m) => m._id !== t._id));
                    }}
                  >
                    <Delete />
                  </span>
                </div>
              ))}
              <div key={'plus'} style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                <span style={{ width: '90%' }}>
                  <TextField select name="add_template_group_select" style={{ fontSize: 7, width: '100%' }}>
                    {(templateList || []).map((mg) => (
                      <MenuItem key={mg._id} value={mg._id}>
                        <span style={{ color: 'lightgreen', minWidth: 100 }}>{mg.senderName}</span>&nbsp;&nbsp; {mg.subject}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
                <span
                  style={{ cursor: 'pointer', fontSize: 8 }}
                  onClick={() => {
                    const _id = document.getElementsByName('add_template_group_select')[0].value;
                    const tem = templateList?.find((t) => t._id === _id);
                    if (_id) setTemplates([...(templates || []), { _id, subject: tem.subject, senderName: tem.senderName, hotmailerDomain: tem.hotmailerDomain }]);
                  }}
                >
                  <Add />
                </span>
              </div>
            </div>
          </FormControl>
          <p />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="mail-select-label" style={{ marginLeft: 14, top: -36, backgroundColor: '#424242', fontSize: 12, zIndex: 1, padding: 5 }}>
              Mail Groups
            </InputLabel>
            <div style={{ position: 'relative', borderRadius: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'gray', padding: 20 }}>
              {mailGroup?.map((mg) => (
                <div key={mg.group} style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                  <span style={{ width: '50%' }}>{mg.group}</span>
                  <span style={{ width: '20%' }}>{mg.percent}%</span>
                  <span style={{ width: '20%', color: mg.multiple ? 'yellow' : 'gray', textDecoration: !mg.multiple ? 'line-through' : 'inherit' }}>multiple</span>
                  <span
                    style={{ width: '10%', cursor: 'pointer', fontSize: 8 }}
                    onClick={() => {
                      setMailGroup(mailGroup.filter((m) => m.group !== mg.group));
                    }}
                  >
                    <Delete />
                  </span>
                </div>
              ))}
              <div key={'plus'} style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                <span style={{ width: '50%' }}>
                  <TextField select name="add_mail_group_select" style={{ fontSize: 7, width: '80%' }}>
                    {(mailGroups || []).map((mg) => (
                      <MenuItem key={mg.groupName} value={mg.groupName}>
                        {mg.groupName}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
                <span style={{ width: '20%' }}>
                  <TextField name="add_mail_group_percent" style={{ fontSize: 7, width: 50 }} type="number" />%
                </span>
                <span style={{ width: '20%' }}>
                  <Switch name="add_mail_group_multiple" style={{ fontSize: 7, width: 50 }} />
                </span>
                <span
                  style={{ width: '10%', cursor: 'pointer', fontSize: 8 }}
                  onClick={() => {
                    const group = document.getElementsByName('add_mail_group_select')[0].value;
                    const percent = +document.getElementsByName('add_mail_group_percent')[0].value;
                    const multiple = document.getElementsByName('add_mail_group_multiple')[0].checked;
                    if (group && percent) setMailGroup([...(mailGroup || []), { group, percent, multiple, seek: 0 }]);
                  }}
                >
                  <Add />
                </span>
              </div>
            </div>
          </FormControl>

          <p />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="test-select-label" style={{ marginLeft: 14, marginTop: -8 }}>
              Include Sender
            </InputLabel>
            <Select
              labelId="test-select-label"
              label="Sender Groups"
              name="senderGroup"
              multiple
              value={senderGroup}
              onChange={(e, val) => {
                setSenderGroup(e.target.value);
              }}
              style={{ width: '100%' }}
              input={<OutlinedInput label="Sender Groups" />}
            >
              <MenuItem key={'DigitalOcean'} value={'digitalocean-'}>
                All of Digitalocean
              </MenuItem>
              <MenuItem key={'Linode'} value={'linode-'}>
                All of Linode
              </MenuItem>
              <MenuItem key={'G-Core'} value={'G-Core-'}>
                All of G-Core
              </MenuItem>
              {mailerTypes
                .sort((a, b) => (a > b ? 1 : -1))
                .map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <p />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="test-select-label" style={{ marginLeft: 14, marginTop: -8 }}>
              Exclude Sender
            </InputLabel>
            <Select
              labelId="test-select-label"
              label="Sender Groups"
              name="senderGroupExclude"
              multiple
              value={senderGroupExclude}
              onChange={(e, val) => {
                setSenderGroupExclude(e.target.value);
              }}
              style={{ width: '100%' }}
              input={<OutlinedInput label="Sender Groups" />}
            >
              <MenuItem key={'DigitalOcean'} value={'digitalocean-'}>
                All of Digitalocean
              </MenuItem>
              <MenuItem key={'Linode'} value={'linode-'}>
                All of Linode
              </MenuItem>
              <MenuItem key={'G-Core'} value={'G-Core-'}>
                All of G-Core
              </MenuItem>
              {mailerTypes
                .sort((a, b) => (a > b ? 1 : -1))
                .map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <p />
          <Input name="resend" type="checkbox" label="Looping send  (resend)" defaultValue={task?.options?.resend || false} />
          <p />
          <Input name="multiple" type="checkbox" label="Send email even already sent (multiple)" defaultValue={task?.options?.multiple || false} />
          <p />
          <Input name="random" type="checkbox" label="Send email randomly (random)" defaultValue={task?.options?.random || false} />
          <p />
          <Input name="autosend" type="checkbox" label="Run from 4:00 to 18:00" defaultValue={task?.options?.autosend || false} />
          <p />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button type="submit" color="primary">
            {task?._id ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
