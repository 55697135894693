import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Input from '../../components/Form/Input';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle }) {
  useEffect(() => setTitle('Namecheap'), [setTitle]);
  const { isAdmin, isAdminMailer, user } = useAuth();

  const [currentNamecheap, setCurrentNamecheap] = useState(null);
  const [namecheaps, setNamecheaps] = useState([]);
  const [namecheapDomains, setNamecheapDomains] = useState([]);

  const refresh = () => {
    API.get(`/api/sender/vps-account`).then((res) => {
      const data = res.data || [];
      setNamecheaps(data.filter((r) => r.type === 'namecheap'));
    });
  };
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (namecheaps.length) {
      namecheaps.forEach((namecheap) => {
        API.get(`/api/sender/namecheap/${namecheap.info.apikey}`).then((res) => {
          setNamecheapDomains((current) => ({ ...current, [namecheap._id]: res.data.domains }));
        });
      });
    }
  }, [namecheaps]);

  return !(isAdmin || isAdminMailer) ? (
    <></>
  ) : (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" onClick={() => setCurrentNamecheap({})}>
              <Icons.Add fontSize="small" />
            </Button>
            Namecheap ({namecheaps?.length})
          </span>
        </div>
        <Table
          size="small"
          data={namecheaps?.map((a, index) => ({ ...a, index: index + 1 })) || []}
          columns={[
            {
              title: 'ID',
              attribute: 'index',
            },
            {
              title: 'Owner',
              attribute: 'owner',
            },
            {
              title: 'ApiUser',
              Cell: ({ info }) => info.apiuser,
            },
            {
              title: 'ApiKey',
              Cell: ({ info }) => info.apikey,
            },
            {
              title: 'Balance',
              Cell: ({ balance }) => (balance ? balance : <span style={{ color: '#F88' }}>0</span>),
            },
            {
              title: 'Good / Bad',
              Cell: ({ _id }) =>
                namecheapDomains[_id] ? (
                  <>
                    <span style={{ color: 'lightgreen' }}>{namecheapDomains[_id].filter((d) => !d.hetrix?.blocked || d.hetrix?.blocked === 'Over MAX Zone').length || 0}</span> /&nbsp;
                    <span style={{ color: '#f88' }}>{namecheapDomains[_id].filter((d) => d.hetrix?.blocked && d.hetrix?.blocked !== 'Over MAX Zone').length || 0}</span>
                  </>
                ) : (
                  ''
                ),
            },
            {
              title: 'Created',
              attribute: 'createdAt',
              Cell: ({ createdAt }) => new Date(createdAt).toLocaleString(),
            },
            {
              title: 'Action',
              Cell: (row) => (
                <>
                  <ButtonGroup size="small">
                    <Button title="Edit" onClick={() => setCurrentNamecheap({ ...row, domains: [...(namecheapDomains[row._id] || [])] })}>
                      <Icons.List fontSize="small" />
                    </Button>
                    <Button
                      title="Remover"
                      onClick={() => {
                        if (window.confirm(`Are you sure to delete the Namecheap account`)) {
                          API.delete(`/api/sender/vps-account/${row._id}`).then(() => refresh());
                        }
                      }}
                    >
                      <Icons.Delete fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </>
              ),
            },
          ]}
        />
        <br />

        <Dialog open={!!currentNamecheap} onClose={() => setCurrentNamecheap(null)} fullWidth>
          <Form
            onSubmit={(param) => {
              param.info = { apiuser: param.apiuser, apikey: param.apikey };
              delete param.apiuser;
              delete param.apikey;
              API.put(`/api/sender/vps-account/${param._id || 'namecheap-' + new Date().getTime()}`, param)
                .then(() => refresh())
                .catch((e) => {
                  window.alert(`Cannot add account: ${e.response?.data?.error}`);
                });
              setCurrentNamecheap(null);
            }}
          >
            <DialogTitle>{currentNamecheap?._id ? 'Update' : 'Add'} Namecheap</DialogTitle>
            <DialogContent>
              <Input type="text" name="_id" defaultValue={currentNamecheap?._id} style={{ display: 'none' }} />
              <Input type="text" name="type" value="namecheap" style={{ display: 'none' }} />
              <Input type="text" name="owner" defaultValue={currentNamecheap?.owner || user.username} />
              <p />
              <Input label="Api User" type="text" name="apiuser" defaultValue={currentNamecheap?.info?.apiuser} autoFocus required />
              <p />
              <Input label="Api Key" type="text" name="apikey" defaultValue={currentNamecheap?.info?.apikey} required />
              <p />
              {currentNamecheap?.domains && (
                <div style={{ overflowY: 'scroll', maxHeight: 300 }}>
                  <Table
                    size="small"
                    data={currentNamecheap?.domains?.map((a, index) => ({ ...a, index: index + 1 }))}
                    columns={[
                      {
                        title: 'ID',
                        attribute: 'index',
                      },
                      {
                        title: 'Name',
                        attribute: 'Name',
                      },
                      {
                        title: 'Blocked',
                        Cell: ({ hetrix }) => (hetrix.blocked && hetrix?.blocked !== 'Over MAX Zone' ? <span style={{ color: '#f88' }}>Bad</span> : <span style={{ color: 'lightgreen' }}>Good</span>),
                      },
                    ]}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentNamecheap(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {currentNamecheap?._id ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Box>
    </>
  );
}
