import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';
import SessionService from '../../services/SessionService';

const Icons = require('@material-ui/icons');

function SmallScreen({ device, setAddDlg, devices }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { session } = device;

  return (
    <div
      style={{ backgroundColor: '#0002', width: 160, margin: 3, position: 'relative' }}
      onDoubleClick={() => {
        dispatch({ type: 'SCREEN_ADD', value: device });
      }}
    >
      <div
        style={{
          backgroundColor: session && session.usingUser ? (session.usingUser === user.username ? 'darkcyan' : '#833') : device.username ? '#ff02' : '#1115',
          padding: 2,
          fontSize: 11,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 13, width: device.username ? 100 : 110 }}>
            {/* <a href={`https://mercado:Smoking10!@${device.ip}`} target="_blank" style={{ color: 'whitesmoke' }}> */}
            {device.owner} - {device.username}
            {/* </a> */}
          </div>
        </span>
        &nbsp;
        {/* {device.username && (
          <>
            <span
              style={{ cursor: 'pointer', color: '#f66' }}
              onClick={() => {
                if (window.confirm(`Are you sure to load "${device.username}" into all Devices?`)) {
                  const otherDeviceIPs = (devices || []).filter((d) => d.ip != device?.ip).map((r) => r.ip);
                  otherDeviceIPs.map((ip) => {
                    API.post(`/api/sv/devices/load`, { username: device.username, ip });
                  });
                }
              }}
            >
              =&gt;
            </span>
            &nbsp;
          </>
        )} */}
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (setAddDlg) setAddDlg(device);
          }}
        >
          <Icons.HelpOutline style={{ fontSize: 16 }} />
        </span>
        &nbsp;
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (window.confirm(`Are you sure to delete the VM - ${device.ip}`))
              SessionService.delete(device.ip).catch((e) => {
                console.error(e);
              });
          }}
        >
          <Icons.Close style={{ fontSize: 18 }} />
        </span>
        &nbsp;
      </div>
      <div style={{ backgroundColor: '#1115', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 14, position: 'static', bottom: 0 }}>{device.ip}</div>
      {device.screen && <img src={URL.createObjectURL(new Blob([new Uint8Array(device.screen.data)]))} width={160} />}
    </div>
  );
}
export default function ({ setTitle }) {
  useEffect(() => setTitle('Devices'), [setTitle]);
  const global = useSelector((s) => s.global);
  const { isAdminDevice, user } = useAuth();
  const [mpShow, setMPShow] = useState(true);
  // const [bbShow, setBBShow] = useState(false);
  const [deviceMPs, setDeviceMPs] = useState([]);
  // const [deviceBBs, setDeviceBBs] = useState([]);
  const [time, setTime] = useState(0);
  const myVMs = global?.userList?.find((u) => u.username === user?.username)?.vms;

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (mpShow && isAdminDevice) API.get('/api/sv/devices/mp').then(({ data }) => setDeviceMPs(data.filter((vm) => new RegExp(myVMs).test(vm.owner))));
    // if (bbShow && isAdminDevice) API.get('/api/sv/devices/bb').then(({ data }) => setDeviceBBs(data.filter((vm) => new RegExp(myVMs).test(vm.owner))));
  }, [mpShow, time, isAdminDevice, myVMs]);
  const [addDlg, setAddDlg] = useState(null);

  return !isAdminDevice ? (
    "You don't have permission"
  ) : (
    <>
      <div style={{ fontFamily: 'sans-serif' }}>
        <div>
          <Box component={Paper} mb={2}>
            <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
              <span>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setAddDlg({ type: 'mp' });
                  }}
                >
                  <Icons.Add fontSize="small" />
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp; Running MP ({deviceMPs.length})
              </span>
              {mpShow ? (
                <Button size="small" onClick={() => setMPShow(false)}>
                  <Icons.Close />
                </Button>
              ) : (
                <Button size="small" onClick={() => setMPShow(true)}>
                  <Icons.RemoveRedEye />
                </Button>
              )}
            </div>
            {mpShow && (
              <>
                <div style={{ padding: 8, display: 'flex', flexWrap: 'wrap' }}>
                  {deviceMPs
                    .filter((g) => g.power === 'on' && g.appInstalled)
                    .sort((a, b) => (a.owner > b.owner ? 1 : -1))
                    .map((device, index) => (
                      <SmallScreen key={index} device={device} setAddDlg={setAddDlg} devices={deviceMPs} />
                    ))}
                </div>
                <div style={{ padding: 8, display: 'flex', flexWrap: 'wrap' }}>
                  {deviceMPs
                    .filter((g) => g.power === 'off' || !g.appInstalled)
                    .sort((a, b) => (a.owner > b.owner ? 1 : -1))
                    .map((device, index) => (
                      <SmallScreen key={index} device={device} setAddDlg={setAddDlg} />
                    ))}
                </div>
              </>
            )}
          </Box>

          {/* <Box component={Paper} mb={2}>
            <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
              <span>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setAddDlg({ type: 'bb' });
                  }}
                >
                  <Icons.Add fontSize="small" />
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp; Running BB ({deviceBBs.length})
              </span>
              {bbShow ? (
                <Button size="small" onClick={() => setBBShow(false)}>
                  <Icons.Close />
                </Button>
              ) : (
                <Button size="small" onClick={() => setBBShow(true)}>
                  <Icons.RemoveRedEye />
                </Button>
              )}
            </div>
            {bbShow && (
              <>
                <div style={{ padding: 8, display: 'flex', flexWrap: 'wrap' }}>
                  {deviceBBs
                    .filter((g) => g.power === 'on' && g.appInstalled)
                    .sort((a, b) => (a.owner > b.owner ? 1 : -1))
                    .map((device, index) => (
                      <SmallScreen key={index} device={device} setAddDlg={setAddDlg} />
                    ))}
                </div>
                <div style={{ padding: 8, display: 'flex', flexWrap: 'wrap' }}>
                  {deviceBBs
                    .filter((g) => g.power === 'off' || !g.appInstalled)
                    .sort((a, b) => (a.owner > b.owner ? 1 : -1))
                    .map((device, index) => (
                      <SmallScreen key={index} device={device} setAddDlg={setAddDlg} />
                    ))}
                </div>
              </>
            )}
          </Box> */}
        </div>
      </div>

      <Dialog open={!!addDlg} onClose={() => setAddDlg(null)}>
        <Form
          onSubmit={(param) => {
            SessionService.add(param)
              .then(() => {
                alert(`Success. Wait sometime to init it`);
              })
              .catch((e) => window.alert(e.response?.data.error || `Error.`));
            setAddDlg(null);
          }}
        >
          <DialogTitle>Add Device ({addDlg?.type})</DialogTitle>
          <DialogContent>
            <Input label="owner" name="owner" defaultValue={addDlg?.owner || window.localStorage.getItem(`device_owner`)} />
            <p />
            <Input label="Email" name="email" defaultValue={addDlg?.email || window.localStorage.getItem(`device_email`)} />
            <p />
            <Input required label="IP" name="ip" defaultValue={addDlg?.ip} disabled={!!addDlg?.ip} />
            <p />
            <Input required label="Temparary password" name="token" defaultValue={addDlg?.token} />
            <p />
            <Select
              required
              label="Type"
              name="type"
              defaultValue={addDlg?.type}
              options={[
                { label: 'MP', value: 'mp' },
                { label: 'BB', value: 'bb' },
                // { label: 'Refresh', value: 'autorefresh' },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddDlg(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              {addDlg?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
