import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import config from '../../config';

export default function IpLogs({ setTitle }) {
  const { isAdmin } = useAuth();
  const [loadingPage, setLoadingPage] = useState(false);
  const [error, setError] = useState(false);
  const [iplogs, setIplogs] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [filterIp, setFilterIp] = useState('');
  const [filterHash, setFilterHash] = useState('');
  const limitDefault = 20;
  const [limit, setLimit] = useState(limitDefault);
  const [offset, setOffset] = useState(0);

  useEffect(() => setTitle('Access Logs'), [setTitle]);

  useEffect(() => {
    setLoadingPage(true);
    axios.get(`${config.authSv}/api/access-logs`, {
      params: {
        limit,
        offset,
        ip: filterIp,
        hash: filterHash,
      },
    })
      .then(({ data }) => {
        console.log('Response data:', data);
        setHasMore(data.length === limit);
        setIplogs((current) => (offset ? [...current, ...data] : data));
      })
      .catch((error) => {
        console.error('Error fetching access logs:', error);
        setError(true);
      })
      .finally(() => setLoadingPage(false));
  }, [limit, offset, filterIp, filterHash]);

  const handleMore = () => {
    setOffset(offset + limit);
  };

  const handleFilterIp = (e) => {
    if (e.key === 'Enter') {
      setOffset(0);
      setIplogs([]);
      setFilterIp(e.target.value);
    }
  };

  const handleFilterHash = (e) => {
    if (e.key === 'Enter') {
      setOffset(0);
      setIplogs([]);
      setFilterHash(e.target.value);
    }
  };

  if (error) {
    return <Box color="error.main">Erro ao buscar os dados.</Box>;
  }

  if (!iplogs.length) {
    return <Loading />;
  }

  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Grid container spacing={4} justify="flex-start">
          <Grid item>
            <Box color="success.main">
              <TextField
                type="text"
                label="Filter: IP"
                onKeyPress={handleFilterIp}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box color="error.main">
              <TextField
                type="text"
                label="Filter: Hash"
                onKeyPress={handleFilterHash}
                size="small"
                style={{ marginTop: -14, marginLeft: 10, fontSize: 9, width: 150 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Table
        data={iplogs.map((l, index) => ({ ...l, index }))}
        columns={[
          {
            title: 'No',
            Cell: ({ index }) => index + 1,
          },
          {
            title: 'Last Access',
            attribute: 'date',
            Cell: ({ date }) => {
              const formattedDate = new Date(date).toLocaleString('pt-BR');
              return isNaN(new Date(date)) ? 'Invalid Date' : formattedDate;
            },
          },
          {
            title: 'Redir',
            attribute: 'redir',
          },
          {
            title: 'IP',
            attribute: 'ip',
          },
          {
            title: 'IP Info',
            Cell: ({ asn, city, state, country }) =>
              asn && city && state && country && (
                <div>
                  <span>{asn} / {city}, {state}, {country}</span>
                </div>
              ),
          },
          {
            title: 'Hash / Email',
            Cell: ({ hash, email }) => (
              <>
                <div>{hash || ''}</div>
                <div style={{ color: 'green' }}>{email || ''}</div>
              </>
            ),
          },
          {
            title: 'Status',
            attribute: 'status',
            Cell: ({ status }) => (
              <Box color={status === 'Acesso permitido' ? 'success.main' : 'error.main'}>
                {status}
              </Box>
            ),
          },
          {
            title: 'Reason',
            attribute: 'reason',
            Cell: ({ reason }) => (
              <div style={{ overflowY: 'scroll', maxHeight: 60 }}>
                {reason && reason.split('\n').map((r, index) => (
                  <div key={index} style={{ color: '#CC6' }}>
                    {r}
                  </div>
                ))}
              </div>
            ),
          },
        ]}
      />
      {hasMore && (
        <Box component={Paper} my={2}>
          <Button fullWidth disabled={loadingPage} onClick={handleMore}>
            {loadingPage ? 'Carregando...' : 'Carregar anteriores'}
          </Button>
        </Box>
      )}
    </>
  );
}